import { combineReducers } from "redux";
import alert from "./alertReducer";
import auth from "./authReducer";
import data from "./dataReducer";
import cot from "./cotReducer";
import timing from "./timingReducer";
import seasonalityViewer from "./seasonalityViewerReducer";

export default combineReducers({
  alert,
  auth,
  data,
  cot,
  timing,
  seasonalityViewer,
});
