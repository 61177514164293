import React from "react";

// Components
import AnomalyTradePerformanceTable from "./BacktestAnomalyAnalysisComponents/AnomalyTradePerformanceTable";
import PrvActionableAnomalyEvent from "./BacktestAnomalyAnalysisComponents/PrvActionableAnomalyEvent";

// Styling
import "./PrvAnomalyTradePerformanceBlock.css";

const PrvAnomalyTradePerformanceBlock = ({ data }) => {
  const {
    category,
    symbol,
    adjCloseLast,
    periodDays,
    currentReturnDeviationInStdev,
  } = data;

  const dataAae = {
    category,
    symbol,
    adjCloseLast,
    periodDays,
    currentReturnDeviationInStdev,
    tradePeriods: data.tradePeriods,
    vixData: data.vixData,
    currentAnomalyCategory: data.currentAnomaly,
    // currentAnomalyCategory: 'p3b',
    currentAnomalyData: data.sigmaSections[data.currentAnomaly],
    // currentAnomalyData: data.sigmaSections['p3b'],
  };
  return (
    <div className="PrvAnomalyTradePerformanceBlock">
      <div className="PrvAnomalyTradePerformanceBlock-heading">
        {`Return Anomaly Analysis - ${symbol} based on periods of ${periodDays} trading days`}
      </div>
      <PrvActionableAnomalyEvent data={dataAae} />
      <div className="PrvAnomalyTradePerformanceBlock-subheading">
        Trade Performance Overview
      </div>
      <div className="PrvAnomalyTradePerformanceBlock-explanation">
        Based on the calculated and above visualized periodic return
        distribution, the following table provides statistics about six anomaly
        sections (greater than 3|2|1σ and less than -1|-2|-3σ). Assignment to
        one of these sections is determined by the deviation of the return (Δr)
        - delta between return at a specific day and the corresponding mean
        return, and scaled by the corresponding standard deviation. Upon the
        occurence of anomaly events, the following price actions were analyzed
        in form of arithmetic returns to assess the outcome of respective
        anomaly trades. The considered durations for calculating the anomaly
        trade returns range from 10 to 60 trading days and, thus, are in
        agreement with the durations of favorable option strategies or mid-term
        directional trades. The analysis additionally differentiates between all
        occurences of a specific anomaly section and occurences when the
        threshold for the respective anomaly section was just breached.
      </div>
      <AnomalyTradePerformanceTable data={data} />
    </div>
  );
};

export default PrvAnomalyTradePerformanceBlock;
