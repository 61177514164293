import React, { useEffect, useRef, useState } from "react";
import { timeParse, timeFormat, selectAll } from "d3";
import { Modal } from "antd";

import drawPrvriLineChart from "./drawPrvriLineChart";
import drawPrvriHistogram from "./drawPrvriHistogram";
import drawPrvriChartForecast from "./drawPrvriChartForecast";

import "./PeriodicReturnViewerResultItem.css";

const PeriodicReturnViewerResultItem = ({ data }) => {
  const [dimensions, setDimensions] = useState({
    heightWindow: undefined,
    widthWindow: undefined,
  });
  const [isInfoModalVisible, setIsInfoModalVisible] = useState(false);

  const parseTime = timeParse("%Y%m%d");
  const formatTime = timeFormat("%Y-%m-%d");
  const formatTimeHeader = timeFormat("%b %d, %Y");

  const refDivLineChart = useRef(null);
  const refDivContainerHistogram = useRef(null);

  const nodeIdLineChart = `prsri-line-chart`;
  const nodeIdHistogramReturns = `prsri-histogram-returns`;
  const nodeIdHistogramVola = `prsri-histogram-vola`;
  const nodeIdChartForeCast = `prsri-chart-forecast`;

  useEffect(() => {
    function handleResize() {
      setDimensions({
        heightWindow: window.innerHeight,
        widthWindow: window.innerWidth,
      });
      window.addEventListener("resize", handleResize);
    }

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const divLineChart = refDivLineChart.current;
    const widthDivLineChart = divLineChart.offsetWidth;
    const heightDivLineChart = divLineChart.offsetHeight;

    const divContainerHistogram = refDivContainerHistogram.current;
    const widthDivHistogram = divContainerHistogram.offsetWidth;
    const heightDivHistograms = divContainerHistogram.offsetHeight;
    const heightHistogramReturns =
      (heightDivHistograms - 60 - 17) / 3 + 60 + 13;
    const heightHistogramVola =
      (heightDivHistograms - heightHistogramReturns) / 2 - 13;
    const heightChartForecast =
      heightDivHistograms - heightHistogramReturns - heightHistogramVola - 14;

    drawPrvriLineChart(
      nodeIdLineChart,
      widthDivLineChart,
      heightDivLineChart,
      data
    );
    drawPrvriHistogram(
      nodeIdHistogramReturns,
      widthDivHistogram,
      heightHistogramReturns,
      data,
      "returns"
    );
    drawPrvriHistogram(
      nodeIdHistogramVola,
      widthDivHistogram,
      heightHistogramVola,
      data,
      "volatility"
    );
    drawPrvriChartForecast(
      nodeIdChartForeCast,
      widthDivHistogram,
      heightChartForecast,
      data
    );

    return () => {
      selectAll(`#${nodeIdLineChart} svg`).remove();
      selectAll(`#${nodeIdHistogramReturns} svg`).remove();
      selectAll(`#${nodeIdHistogramVola} svg`).remove();
      selectAll(`#${nodeIdChartForeCast} svg`).remove();
    };
  }, [
    data,
    dimensions,
    nodeIdLineChart,
    nodeIdHistogramReturns,
    nodeIdHistogramVola,
    nodeIdChartForeCast,
  ]);

  const adjCloseLast =
    data.category === "forex" && !data.symbol.includes("JPY")
      ? data.adjCloseList[0].toFixed(5)
      : data.adjCloseList[0].toFixed(2);

  // --------------- INFO MODAL ---------------------
  const modalWidth = window.innerWidth * 0.618;
  const modalHeight = window.innerHeight * 0.75;

  const infoModal = (
    <Modal
      className="PeriodicReturnViewer-info-modal"
      title="Company Description"
      visible={isInfoModalVisible}
      width={modalWidth}
      height={modalHeight}
      centered={true}
      footer={null}
      onOk={() => {
        setIsInfoModalVisible(false);
      }}
      onCancel={() => {
        setIsInfoModalVisible(false);
      }}
    >
      <div className="PeriodicReturnViewer-info-modal-title">
        {data.companyDescription.name}
      </div>
      <div className="PeriodicReturnViewer-info-modal-container">
        <div className="PeriodicReturnViewer-info-modal-subcontainer-address info-modal-subcontainer">
          <div className="PeriodicReturnViewer-info-modal-address-street-1">
            {data.companyDescription.street1}
          </div>
          {data.companyDescription.street2 !== "" && (
            <div className="PeriodicReturnViewer-info-modal-address-street-2">
              {data.companyDescription.street2}
            </div>
          )}
          <div className="PeriodicReturnViewer-info-modal-address-city-state">
            {data.companyDescription.city_state_zip}
          </div>
          <div className="PeriodicReturnViewer-info-modal-address-country">
            {data.companyDescription.country}
          </div>
          <div className="PeriodicReturnViewer-info-modal-address-phone">
            <div className="PeriodicReturnViewer-info-modal-address-phone-label address-label">
              Phone:
            </div>
            <div className="PeriodicReturnViewer-info-modal-address-phone-data">
              {data.companyDescription.phone}
            </div>
          </div>
          <div className="PeriodicReturnViewer-info-modal-address-website">
            <div className="PeriodicReturnViewer-info-modal-address-website-label address-label">
              Website:
            </div>
            <div className="PeriodicReturnViewer-info-modal-address-website-data">
              <a
                href={data.companyDescription.website}
                target="_blank"
                rel="noopener noreferrer"
              >
                {data.companyDescription.website}
              </a>
            </div>
          </div>
        </div>
        <div className="PeriodicReturnViewer-info-modal-subcontainer-category info-modal-subcontainer">
          <div className="PeriodicReturnViewer-info-modal-category-sector-label category-label">
            Sector:
          </div>
          <div className="PeriodicReturnViewer-info-modal-category-sector-name category-name">
            {`${data.sectorName} (${data.sectorSymbol})`}
          </div>
          <div className="PeriodicReturnViewer-info-modal-category-industry-label category-label">
            Industry:
          </div>
          <div className="PeriodicReturnViewer-info-modal-category-industry-name category-name">
            {data.companyDescription.industry}
          </div>
        </div>
      </div>
      <div className="PeriodicReturnViewer-info-modal-description">
        {data.companyDescription.description}
      </div>
      <div className="PeriodicReturnViewer-info-modal-footer">
        <div className="PeriodicReturnViewer-info-modal-source">
          <div className="PeriodicReturnViewer-info-modal-source-label">
            Source:
          </div>
          <div className="PeriodicReturnViewer-info-modal-source-data">
            Yahoo Finance
          </div>
        </div>
        <div className="PeriodicReturnViewer-info-modal-date">
          <div className="PeriodicReturnViewer-info-modal-date-label">
            Date:
          </div>
          <div className="PeriodicReturnViewer-info-modal-date-data">
            {data.companyDescription.createdAt}
          </div>
        </div>
      </div>
    </Modal>
  );

  return (
    <div className="PeriodicReturnViewerResultItem">
      <div className="PeriodicReturnViewerResultItem-header">
        <div className="PeriodicReturnViewerResultItem-header-title">
          {`${data.symbol} - ${data.name.toUpperCase()} ($${adjCloseLast})`}
          {data.category === "stocks" && (
            <span
              className="PeriodicReturnViewerResultItem-header-title-info"
              onClick={() => setIsInfoModalVisible(!isInfoModalVisible)}
            >
              INFO
            </span>
          )}
        </div>
        <div className="PeriodicReturnViewerResultItem-header-date">
          {formatTimeHeader(parseTime(data.endDate))}
        </div>
      </div>
      <div className="PeriodicReturnViewerResultItem-container">
        <div className="PeriodicReturnViewerResultItem-container-details">
          <div className="PeriodicReturnViewerResultItem-details-item delta return">
            <div className="PeriodicReturnViewerResultItem-details-label">
              Period Log-Return/Vola Δ(Last-Mean) [Abs | StDev]
            </div>
            <div className="PeriodicReturnViewerResultItem-details-value">
              <div className="PeriodicReturnViewerResultItem-details-value-return">{`${
                data.periodicReturnsDeltaAbs > 0 ? "+" : ""
              }${data.periodicReturnsDeltaAbs.toFixed(
                2
              )} % | ${data.periodicReturnsDeltaStdev.toFixed(2)} σ`}</div>
              <div className="PeriodicReturnViewerResultItem-details-value-seperator">
                /
              </div>
              <div className="PeriodicReturnViewerResultItem-details-value-vola">
                {`${
                  data.periodicStdevDeltaAbs > 0 ? "+" : ""
                }${data.periodicStdevDeltaAbs.toFixed(
                  2
                )} % | ${data.periodicStdevDeltaStdev.toFixed(2)} σ `}
              </div>
            </div>
          </div>
          <div className="PeriodicReturnViewerResultItem-details-item">
            <div className="PeriodicReturnViewerResultItem-details-label">
              Log Return Last
            </div>
            <div className="PeriodicReturnViewerResultItem-details-value">
              {`${data.periodicReturnsLast.toFixed(2)} %`}
            </div>
          </div>
          <div className="PeriodicReturnViewerResultItem-details-item">
            <div className="PeriodicReturnViewerResultItem-details-label">
              Log Return Median
            </div>
            <div className="PeriodicReturnViewerResultItem-details-value">
              {`${data.periodicReturnsMedian.toFixed(2)} %`}
            </div>
          </div>
          <div className="PeriodicReturnViewerResultItem-details-item">
            <div className="PeriodicReturnViewerResultItem-details-label">
              Log Return Mean
            </div>
            <div className="PeriodicReturnViewerResultItem-details-value">
              {`${data.periodicReturnsMean.toFixed(2)} %`}
            </div>
          </div>
          <div className="PeriodicReturnViewerResultItem-details-item">
            <div className="PeriodicReturnViewerResultItem-details-label">
              Log Return StDev
            </div>
            <div className="PeriodicReturnViewerResultItem-details-value">
              {`${data.periodicReturnsStdev.toFixed(2)} %`}
            </div>
          </div>
          <div className="PeriodicReturnViewerResultItem-details-item">
            <div className="PeriodicReturnViewerResultItem-details-label">
              Log Return Min
            </div>
            <div className="PeriodicReturnViewerResultItem-details-value">
              {`${data.periodicReturnsMin.toFixed(2)} %`}
            </div>
          </div>
          <div className="PeriodicReturnViewerResultItem-details-item">
            <div className="PeriodicReturnViewerResultItem-details-label">
              Log Return Max
            </div>
            <div className="PeriodicReturnViewerResultItem-details-value">
              {`${data.periodicReturnsMax.toFixed(2)} %`}
            </div>
          </div>
          <div className="PeriodicReturnViewerResultItem-details-item">
            <div className="PeriodicReturnViewerResultItem-details-label">
              Volatility Last
            </div>
            <div className="PeriodicReturnViewerResultItem-details-value">
              {`${data.periodicStdevsLast.toFixed(2)} %`}
            </div>
          </div>
          <div className="PeriodicReturnViewerResultItem-details-item">
            <div className="PeriodicReturnViewerResultItem-details-label">
              Volatility Median
            </div>
            <div className="PeriodicReturnViewerResultItem-details-value">
              {`${data.periodicStdevsMedian.toFixed(2)} %`}
            </div>
          </div>
          <div className="PeriodicReturnViewerResultItem-details-item">
            <div className="PeriodicReturnViewerResultItem-details-label">
              Volatility Mean
            </div>
            <div className="PeriodicReturnViewerResultItem-details-value">
              {`${data.periodicStdevsMean.toFixed(2)} %`}
            </div>
          </div>
          <div className="PeriodicReturnViewerResultItem-details-item">
            <div className="PeriodicReturnViewerResultItem-details-label">
              Volatility StDev
            </div>
            <div className="PeriodicReturnViewerResultItem-details-value">
              {`${data.periodicStdevsStdev.toFixed(2)} %`}
            </div>
          </div>
          <div className="PeriodicReturnViewerResultItem-details-item">
            <div className="PeriodicReturnViewerResultItem-details-label">
              Volatility Min
            </div>
            <div className="PeriodicReturnViewerResultItem-details-value">
              {`${data.periodicStdevsMin.toFixed(2)} %`}
            </div>
          </div>
          <div className="PeriodicReturnViewerResultItem-details-item">
            <div className="PeriodicReturnViewerResultItem-details-label">
              Volatility Max
            </div>
            <div className="PeriodicReturnViewerResultItem-details-value">
              {`${data.periodicStdevsMax.toFixed(2)} %`}
            </div>
          </div>
          <div className="PeriodicReturnViewerResultItem-details-item">
            <div className="PeriodicReturnViewerResultItem-details-label">
              Start Date
            </div>
            <div className="PeriodicReturnViewerResultItem-details-value">
              {formatTime(parseTime(data.startDate))}
            </div>
          </div>
          <div className="PeriodicReturnViewerResultItem-details-item">
            <div className="PeriodicReturnViewerResultItem-details-label">
              End Date
            </div>
            <div className="PeriodicReturnViewerResultItem-details-value">
              {formatTime(parseTime(data.endDate))}
            </div>
          </div>
          <div className="PeriodicReturnViewerResultItem-details-item">
            <div className="PeriodicReturnViewerResultItem-details-label">
              Trading Days
            </div>
            <div className="PeriodicReturnViewerResultItem-details-value">
              {data.tradingDays}
            </div>
          </div>
          <div className="PeriodicReturnViewerResultItem-details-item">
            <div className="PeriodicReturnViewerResultItem-details-label">
              Period Days
            </div>
            <div className="PeriodicReturnViewerResultItem-details-value">
              {data.periodDays}
            </div>
          </div>
          <div className="PeriodicReturnViewerResultItem-details-item">
            <div className="PeriodicReturnViewerResultItem-details-label">
              Total Periods
            </div>
            <div className="PeriodicReturnViewerResultItem-details-value">
              {data.totalPeriods}
            </div>
          </div>
          <div className="PeriodicReturnViewerResultItem-details-item">
            <div className="PeriodicReturnViewerResultItem-details-label">
              Adjusted Close Last
            </div>
            <div className="PeriodicReturnViewerResultItem-details-value">
              {`$${adjCloseLast}`}
            </div>
          </div>
        </div>
        <div
          id={nodeIdLineChart}
          ref={refDivLineChart}
          className="PeriodicReturnViewerResultItem-container-linechart"
        ></div>
        <div
          ref={refDivContainerHistogram}
          className="PeriodicReturnViewerResultItem-container-histogram"
        >
          <div
            id={nodeIdHistogramReturns}
            className="PeriodicReturnViewerResultItem-histogram-returns"
          ></div>
          <div
            id={nodeIdHistogramVola}
            className="PeriodicReturnViewerResultItem-histogram-vola"
          ></div>
          <div
            id={nodeIdChartForeCast}
            className="PeriodicReturnViewerResultItem-chart-forecast"
          ></div>
        </div>
      </div>
      {infoModal}
    </div>
  );
};

export default PeriodicReturnViewerResultItem;
