import React from 'react';
import PropTypes from 'prop-types';
import { Table, Input, Button, Space } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { timeFormat } from 'd3';

// Contexts
import { AnomaliesContext } from '../../../contexts/AnomaliesContext';

// Redux
import { connect } from 'react-redux';
import { getPeriodicReturnViewerData } from '../../../redux/actions/dataActions';

// Styling
import './PeriodicReturnFilterResult.css';

class PeriodicReturnFilterResult extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
  };

  formatTime = timeFormat('%Y-%m-%d');

  handleClickOnRow = (symbol) => {
    const { changeOption, changeTool } = this.context;
    const { period } = this.props;
    const endDateObj = new Date(Date.now());
    const year = endDateObj.getFullYear();
    const month =
      endDateObj.getMonth() + 1 > 9
        ? `${endDateObj.getMonth() + 1}`
        : `0${endDateObj.getMonth() + 1}`;
    const day =
      endDateObj.getDate() > 9
        ? `${endDateObj.getDate()}`
        : `0${endDateObj.getDate()}`;

    const endDate = `${year}${month}${day}`;
    changeOption('prvOptions', 'symbol', symbol);
    changeOption('prvOptions', 'period', period);
    changeOption('prvOptions', 'endDate', endDate);
    this.props.getPeriodicReturnViewerData(symbol, period, endDate);
    changeTool('prv');
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        classname='PeriodicReturnFilterResult-search-dropdown'
        style={{ padding: 8, fontSize: '0.85rem' }}
      >
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${
            dataIndex.slice(0, 1).toUpperCase() + dataIndex.slice(1)
          }`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: 'block', fontSize: 'inherit' }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90, fontSize: 'inherit' }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size='small'
            style={{ width: 90, fontSize: 'inherit' }}
          >
            Reset
          </Button>
          <Button
            type='link'
            size='small'
            style={{ fontSize: 'inherit' }}
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  render() {
    const { data } = this.props;

    // Preparing data
    const dataTable = data.map((obj, i) => {
      const returnKeys = Object.keys(obj.anomalyTradingStats).filter((s) =>
        s.includes('return')
      );

      const returnKeysJustBreached = returnKeys.filter((s) =>
        s.includes('just')
      );
      const returnKeysOutsideThreshold = returnKeys.filter(
        (s) => !s.includes('just')
      );

      const nextPeriodDays = returnKeysJustBreached.map((s) =>
        parseInt(s.slice(6, 8))
      );

      const returnsJustBreached = returnKeysJustBreached.map(
        (s) =>
          `${obj.anomalyTradingStats[s].mean > 0 ? '+' : ''}` +
          obj.anomalyTradingStats[s].mean.toFixed(1) +
          '±' +
          obj.anomalyTradingStats[s].stdev.toFixed(1)
      );
      const returnsOutsideThreshold = returnKeysOutsideThreshold.map(
        (s) =>
          `${obj.anomalyTradingStats[s].mean > 0 ? '+' : ''}` +
          obj.anomalyTradingStats[s].mean.toFixed(1) +
          '±' +
          obj.anomalyTradingStats[s].stdev.toFixed(1)
      );

      const probKeys = Object.keys(
        obj.anomalyTradingStats[returnKeys[0]]
      ).filter((s) => s.includes('Rate'));
      const probsJustBreached = returnKeysJustBreached.map((keyReturn) =>
        probKeys.map((keyProb) =>
          obj.anomalyTradingStats[keyReturn][keyProb].toFixed(0)
        )
      );
      const probsOutsideThreshold = returnKeysOutsideThreshold.map(
        (keyReturn) =>
          probKeys.map((keyProb) =>
            obj.anomalyTradingStats[keyReturn][keyProb].toFixed(0)
          )
      );

      return {
        key: i,
        symbol: obj.symbol,
        name: obj.name,
        sector: obj.sectorSymbol,
        period: `${this.formatTime(
          obj.dateList[obj.periodDays]
        )}-||-${this.formatTime(obj.dateList[0])}`,
        adjClose: `${obj.adjCloseList[obj.periodDays].toFixed(
          2
        )}-||-${obj.adjCloseList[0].toFixed(2)}`,
        // adjCloseLastPeriodStart: obj.adjCloseList[obj.periodDays].toFixed(2),
        // adjCloseLastPeriodEnd: obj.adjCloseList[0].toFixed(2),
        // periodicReturnsLastAr: (
        //   (obj.adjCloseList[0] / obj.adjCloseList[obj.periodDays] - 1) *
        //   100
        // ).toFixed(2),
        periodicReturnsLast: obj.periodicReturnsLast.toFixed(2),
        periodicReturnsLastArLog: `${(
          (obj.adjCloseList[0] / obj.adjCloseList[obj.periodDays] - 1) *
          100
        ).toFixed(1)}-||-${obj.periodicReturnsLast.toFixed(1)}`,
        periodicReturnsMean: obj.periodicReturnsMean.toFixed(2),
        periodicReturnsStdev: obj.periodicReturnsStdev.toFixed(2),
        periodicReturnAvg: `${obj.periodicReturnsMean.toFixed(
          1
        )}±${obj.periodicReturnsStdev.toFixed(1)}`,
        periodicReturnsDeltaStdev: obj.periodicReturnsDeltaStdev.toFixed(2),
        countJustBreached: obj.anomalyTradingStats.countJustBreached,
        daysOutsideThresholdCurrent:
          obj.anomalyTradingStats.daysExceedingThresholdCurrent,
        daysOutsideThresholdMedianAvg: `${obj.anomalyTradingStats.daysExceedingThresholdMedian} | ${obj.anomalyTradingStats.daysExceedingThresholdMean}±${obj.anomalyTradingStats.daysExceedingThresholdStdev}`,
        daysNextPeriod: nextPeriodDays.join('-||-'),
        returnsJustBreached: returnsJustBreached.join('-||-'),
        probsJustBreached: probsJustBreached
          .map((arr) => arr.join('-||-'))
          .join('-$$-'),
        returnsOutsideThreshold: returnsOutsideThreshold.join('-||-'),
        probsOutsideThreshold: probsOutsideThreshold
          .map((arr) => arr.join('-||-'))
          .join('-$$-'),
      };
    });

    const columns = [
      {
        title: 'Symbol',
        className: 'cell symbol',
        dataIndex: 'symbol',
        key: 'symbol',
        width: '5%',
        ...this.getColumnSearchProps('symbol'),
        sorter: (a, b) => ('' + a.symbol).localeCompare(b.symbol),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        // width: '15%',
        ...this.getColumnSearchProps('name'),
        sorter: (a, b) => ('' + a.name).localeCompare(b.name),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Sector',
        dataIndex: 'sector',
        key: 'sector',
        // width: '15%',
        ...this.getColumnSearchProps('sector'),
      },
      {
        title: 'Last Period',
        dataIndex: 'period',
        key: 'period',
        // width: '15%',
        render(text) {
          return {
            children: (
              <div className='cell direction-vertical'>
                <span>{text.split('-||-')[0]}</span>
                <span>{text.split('-||-')[1]}</span>
              </div>
            ),
          };
        },
      },
      {
        title: 'Adj Close [$]',
        dataIndex: 'adjClose',
        key: 'adjClose',
        // width: '5%',
        render(text) {
          return {
            children: (
              <div className='cell direction-vertical'>
                <span>{text.split('-||-')[0]}</span>
                <span>{text.split('-||-')[1]}</span>
              </div>
            ),
          };
        },
      },
      // {
      //   title: 'Adj Close Start [$]',
      //   className: 'cell align-right',
      //   dataIndex: 'adjCloseLastPeriodStart',
      //   key: 'adjCloseLastPeriodStart',
      //   // width: '5%',
      // },
      // {
      //   title: 'Adj Close End [$]',
      //   className: 'cell align-right',
      //   dataIndex: 'adjCloseLastPeriodEnd',
      //   key: 'adjCloseLastPeriodEnd',
      //   // width: '5%',
      // },
      // {
      //   title: 'Ar Return [%]',
      //   className: 'cell align-right',
      //   dataIndex: 'periodicReturnsLastAr',
      //   key: 'periodicReturnsLastAr',
      //   // width: '5%',
      //   render(text) {
      //     return {
      //       children: <span>{`${parseInt(text) > 0 ? '+' : ''}${text}`}</span>,
      //     };
      //   },
      // },
      // {
      //   title: 'Log Return [%]',
      //   className: 'cell align-right',
      //   dataIndex: 'periodicReturnsLast',
      //   key: 'periodicReturnsLast',
      //   // width: '5%',
      //   render(text) {
      //     return {
      //       props: {
      //         style: { color: parseInt(text) >= 0 ? '#389e0d' : '#ff181d' },
      //       },
      //       children: <span>{`${parseInt(text) > 0 ? '+' : ''}${text}`}</span>,
      //     };
      //   },
      //   sorter: (a, b) => a.periodicReturnsLast - b.periodicReturnsLast,
      //   sortDirections: ['descend', 'ascend'],
      // },
      {
        title: 'Ar|Log Return [%]',
        className: 'cell align-right',
        dataIndex: 'periodicReturnsLastArLog',
        key: 'periodicReturnsLastArLog',
        // width: '7%',
        render(text) {
          return {
            children: (
              <React.Fragment>
                <span
                  className={`${
                    parseInt(text.split('-||-')[1]) > 0
                      ? 'cell font-color-green'
                      : 'cell font-color-red'
                  }`}
                >
                  {`${parseInt(text.split('-||-')) > 0 ? '+' : ''}
                   ${text.split('-||-')[0]}`}
                </span>
                <span> |</span>
                <span
                  className={`${
                    parseInt(text.split('-||-')[1]) > 0
                      ? 'cell font-color-green'
                      : 'cell font-color-red'
                  }`}
                >
                  {`${parseInt(text.split('-||-')) > 0 ? '+' : ''}
                   ${text.split('-||-')[1]}`}
                </span>
              </React.Fragment>
            ),
          };
        },
        sorter: (a, b) => a.periodicReturnsLast - b.periodicReturnsLast,
        sortDirections: ['descend', 'ascend'],
      },
      // {
      //   title: 'Mean Log Return [%]',
      //   className: 'cell align-right',
      //   dataIndex: 'periodicReturnsMean',
      //   key: 'periodicReturnsMean',
      //   // width: '5%',
      // },
      {
        title: 'Avg Log Return [%]',
        className: 'cell align-right',
        dataIndex: 'periodicReturnAvg',
        key: 'periodicReturnAvg',
        // width: '5%',
      },
      // {
      //   title: 'StDev Log Return [%]',
      //   className: 'cell align-right',
      //   dataIndex: 'periodicReturnsStdev',
      //   key: 'periodicReturnsStdev',
      //   // width: '5%',
      // },
      {
        title: 'Return Delta [σ]',
        className: 'cell align-right delta-log-return',
        dataIndex: 'periodicReturnsDeltaStdev',
        key: 'periodicReturnsDeltaStdev',
        // width: '5%',
        render(text) {
          return {
            props: {
              style: { color: parseInt(text) >= 0 ? '#389e0d' : '#ff181d' },
            },
            children: <span>{`${parseInt(text) > 0 ? '+' : ''}${text}`}</span>,
          };
        },
        sorter: (a, b) =>
          a.periodicReturnsDeltaStdev - b.periodicReturnsDeltaStdev,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'JB Count',
        className: 'cell align-right',
        dataIndex: 'countJustBreached',
        key: 'countJustBreached',
        // width: '5%',
      },
      {
        title: 'OT Days Current',
        className: 'cell align-right',
        dataIndex: 'daysOutsideThresholdCurrent',
        key: 'daysOutsideThresholdCurrent',
        // width: '5%',
      },
      {
        title: 'OT Days Median|Avg',
        className: 'cell align-right',
        dataIndex: 'daysOutsideThresholdMedianAvg',
        key: 'daysOutsideThresholdMedianAvg',
        // width: '5%',
      },
      {
        title: 'Next Period [d]',
        className: 'cell align-right',
        dataIndex: 'daysNextPeriod',
        key: 'daysNextPeriod',
        // width: '5%',
        render(text) {
          return {
            children: (
              <div className='cell direction-vertical'>
                {text.split('-||-').map((s) => (
                  <span key={'daysNextPeriod' + s}>{s}</span>
                ))}
              </div>
            ),
          };
        },
      },
      {
        title: 'JB Avg Return [%]',
        className: 'cell align-right',
        dataIndex: 'returnsJustBreached',
        key: 'returnsJustBreached',
        // width: '5%',
        render(text) {
          return {
            children: (
              <div className='cell direction-vertical'>
                {text.split('-||-').map((s, idx) => (
                  <span key={'returnsJustBreached' + idx}>{s}</span>
                ))}
              </div>
            ),
          };
        },
      },
      {
        title: 'Prob JB Return ≥ 0|-5|-10% [%]',
        className: 'cell align-right',
        dataIndex: 'probsJustBreached',
        key: 'probsJustBreached',
        // width: '5%',
        render(text) {
          return {
            children: (
              <div className='cell direction-vertical'>
                {text.split('-$$-').map((s, idx) => (
                  <span key={'probsJustBreached-' + idx}>
                    <span>{s.split('-||-')[0]}</span>
                    <span>{' | '}</span>
                    <span>{s.split('-||-')[1]}</span>
                    <span>{' | '}</span>
                    <span>{s.split('-||-')[2]}</span>
                  </span>
                ))}
              </div>
            ),
          };
        },
      },
      {
        title: 'OT Avg Return [%]',
        className: 'cell align-right',
        dataIndex: 'returnsOutsideThreshold',
        key: 'returnsOutsideThreshold',
        // width: '5%',
        render(text) {
          return {
            children: (
              <div className='cell direction-vertical'>
                {text.split('-||-').map((s, idx) => (
                  <span key={'returnsOutsideThreshold' + idx}>{s}</span>
                ))}
              </div>
            ),
          };
        },
      },
      {
        title: 'Prob OT Return ≥ 0|-5|-10% [%]',
        className: 'cell align-right',
        dataIndex: 'probsOutsideThreshold',
        key: 'probsOutsideThreshold',
        // width: '5%',
        render(text) {
          return {
            children: (
              <div className='cell direction-vertical'>
                {text.split('-$$-').map((s, idx) => (
                  <span key={'probsOutsideThreshold-' + idx}>
                    <span>{s.split('-||-')[0]}</span>
                    <span>{' | '}</span>
                    <span>{s.split('-||-')[1]}</span>
                    <span>{' | '}</span>
                    <span>{s.split('-||-')[2]}</span>
                  </span>
                ))}
              </div>
            ),
          };
        },
      },
    ];

    return (
      <div className='PeriodicReturnFilterResult'>
        <Table
          columns={columns}
          showSorterTooltip={false}
          pagination={false}
          dataSource={dataTable}
          size='small'
          onRow={(record) => {
            return {
              onClick: () => this.handleClickOnRow(record.symbol),
            };
          }}
        />
      </div>
    );
  }
}

PeriodicReturnFilterResult.contextType = AnomaliesContext;

PeriodicReturnFilterResult.propTypes = {
  getPeriodicReturnViewerData: PropTypes.func.isRequired,
};

export default connect(null, { getPeriodicReturnViewerData })(
  PeriodicReturnFilterResult
);
