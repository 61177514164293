import React from 'react';
import { Spin, Space } from 'antd';

import './SpinnerWithText.css';

const SpinnerWithText = ({ spinnerSize, textSize }) => {
  const defaultSpinnerSize = 'large';
  const defaultTextSize = '25px';

  return (
    <div className='SpinnerWithText'>
      <Space size='middle'>
        <Spin 
          className='SpinnerWithText-icon'
          size={spinnerSize || defaultSpinnerSize} />
      </Space>
      <div
        className='SpinnerWithText-text'
        style={{ fontSize: `${textSize || defaultTextSize}` }}
      >
        LOADING
      </div>
    </div>
  );
};

export default SpinnerWithText;
