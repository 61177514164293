import React, { useContext } from "react";
import { Layout } from "antd";

// Context
import { AnomaliesContext } from "../../contexts/AnomaliesContext";

import AnomaliesHeadMenu from "./AnomaliesHeadMenu";
// import PeriodicReturnScannerContent from './PeriodicReturnScanner/PeriodicReturnScannerContent';
import PeriodicReturnFilterContent from "./PeriodicReturnFilter/PeriodicReturnFilterContent";
import PeriodicReturnViewerContent from "./PeriodicReturnViewer/PeriodicReturnViewerContent";
// import PeriodicCorrelationScannerContent from './PeriodicCorrelationScanner/PeriodicCorrelationScannerContent';
// import PeriodicCorrelationViewerContent from './PeriodicCorrelationViewer/PeriodicCorrelationViewerContent';
// import PeriodicSpreadScannerSingleContent from './PeriodicSpreadScannerSingle/PeriodicSpreadScannerSingleContent';
// import PeriodicSpreadViewerContent from './PeriodicSpreadViewer/PeriodicSpreadViewerContent';
import AnomaliesFooter from "./AnomaliesFooter";

const Anomalies = () => {
  const { toolSelected } = useContext(AnomaliesContext);

  let content;
  switch (toolSelected) {
    // case 'prs':
    //   content = <PeriodicReturnScannerContent />;
    //   break;
    case "prf":
      content = <PeriodicReturnFilterContent />;
      break;
    case "prv":
      content = <PeriodicReturnViewerContent />;
      break;
    // case 'pcs':
    //   content = <PeriodicCorrelationScannerContent />;
    //   break;
    // case 'pcv':
    //   content = <PeriodicCorrelationViewerContent />;
    //   break;
    // case 'psss':
    //   content = <PeriodicSpreadScannerSingleContent />;
    //   break;
    // case 'psv':
    //   content = <PeriodicSpreadViewerContent />;
    //   break;
    default:
      content = null;
  }

  return (
    <Layout>
      <AnomaliesHeadMenu />

      {content}

      <AnomaliesFooter />
    </Layout>
  );
};

export default Anomalies;
