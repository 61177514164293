// ALERTS FROM BACKEND
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

// AUTH
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const REGISTER_RESET = "REGISTER_RESET";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGOUT = "LOGOUT";
export const USER_UPDATED = "USER_UPDATED";
export const USER_PASSWORD_UPDATED = "USER_PASSWORD_UPDATED";
export const USER_DELETED = "USER_DELETED";
export const USER_ERROR = "USER_ERROR";

// DATA + COTDATA
export const DATA_ERROR = "DATA_ERROR";
export const IS_LOADING = "IS_LOADING";
export const INITIAL_STATE = "INITIAL_STATE";

export const DATA_LOADED = "DATA_LOADED";

export const COT_OVERVIEW_LOADING = "COT_OVERVIEW_LOADING";
export const COT_OVERVIEW_LOADED = "COT_OVERVIEW_LOADED";
export const COT_OVERVIEW_ERROR = "COT_OVERVIEW_ERROR";
export const COT_ELEMENT_LOADING = "COT_ELEMENT_LOADING";
export const COT_ELEMENT_LOADED = "COT_ELEMENT_LOADED";
export const COT_ELEMENT_ERROR = "COT_ELEMENT_ERROR";

// TIMING DATA
export const TIMING_SAFETY_STOP_LOADING = "TIMING_SAFETY_STOP_LOADING";
export const TIMING_SAFETY_STOP_ERROR = "TIMING_SAFETY_STOP_ERROR";
export const TIMING_SAFETY_STOP_SUCCESS = "TIMING_SAFETY_STOP_SUCCESS";

// SEASONALITY DATA
export const SEASONALITY_VIEWER_LOADING = "SEASONALITY_VIEWER_LOADING";
export const SEASONALITY_VIEWER_ERROR = "SEASONALITY_VIEWER_ERROR";
export const SEASONALITY_VIEWER_SUCCESS = "SEASONALITY_VIEWER_SUCCESS";
