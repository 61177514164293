import React, { useContext, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import { Menu, Layout, Modal } from "antd";
// import { InfoCircleOutlined } from '@ant-design/icons';
import PropTypes from "prop-types";

// Context
import { AnomaliesContext } from "../../contexts/AnomaliesContext";

// Redux
import { connect } from "react-redux";

// own components
import LogoText_white from "./../../style/logos/LogoText_white.svg";
import imgIconThorsten from ".//../../style/images/img_icon_thorsten_01.png";
import WistiaEmbed from "../../components/WistiaEmbed";

// styling
import "./AnomaliesHeadMenu.css";

const { Header } = Layout;

const AnomaliesHeadMenu = ({ loading }) => {
  // context
  const { toolSelected, changeTool } = useContext(AnomaliesContext);

  // state
  const [isModalVisible, setIsModalVisible] = useState(false);

  // selecting data loading state for prs
  let dataLoadingPrs = false;
  if (loading !== null) {
    if (loading.anomaliesPrs !== undefined) {
      dataLoadingPrs = loading.anomaliesPrs;
    }
  }

  // selecting data loading state for prf
  let dataLoadingPrv = false;
  if (loading !== null) {
    if (loading.anomaliesPrv !== undefined) {
      dataLoadingPrv = loading.anomaliesPrv;
    }
  }

  // selecting data loading state for prf
  let dataLoadingPrf = false;
  if (loading !== null) {
    if (loading.anomaliesPrf !== undefined) {
      dataLoadingPrf = loading.anomaliesPrf;
    }
  }

  // selecting data loading state for pcs
  let dataLoadingPcs = false;
  if (loading !== null) {
    if (loading.anomaliesPcs !== undefined) {
      dataLoadingPcs = loading.anomaliesPcs;
    }
  }

  // selecting data loading state for psss
  let dataLoadingPsss = false;
  if (loading !== null) {
    if (loading.anomaliesPsss !== undefined) {
      dataLoadingPsss = loading.anomaliesPsss;
    }
  }

  const modalWidth = window.innerWidth * 0.5;
  const modalPadding = 24;
  const videoSize = {
    width: modalWidth - 2 * modalPadding,
    heigth: ((modalWidth - 2 * modalPadding) / 16) * 9,
  };

  const pInfoModalPrv = (
    <div>
      <h3 className="AnomaliesHeadMenu-modal-header">Periodic Return Viewer</h3>
      <div
        className="AnomaliesHeadMenu-modal-text"
        style={{ width: videoSize.width, height: videoSize.heigth }}
      >
        <WistiaEmbed hashedId={"l2j4pk7v14"} />
      </div>
    </div>
  );
  const pInfoModalPrf = (
    <div>
      <h3 className="AnomaliesHeadMenu-modal-header">Periodic Return Filter</h3>
      <div className="AnomaliesHeadMenu-modal-text">
        <p>
          Donec rhoncus risus ac consectetur aliquam. Aliquam dapibus, metus
          quis fringilla facilisis, lectus augue mollis lorem, non tempor felis
          ante ut orci. Sed in tellus orci. Etiam quis enim tellus.
        </p>
        <p>
          Donec rhoncus risus ac consectetur aliquam. Aliquam dapibus, metus
          quis fringilla facilisis, lectus augue mollis lorem, non tempor felis
          ante ut orci. Sed in tellus orci. Etiam quis enim tellus.
        </p>
        <p>
          Donec rhoncus risus ac consectetur aliquam. Aliquam dapibus, metus
          quis fringilla facilisis, lectus augue mollis lorem, non tempor felis
          ante ut orci. Sed in tellus orci. Etiam quis enim tellus.
        </p>
      </div>
    </div>
  );

  const infoModal = (
    <Modal
      title="Description and Usage"
      visible={isModalVisible}
      width={modalWidth}
      centered={true}
      footer={null}
      onOk={() => {
        setIsModalVisible(false);
      }}
      onCancel={() => {
        setIsModalVisible(false);
      }}
    >
      {toolSelected === "prv" ? pInfoModalPrv : pInfoModalPrf}
    </Modal>
  );

  return (
    <Fragment>
      <Header className="AnomaliesHeadMenu-header">
        <div
          key="headerLogo"
          className="headerLogo"
          style={{
            float: "left",
            marginTop: "-2px",
            marginLeft: "-45px",
            marginRight: "2px",
          }}
        >
          <Link to="/">
            <img src={LogoText_white} height="36px" alt="..." />
          </Link>
        </div>
        <div
          className="AnomaliesHeadMenu-container-info"
          onClick={() => setIsModalVisible(!isModalVisible)}
        >
          <span className="AnomaliesHeadMenu-container-info-label">
            Get briefed by
          </span>
          <img
            className="AnomaliesHeadMenu-container-info-icon-thorsten"
            src={imgIconThorsten}
            width={25}
            alt="iconTh"
          />
          {
            // <InfoCircleOutlined
            //   className='AnomaliesHeadMenu-container-info-icon'
            //   onClick={() => setIsModalVisible(!isModalVisible)}
            // />
          }
        </div>
        <Menu
          className="AnomaliesHeadMenu-header-menu"
          key="Overview-Head-Menu"
          theme="dark"
          mode="horizontal"
          selectedKeys={[toolSelected]}
          style={{ paddingLeft: "100px", marginRight: "120px" }}
          onClick={(e) => changeTool(e.key)}
        >
          {/* <Menu.Item key='prs'>
          <span
            className={`AnomaliesHeadMenu-prs ${
              dataLoadingPrs ? 'loading' : ''
            }`}
            data-text='Return Scanner'
          >
            Return Scanner
          </span>
        </Menu.Item> */}
          <Menu.Item key="prv">
            <span
              className={`AnomaliesHeadMenu-prv ${
                dataLoadingPrv ? "loading" : ""
              }`}
              data-text="Periodic Return Viewer"
            >
              Periodic Return Viewer
            </span>
          </Menu.Item>
          <Menu.Item key="prf">
            <span
              className={`AnomaliesHeadMenu-prf ${
                dataLoadingPrf ? "loading" : ""
              }`}
              data-text="Periodic Return Filter"
            >
              Periodic Return Filter
            </span>
          </Menu.Item>
          {/* <Menu.Item key='pcs'>
          <span
            className={`AnomaliesHeadMenu-pcs ${
              dataLoadingPcs ? 'loading' : ''
            }`}
            data-text='Correlation Scanner'
          >
            Correlation Scanner
          </span>
        </Menu.Item>
        <Menu.Item key='pcv'>Correlation Viewer</Menu.Item>
        <Menu.Item key='psss'>
          <span
            className={`AnomaliesHeadMenu-psss ${
              dataLoadingPsss ? 'loading' : ''
            }`}
            data-text='Spread Scanner Single'
          >
            Spread Scanner Single
          </span>
        </Menu.Item>
        <Menu.Item key='psv'>Spread Viewer</Menu.Item> */}
        </Menu>
      </Header>
      {infoModal}
    </Fragment>
  );
};

AnomaliesHeadMenu.propTypes = {
  loading: PropTypes.object,
};

const mapStateToProps = (state) => ({
  loading: state.data.loading,
});

export default connect(mapStateToProps, {})(AnomaliesHeadMenu);
