import axios from 'axios';
import {
  USER_LOADED,
  AUTH_ERROR,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  REGISTER_RESET,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  USER_UPDATED,
  USER_PASSWORD_UPDATED,
  USER_DELETED,
  USER_ERROR,
} from '../types';
import { setAlert } from './alertActions';
import setAuthToken from '../redux-utils/setAuthToken';

// Load user if we have a token we want to always send it with the header as required for authentication/route protection by backend
export const loadUser = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/v1/auth/me`
    );

    dispatch({
      type: USER_LOADED,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

// Register user
export const register = ({ name, email, password }) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const newUser = {
    name,
    email,
    password,
  };
  const body = JSON.stringify(newUser);

  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/v1/auth/register`,
      body,
      config
    );
    // console.log(res.data);

    dispatch({
      type: REGISTER_SUCCESS,
      payload: res.data,
    });

    // loadUser() initial dev, for production back end we will include email confirmation before hand
    // dispatch(loadUser());
  } catch (error) {
    console.log(error.response.data);

    // Additionally, Brad used here the setAlert for errors showing up
    dispatch(setAlert(error.response.data.error, 'danger'));

    dispatch({
      type: REGISTER_FAIL,
    });
  }
};

// Register reset
export const registerReset = () => async (dispatch) => {
  dispatch({ type: REGISTER_RESET });
};

// Login user
export const login = ({ email, password }) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const credentials = {
    email,
    password,
  };
  const body = JSON.stringify(credentials);

  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/v1/auth/login`,
      body,
      config
    );
    // console.log(res.data);

    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });

    // Only here, for production back end we will include email confirmation before hand -> should have been added already for dev and prod env
    dispatch(loadUser());
  } catch (error) {
    console.log(error.response.data);

    // Additionally, Brad used here the setAlert for errors showing up
    dispatch(setAlert(error.response.data.error, 'danger'));

    dispatch({
      type: LOGIN_FAIL,
      payload: error.response.data,
    });
  }
};

// Logout
export const logout = () => async (dispatch) => {
  dispatch({ type: LOGOUT });
};

// Update user data (allowed: name, email, role)
export const updateUser = (id, newUser) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = JSON.stringify(newUser);

  try {
    const res = await axios.put(
      `${process.env.REACT_APP_BACKEND_URL}/api/v1/users/${id}`,
      body,
      config
    );
    // console.log(res.data);

    dispatch({
      type: USER_UPDATED,
      payload: res.data,
    });

    dispatch(loadUser());
  } catch (error) {
    console.log(error.response.data);

    // Additionally, Brad used here the setAlert for errors showing up
    dispatch(setAlert(error.response.data.error, 'danger'));

    dispatch({
      type: USER_ERROR,
      payload: error.response.data,
    });
  }
};

// Update user password
export const updateUserPassword = (id, password, newPassword) => async (
  dispatch
) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const passwords = { password, newPassword };

  const body = JSON.stringify(passwords);

  try {
    const res = await axios.put(
      `${process.env.REACT_APP_BACKEND_URL}/api/v1/users/${id}/changepassword`,
      body,
      config
    );
    // console.log(res.data);

    dispatch({
      type: USER_PASSWORD_UPDATED,
      payload: res.data,
    });

    dispatch(setAlert(res.data.data, 'success'));
    dispatch(loadUser());
  } catch (error) {
    console.log(error.response.data);

    // Additionally, Brad used here the setAlert for errors showing up
    dispatch(setAlert(error.response.data.error, 'danger'));

    dispatch({
      type: USER_ERROR,
      payload: error.response.data,
    });
  }
};

// Delete user
export const deleteUser = (id) => async (dispatch) => {
  try {
    await axios.delete(
      `${process.env.REACT_APP_BACKEND_URL}/api/v1/users/${id}`
    );

    dispatch({ type: USER_DELETED });
  } catch (error) {
    console.log(error);

    dispatch({
      type: USER_ERROR,
      payload: error.response.data,
    });
  }
};
