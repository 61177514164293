import React from "react";
import { Layout } from "antd";

import "./AnomaliesFooter.css";

const { Footer } = Layout;

const AnomaliesFooter = () => {
  return (
    <Footer className="AnomaliesFooter">
      <div className="AnomaliesFooter-text">
        <b>THE TACTICAL BRIEF</b> {`© ${new Date().getFullYear()}`}
      </div>
    </Footer>
  );
};

export default AnomaliesFooter;
