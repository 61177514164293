import * as d3 from "d3";

import tooltips from "./drawPrvriChartForecast-tooltips";

const drawPrvriChartForecast = (nodeId, width, height, data) => {
  // GENERAL NODE INFORMATION
  const classChartForecastDiv = "PeriodicReturnViewerResultItem-chart-forecast";

  // DATA PREPARATION --------------------------------------------------------------------------------
  // // Defining how many digits to show when rounding
  const digitCount =
    data.category === "forex" && !data.symbol.includes("JPY") ? 5 : 2;
  // // Extracting constants
  const period = data.periodDays;
  const adjCloseLast = data.adjCloseList[0];
  const returnMean = data.periodicReturnsMean;
  const returnLast = data.periodicReturnsLast;
  const arithReturnLast = (Math.E ** (returnLast / 100) - 1) * 100;

  // // Composing chart data
  const dataChart = data.dateList.slice(0, 2 * period).map((d, idx) => ({
    date: d,
    adjClose: data.adjCloseList[idx],
  }));

  // // Calculating return and price forecast
  const logReturnForecast = 2 * returnMean - returnLast;
  const arithReturnForecast = (Math.E ** (logReturnForecast / 100) - 1) * 100;
  const adjCloseForecast = adjCloseLast * (arithReturnForecast / 100 + 1);

  // DIMENSIONS --------------------------------------------------------------------------------
  const margin = { top: 30, right: 10, bottom: 28, left: 40 };
  const widthChart = width - margin.left - margin.right;
  const heightChart = height - margin.top - margin.bottom;

  const svg = d3
    .select(`#${nodeId}`)
    .append("svg")
    .attr("width", width)
    .attr("height", height);
  // .style('border', '1px solid gold')
  const g = svg
    .append("g")
    .attr("transform", `translate(${margin.left}, ${margin.top})`);

  // DEFs --------------------------------------------------------------------------------
  const defs = svg.append("defs");

  const colorMainLine = "#002766";
  const colorRed = "#bf1216";
  const colorGreenProfit = "#389e0d";
  const colorRedLoss = "#ff7875";

  // LABELS --------------------------------------------------------------------------------
  // // Title
  g.append("text")
    .attr("class", "x axis-label")
    .attr("x", (1 / 2) * widthChart)
    .attr("y", -12)
    .attr("font-size", "13px")
    .attr("font-weight", 400)
    .attr("fill", "#656565")
    .attr("text-anchor", "middle")
    .text("Statistic Expection for Upcoming Period");
  // // Close Price
  g.append("text")
    .attr("class", "chart-label close")
    .attr("x", widthChart)
    // .attr('y', arithReturnForecast > 0 ? heightChart-5 : 5)
    .attr("y", heightChart - 3)
    .attr("font-size", "9px")
    .attr("font-weight", 300)
    .attr("fill", "#878787")
    .attr("opacity", 0.8)
    .attr("text-anchor", "end")
    .text("arithmetic returns");

  // SCALES --------------------------------------------------------------------------------
  const dateDataStart = dataChart[dataChart.length - 1].date;
  const dateDataEnd = dataChart[0].date;
  const periodWithWeekends = Math.floor((period / 2) * 3);
  const dateChartEnd = new Date(
    dateDataEnd.setDate(dateDataEnd.getDate()) +
      1.25 * periodWithWeekends * 86400000
  );
  const xScale = d3
    .scaleTime()
    .range([0, widthChart])
    .domain([dateDataStart, dateChartEnd]);

  const [closeMin, closeMax] = d3.extent(dataChart, (d) => d.adjClose);
  const yMin = d3.min([closeMin, adjCloseForecast]);
  const yMax = d3.max([closeMax, adjCloseForecast]);
  const yScaleSpacing = (yMax - yMin) * 0.2;
  const yScale = d3
    .scaleLinear()
    .range([heightChart, 0])
    .domain([yMin - yScaleSpacing, yMax + yScaleSpacing / 2]);

  // AXES --------------------------------------------------------------------------------
  // // X AXIS
  const xAxisBottomCall = d3
    .axisBottom()
    .scale(xScale)
    .ticks(5)
    .tickSizeOuter(0)
    .tickSizeInner(5)
    .tickFormat(d3.timeFormat("%b'%y"));
  const xAxisBottom = g
    .append("g")
    .attr("class", "x axis bottoms")
    .attr("transform", `translate(0, ${heightChart})`);
  xAxisBottom.call(xAxisBottomCall);
  // // AXES STYLING
  d3.selectAll(`.${classChartForecastDiv} .axis path`).attr(
    "stroke",
    "#878787"
  );
  d3.selectAll(`.${classChartForecastDiv} .axis line`).attr(
    "stroke",
    "#878787"
  );
  d3.selectAll(`.${classChartForecastDiv} .axis text`)
    .attr("fill", "#878787")
    .attr("font-size", "10px");

  // LINES --------------------------------------------------------------------------------
  // // Close
  const lineGeneratorClose = d3
    .line()
    .x((d) => xScale(d.date))
    .y((d) => yScale(d.adjClose));
  g.append("path")
    .attr("class", "main-line")
    .attr("id", "line-close")
    .attr("d", lineGeneratorClose(dataChart))
    .attr("fill", "none")
    .attr("stroke", colorMainLine)
    .attr("stroke-width", 1);

  // TARGET --------------------------------------------------------------------------------
  // // Target Gradient
  const colorTargetPerformance =
    adjCloseForecast > adjCloseLast ? colorGreenProfit : colorRedLoss;
  const colorTargetRect = colorMainLine;
  const gradientTarget = defs
    .append("linearGradient")
    .attr("id", "targetGradient")
    .attr("x1", "100%")
    .attr("x2", "100%")
    .attr("y1", adjCloseForecast > adjCloseLast ? "100%" : "0%")
    .attr("y2", adjCloseForecast > adjCloseLast ? "0%" : "100%");
  gradientTarget
    .append("stop")
    .attr("class", "start")
    .attr("offset", "0%")
    .attr("stop-color", colorTargetRect)
    .attr("stop-opacity", 1);
  gradientTarget
    .append("stop")
    .attr("class", "end")
    .attr("offset", "100%")
    .attr("stop-color", colorTargetRect)
    .attr("stop-opacity", 0);
  // // Target Rect
  const dateNextPeriodEnd = new Date(
    dateDataEnd.setDate(dateDataEnd.getDate()) + periodWithWeekends * 86400000
  );
  g.append("rect")
    .attr("class", "rect target")
    .attr("x", xScale(dateNextPeriodEnd))
    .attr(
      "y",
      arithReturnForecast > 0
        ? yScale(adjCloseForecast) - heightChart * 0.1
        : yScale(adjCloseForecast)
    )
    .attr("width", widthChart - xScale(dateNextPeriodEnd))
    .attr("height", heightChart * 0.1)
    .attr("fill", "url(#targetGradient)");
  // // Target Text - price
  g.append("text")
    .attr("class", "text target price")
    .attr("x", xScale(dateNextPeriodEnd) - 5)
    .attr("y", yScale(adjCloseForecast) + 3.5)
    .attr("text-anchor", "end")
    .attr("font-size", "10px")
    .attr("fill", "#878787")
    .text(`$${adjCloseForecast.toFixed(digitCount)}`);
  // // Target Text - performance
  g.append("text")
    .attr("class", "text target price")
    .attr("x", xScale(dateNextPeriodEnd) - 5)
    .attr(
      "y",
      arithReturnForecast > 0
        ? yScale(adjCloseForecast) + 3.5 + 15
        : yScale(adjCloseForecast) - 10
    )
    .attr("font-size", "11px")
    .attr("font-weight", "500")
    .attr("fill", colorTargetPerformance)
    .attr("text-anchor", "end")
    .text(
      `${arithReturnForecast > 0 ? "+" : ""}${arithReturnForecast.toFixed(2)} %`
    );
  // // Target Line - vertical
  g.append("line")
    .attr("class", "line target vertical")
    .attr("x1", xScale(dateNextPeriodEnd))
    .attr("x2", xScale(dateNextPeriodEnd))
    .attr("y1", yScale(adjCloseForecast))
    .attr("y2", yScale(adjCloseLast))
    .attr("stroke", colorTargetPerformance)
    .attr("stroke-width", 0.7)
    .attr("stroke-dasharray", "1,1")
    .attr("opacity", 1)
    .lower();

  // UTILITY ELEMENTS --------------------------------------------------------------------------------
  // // Close
  // // // Close - circle
  g.append("circle")
    .attr("class", "utility-circle close")
    .attr("cx", xScale(dateDataEnd))
    .attr("cy", yScale(adjCloseLast))
    .attr("r", 3)
    .attr("stroke", colorRed)
    .attr("stroke-width", 1.2)
    .attr("fill", "transparent");
  // // // Close - horizontal line
  g.append("line")
    .attr("class", "utility-line close")
    // .attr('x1', xScale(dataChart[Math.floor((period-1)*1.1)].date))
    .attr("x1", xScale(dataChart[period - 1].date))
    .attr("x2", widthChart)
    .attr("y1", yScale(adjCloseLast))
    .attr("y2", yScale(adjCloseLast))
    .attr("stroke", "#333")
    .attr("stroke-width", 0.7)
    .attr("stroke-dasharray", "1,1")
    .attr("opacity", 0.5)
    .lower();
  // // // Close - price label rect
  g.append("rect")
    .attr("class", "utility-label-rect last-close")
    .attr("width", 40)
    .attr("height", 14)
    .attr("x", xScale(dateDataEnd) - 50)
    .attr("y", yScale(adjCloseLast) - 7)
    .attr("fill", "#fff")
    .attr("opacity", 0.85);
  // // // Close - price label text
  g.append("text")
    .attr("class", "utility-label-text last-close")
    .attr("x", xScale(dateDataEnd) - 50 + 20)
    .attr("y", yScale(adjCloseLast) + 3.5)
    .attr("font-size", "10px")
    .attr("fill", "#878787")
    .attr("text-anchor", "middle")
    .text(`$${adjCloseLast.toFixed(digitCount)}`);

  // // Last Period
  // // // Last Period Start - vertical line
  const colorLastPeriodPerformance =
    returnLast > 0 ? colorGreenProfit : colorRedLoss;
  const dataLastPeriodStart = dataChart[period - 1];
  const yMidLastPeriodToNow =
    (yScale(adjCloseLast) - yScale(dataLastPeriodStart.adjClose)) / 2 +
    yScale(dataLastPeriodStart.adjClose);
  g.append("line")
    .attr("class", "utility-line last-period")
    .attr("x1", xScale(dataLastPeriodStart.date))
    .attr("x2", xScale(dataLastPeriodStart.date))
    .attr("y1", yScale(dataLastPeriodStart.adjClose))
    .attr("y2", yScale(adjCloseLast))
    .attr("stroke", colorLastPeriodPerformance)
    .attr("stroke-width", 0.7)
    .attr("stroke-dasharray", "1,1")
    .attr("opacity", 1)
    .lower();
  g.append("rect")
    .attr("class", "utility-label-rect last-period")
    .attr("width", 50)
    .attr("height", 14)
    .attr("x", xScale(dataLastPeriodStart.date) - 50 - 5)
    .attr("y", yMidLastPeriodToNow - 7)
    .attr("fill", "#fff")
    .attr("opacity", 0.85);
  g.append("text")
    .attr("class", "utility-label-text last-period")
    .attr("x", xScale(dataLastPeriodStart.date) - 25 - 5)
    .attr("y", yMidLastPeriodToNow + 3.5)
    .attr("font-size", "10px")
    .attr("fill", colorLastPeriodPerformance)
    .attr("text-anchor", "middle")
    .text(`${arithReturnLast > 0 ? "+" : ""}${arithReturnLast.toFixed(2)} %`);

  tooltips(
    data.category,
    data.symbol,
    classChartForecastDiv,
    g,
    widthChart,
    heightChart,
    xScale,
    yScale
  );
};

export default drawPrvriChartForecast;
