import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Layout } from "antd";
import { MenuOutlined, UserOutlined, LogoutOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";

// Redux
import { connect } from "react-redux";
import { logout } from "../redux/actions/authActions";

// Self-made
import { subMenuEntries } from "./MainHeader-utils";

//Styling
import "./MainHeader.css";
import logo from "../style/logos/ttb_logo_main_no_gradient.svg";
// import imgMacroHive from "../style/images/logo_macro_hive_on_dark.png";

const { Header } = Layout;

const MainHeader = (props) => {
  const {
    auth: { isAuthenticated, loading },
    logout,
  } = props;

  const [subMenuOpen, setSubMenuOpen] = useState(false);

  const handleClickMenu = () => {
    setSubMenuOpen(!subMenuOpen);
  };

  // Setting header links with respect to auth status
  const guestLinks = (
    <div
      className="MainHeader-container-authentication"
      onClick={() => setSubMenuOpen(false)}
    >
      <Link to="/">
        <span className="MainHeader-span-login">
          <UserOutlined className="MainHeader-icon-login" />
          Login | Register
        </span>
      </Link>
    </div>
  );
  const authLinks = (
    <div
      className="MainHeader-container-authentication"
      onClick={() => setSubMenuOpen(false)}
    >
      <Link to="/main/account">
        <span className="MainHeader-span-account">
          <UserOutlined className="MainHeader-icon-account" />
          Account
        </span>
      </Link>
      <Link to="/" onClick={logout}>
        <span className="MainHeader-span-logout">
          <LogoutOutlined className="MainHeader-icon-logout" />
          Logout
        </span>
      </Link>
    </div>
  );

  // Header sub menu
  const subMenu = (
    <div
      className="MainHeader-submenu-backdrop"
      style={{ display: subMenuOpen ? "initial" : "none" }}
      onClick={() => setSubMenuOpen(false)}
    >
      <div
        className="MainHeader-submenu"
        style={{
          display: subMenuOpen ? "flex" : "none",
        }}
      >
        <div className="MainHeader-submenu-container">
          {subMenuEntries
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map((obj, i) => {
              // const linkUrl = isAuthenticated ? obj.urlPath : obj.urlPathNotAuthenticated
              const linkUrl = obj.urlPath;
              const linkTarget = obj.urlIntern ? "_self" : "_blank";

              return (
                <div
                  className={`MainHeader-submenu-entry ${
                    obj.active ? "entry-active" : "entry-inactive"
                  }`}
                  key={i}
                >
                  {obj.urlIntern ? (
                    <Link
                      to={{
                        pathname: linkUrl,
                        state: { requestedUrl: obj.urlPath },
                      }}
                      target={linkTarget}
                    >
                      {obj.name}
                    </Link>
                  ) : (
                    <a
                      href={linkUrl}
                      target={linkTarget}
                      rel="noopener noreferrer"
                    >
                      {obj.name}
                      {obj.name === "Market Views" ? (
                        <span className="MainHeader-submenu-entry-public">
                          public
                        </span>
                      ) : null}
                    </a>
                  )}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );

  return (
    <Header className="MainHeader">
      <div
        className="MainHeader-logo"
        style={{ top: `${(1920 / window.innerWidth) * 22}px` }}
        onClick={() => setSubMenuOpen(false)}
      >
        <Link to="/">
          <img
            src={logo}
            style={{ width: `${(120 * window.innerWidth) / 1920}px` }}
            alt="..."
          />
        </Link>
      </div>

      <div className="MainHeader-container">
        <div className="MainHeader-menu" onClick={handleClickMenu}>
          <MenuOutlined
            className="MainHeader-menu-icon"
            style={{ fontSize: "14px" }}
          />
          Menu
        </div>
        {/* <div className='MainHeader-comment'>
          <a
            href='https://macrohive.com/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src={imgMacroHive} alt='Macro Hive' height={33} />
          </a>{' '}
          Preview
        </div> */}
        {!loading ? (isAuthenticated ? authLinks : guestLinks) : null}
      </div>

      {subMenu}
    </Header>
  );
};

MainHeader.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logout })(MainHeader);
