import * as d3 from 'd3';

const active = {
  fillTitle: '#ffffff',
  fillText: '#dddddd',
  border: {
    'market-views': '#1890ff',
    education: '#b3b3b3',
    'analytic-toolkit': '#737373',
    community: '#0000ff',
  },
};
const inactive = {
  fill: '#333333',
};

const sets = ['market-views', 'education', 'analytic-toolkit', 'community'];

const dialTextsAll = [
  'dial-text-market-views',
  'dial-text-anomalies',
  'dial-text-volatility',
  'dial-text-flows',
  'dial-text-gamma',
  'dial-text-spreads',
  'dial-text-seasonality',
  'dial-text-portfolio',
  'dial-text-timing',
  'dial-text-economy',
  'dial-text-sectors',
  'dial-text-calendar',
  'dial-text-cot',
];
const dialTextsByState = {
  'market-views': ['dial-text-market-views'],
  education: [
    'dial-text-anomalies',
    'dial-text-volatility',
    'dial-text-flows',
    'dial-text-gamma',
    'dial-text-spreads',
    'dial-text-seasonality',
    'dial-text-timing',
    'dial-text-economy',
    'dial-text-sectors',
    'dial-text-calendar',
    'dial-text-cot',
  ],
  'analytic-toolkit': [
    'dial-text-anomalies',
    'dial-text-volatility',
    'dial-text-flows',
    'dial-text-gamma',
    'dial-text-spreads',
    'dial-text-seasonality',
    'dial-text-timing',
    'dial-text-economy',
    'dial-text-sectors',
    'dial-text-calendar',
    'dial-text-cot',
  ],
  community: ['dial-text-portfolio'],
};

const adjustHoverStyles = (state) => {
  const t = d3.transition().duration(250).ease(d3.easeLinear);

  const setsNotSelected = sets.filter((s) => s !== state);

  if (state !== 'initial') {
    d3.select(`#${state}-text`).transition(t).attr('fill', active.fillText);
    d3.select(`#${state}-title tspan`)
      .transition(t)
      .attr('fill', active.fillTitle);
    d3.select(`#${state}-border`)
      .transition(t)
      .attr('stroke', active.border[state])
      .attr('stroke-width', 1.2);

    setsNotSelected.forEach((s) => {
      d3.select(`#${s}-text`).transition(t).attr('fill', inactive.fill);
      d3.select(`#${s}-title tspan`).transition(t).attr('fill', inactive.fill);
      d3.select(`#${s}-border`)
        .transition(t)
        .attr('stroke', inactive.fill)
        .attr('stroke-width', 0.7);
    });

    const dialTextsNotSelected = dialTextsAll.filter(
      (s) => !dialTextsByState[state].includes(s)
    );

    dialTextsNotSelected.forEach((s) => {
      if (s !== 'dial-text-market-views') {
        d3.selectAll(`#${s} tspan`).transition(t).attr('fill', inactive.fill);
      } else {
        d3.selectAll(`#${s}`).transition(t).attr('fill', inactive.fill);
      }
    });
  } else {
    dialTextsAll.forEach((s) => {
      if (s !== 'dial-text-market-views') {
        d3.selectAll(`#${s} tspan`)
          .transition(t)
          .attr('fill', active.fillTitle);
      } else {
        d3.selectAll(`#${s}`).transition(t).attr('fill', active.fillTitle);
      }
    });
    sets.forEach((s) => {
      d3.select(`#${s}-text`).transition(t).attr('fill', active.fillText);
      d3.select(`#${s}-title tspan`)
        .transition(t)
        .attr('fill', active.fillTitle);
      d3.select(`#${s}-border`)
        .transition(t)
        .attr('stroke', active.border[s])
        .attr('stroke-width', 0.95);
    });
  }
};

export default adjustHoverStyles;
