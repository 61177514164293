import React from "react";
import { Select } from "antd";

import { GICS_SECTORS } from "./SECTORS";

import "./CategorySelect.css";

const { Option, OptGroup } = Select;

const CategorySelect = ({ category, setCategory }) => {
  const optionsCategory = [
    {
      name: "FOREX",
      value: "forex",
    },
    {
      name: "ETFs",
      value: "etfs",
    },
    {
      name: "Stocks",
      value: "stocks",
    },
    {
      name: "ETFs and Stocks",
      value: "etfs+stocks",
    },
  ];

  const dropdownCategory = (
    <Select
      className="CategorySelect-dropdown"
      listHeight={Math.floor(0.75 * window.innerHeight)}
      placeholder="Category"
      value={category !== null ? category : undefined}
      onChange={(val) => setCategory(val)}
    >
      <OptGroup label={"General"}>
        {optionsCategory.map((obj, i) => (
          <Option
            key={obj.value}
            value={obj.value}
            style={{ fontWeight: 300, fontSize: "15px" }}
          >
            {obj.name}
          </Option>
        ))}
      </OptGroup>
      <OptGroup label={"Stocks by GICS Sectors"}>
        {GICS_SECTORS.map((obj, i) => (
          <Option
            key={obj.symbol}
            value={obj.symbol}
            style={{ fontWeight: 300, fontSize: "15px" }}
          >
            {`${obj.symbol} - ${obj.name}`}
          </Option>
        ))}
      </OptGroup>
    </Select>
  );

  return <div className="CategorySelect">{dropdownCategory}</div>;
};

export default CategorySelect;
