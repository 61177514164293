import React, { createContext, Component } from 'react';

export const TimingContext = createContext();

export class TimingContextProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toolSelected: 'safetyStop',
    };
  }

  changeTool = (newTool) => {
    this.setState((currState) => ({ ...currState, toolSelected: newTool }));
  };

  render() {
    return (
      <TimingContext.Provider
        value={{
          ...this.state,
          changeTool: this.changeTool,
        }}
      >
        {this.props.children}
      </TimingContext.Provider>
    );
  }
}
