import { DATA_LOADED, DATA_ERROR, IS_LOADING, INITIAL_STATE } from '../types';

const initialState = {
  loading: null,
  data: null,
  error: null,
};

export default function (state = initialState, action) {
  const { type, payload, name } = action;

  switch (type) {
    case IS_LOADING:
      return {
        loading: { ...state.loading, [name]: true },
        data: { ...state.data, [name]: null },
        error: { ...state.error, [name]: null },
      };
    case DATA_LOADED:
      return {
        ...state,
        loading: { ...state.loading, [name]: false },
        data: { ...state.data, [name]: payload.data },
      };
    case DATA_ERROR:
      return {
        ...state,
        loading: { ...state.loading, [name]: false },
        data: { ...state.data, [name]: null },
        error: { ...state.error, [name]: payload },
      };
    case INITIAL_STATE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
