import * as d3 from 'd3';

const tooltips = (
  nodeId,
  gChart,
  dataVixDatesChart,
  dataVixValuesChart,
  winRates,
  inputWinRateMaDays,
  winRateMaValues,
  dataReturnsDates,
  returnMaValues,
  widthChart,
  heightChart,
  heightReturnChart,
  xScale,
  yScaleReturn,
  yScaleWinRate,
  yScaleVix
) => {
  // STYLES  -----------------------------------------------------
  const colors = {
    axisTitle: '#5D5D5D',
    axisLabel: '#878787',
    axisLine: '#878787',
    errorBars: '#434343',
    blueMain: '#1890ff',
    blueDarker: '#126cbf',
    blueDarkest: '#002766',
    blueLightest: '#f5f5f5',
    red: '#ff181d',
    redLight: '#ffa8aa',
    redLabel: '#bf1216',
    green: '#4bc219',
    greenLight: '#bce8a9',
    tooltipHoverLine: '#d3d3d3',
    tooltipLabel: '#333',
    tooltipDateLabelFill: '#fff',
    tooltipCircleStrokeBlueDarker: 'rgba(18,108,191,0.75)',
  };

  // DATA PREPARATION  -----------------------------------------------------
  const dataVixDatesReversed = [...dataVixDatesChart].reverse();
  const dataVixValuesReversed = [...dataVixValuesChart].reverse();

  const formatTime = d3.timeFormat('%Y-%m-%d');
  const bisectDate = d3.bisector((d) => d).left;

  const focus = gChart
    .append('g')
    .attr('class', `${nodeId} focus`)
    .attr('display', 'none');

  // X Hover line -----------------------------------------------------------------------------
  focus
    .append('line')
    .attr('class', 'x-hover-line hover-line')
    .attr('y1', 0)
    .attr('y2', heightChart + 10)
    .attr('stroke', colors.tooltipHoverLine)
    .attr('stroke-width', 0.75);

  // Hover Date Info  -----------------------------------------------------------------------------
  const dateRect = focus
    .append('rect')
    .attr('class', 'date-hover-rect hover-rect')
    .attr('width', 64)
    .attr('height', 14)
    .attr('y', heightChart + 5)
    .attr('x', -32)
    .attr('ry', 1)
    .attr('rx', 1)
    .attr('fill', colors.tooltipDateLabelFill)
    .attr('stroke', colors.tooltipLabel)
    .attr('stroke-width', '0.5')
    .attr('opacity', 0.8);
  const dateText = focus
    .append('text')
    .attr('class', 'date-hover-text hover-text')
    .attr('y', heightChart + 16)
    .attr('text-anchor', 'middle')
    .attr('font-size', '10px')
    .attr('fill', colors.axisTitle);

  // // Hover Return Info --------------------------------------------------------------
  // Hover line
  const returnMovableLine = focus
    .append('line')
    .attr('class', 'return-movable-hover-line hover-line')
    .attr('x1', 0)
    .attr('x2', widthChart)
    .attr('stroke', colors.tooltipHoverLine)
    .attr('stroke-width', 0.75)
    .attr('visibility', 'hidden');
  const returnMovableCircle = focus
    .append('circle')
    .attr('class', 'return-movable-hover-circle hover-circle')
    .attr('visibility', 'hidden');
  const returnMovableRect = focus
    .append('rect')
    .attr('class', 'return-movable-hover-rect hover-rect chart-hover-rect')
    .attr('visibility', 'hidden');
  const returnMovableText = focus
    .append('text')
    .attr('class', 'return-movable-hover-text hover-text chart-hover-text')
    .attr('visibility', 'hidden');
  const returnMaCircle = focus
    .append('circle')
    .attr('class', 'return-hover-circle hover-circle')
    .attr('visibility', 'hidden');
  const returnMaRect = focus
    .append('rect')
    .attr('class', 'return-hover-rect hover-rect chart-hover-rect')
    .attr('visibility', 'hidden');
  const returnMaText = focus
    .append('text')
    .attr('class', 'return-hover-text hover-text chart-hover-text')
    .attr('visibility', 'hidden');

  // Hover WIN RATE & WIN RATE MOVING AVERAGE Info ----------------------------------------------------
  const winRateRect = focus
    .append('rect')
    .attr('class', 'win-rate-hover-rect hover-rect chart-hover-rect');
  const winRateText = focus
    .append('text')
    .attr('class', 'win-rate-hover-text hover-text chart-hover-text');
  const winRateMaRect = focus
    .append('rect')
    .attr('class', 'win-rate-ma-hover-rect hover-rect chart-hover-rect');
  const winRateMaText = focus
    .append('text')
    .attr('class', 'win-rate-ma-hover-text hover-text chart-hover-text');
  const winRateCircle = focus
    .append('circle')
    .attr('class', 'win-rate-hover-circle hover-circle');
  const winRateMaCircle = focus
    .append('circle')
    .attr('class', 'win-rate-ma-hover-circle hover-circle');

  // Hover VIX Info -----------------------------------------------------------------------------------
  const vixRect = focus
    .append('rect')
    .attr('class', 'vix-hover-rect hover-rect chart-hover-rect');
  const vixText = focus
    .append('text')
    .attr('class', 'vix-hover-text hover-text chart-hover-text');
  focus.append('circle').attr('class', 'vix-hover-circle hover-circle');

  // STYLING HOVER ELEMENTS ---------------------------------------------------------------------------
  // // RECT STYLES
  focus
    .selectAll('.chart-hover-rect')
    .attr('width', 46)
    .attr('height', 14)
    .attr('y', -18)
    .attr('x', 6)
    .attr('ry', 1)
    .attr('rx', 1)
    .attr('fill', colors.tooltipLabel)
    .attr('opacity', 0.95);
  winRateRect.attr('fill', colors.blueDarker);

  // // TEXT STYLES
  focus
    .selectAll('.chart-hover-text')
    .attr('y', -7)
    .attr('x', 4 + 23)
    .attr('text-anchor', 'middle')
    .attr('font-size', '10px')
    .attr('fill', '#fff');

  // CIRCLE STYLES
  focus
    .selectAll('.hover-circle')
    .attr('r', 2.5)
    .attr('fill', 'rgba(255,255,255,0.25')
    .attr('stroke', 'rgba(51,48,0,0.75')
    .attr('stroke-width', 1.2);
  winRateCircle.attr('stroke', colors.tooltipCircleStrokeBlueDarker);

  // HOVER OVERLAY ----------------------------------------------------------------------------
  gChart
    .append('rect')
    .attr('class', 'overlay')
    .attr('width', widthChart)
    .attr('height', heightChart)
    .attr('fill', 'transparent')
    .on('mouseover', function () {
      focus.attr('display', null);
    })
    .on('mouseout', function () {
      focus.attr('display', 'none');
    })
    .on('mousemove', mousemove);

  function mousemove() {
    const x0 = xScale.invert(d3.mouse(this)[0]);
    const i = bisectDate(dataVixDatesReversed, x0, 1);
    const d0 = dataVixDatesReversed[i - 1];
    const d1 = dataVixDatesReversed[i] || dataVixDatesReversed[i - 1]; // ||-hack due to d1 undefined error at edge
    const d = x0 - d0 > d1 - x0 ? d1 : d0;

    if (xScale(d) > widthChart - 32) {
      dateRect.transition().duration(100).attr('x', -64);
      dateText.transition().duration(100).attr('x', -32);
    } else {
      dateRect.transition().duration(100).attr('x', -32);
      dateText.transition().duration(100).attr('x', 0);
    }

    // DATE HOVER UPDATES ----------------------------------------------------------------------------------
    focus
      .select('.x-hover-line')
      .attr('transform', 'translate(' + xScale(d) + ',' + 0 + ')');
    focus
      .select('.date-hover-rect')
      .attr('transform', 'translate(' + xScale(d) + ',' + 0 + ')');
    focus
      .select('.date-hover-text')
      .attr('transform', 'translate(' + xScale(d) + ',' + 0 + ')')
      .text(formatTime(d));

    // GENERAL LABEL REPOSITIONING RULES WHEN APPROACHING RIGHT END OF CHART-----------------------------------
    if (xScale(d) > widthChart - 52) {
      returnMovableRect.transition().duration(100).attr('x', -52);
      returnMaRect.transition().duration(100).attr('x', -52);
      returnMovableText.transition().duration(100).attr('x', -29);
      returnMaText.transition().duration(100).attr('x', -29);
      winRateRect.transition().duration(100).attr('x', -52);
      winRateMaRect.transition().duration(100).attr('x', -52);
      winRateText.transition().duration(100).attr('x', -29);
      winRateMaText.transition().duration(100).attr('x', -29);
      vixRect.transition().duration(100).attr('x', -52);
      vixText.transition().duration(100).attr('x', -29);
    } else {
      returnMovableRect.transition().duration(100).attr('x', 6);
      returnMaRect.transition().duration(100).attr('x', 6);
      returnMovableText.transition().duration(100).attr('x', 29);
      returnMaText.transition().duration(100).attr('x', 29);
      winRateRect.transition().duration(100).attr('x', 6);
      winRateMaRect.transition().duration(100).attr('x', 6);
      winRateText.transition().duration(100).attr('x', 29);
      winRateMaText.transition().duration(100).attr('x', 29);
      vixRect.transition().duration(100).attr('x', 6);
      vixText.transition().duration(100).attr('x', 29);
    }
    // RETURN HOVER UPDATES ----------------------------------------------------------------------------------
    const mouseY = d3.mouse(this)[1];

    if (mouseY < heightReturnChart) {
      const returnFromMouseY = yScaleReturn.invert(mouseY);
      returnMovableLine
        .attr('y1', mouseY)
        .attr('y2', mouseY)
        .attr('visibility', 'visible');
      returnMovableCircle
        .attr('cx', xScale(d))
        .attr('cy', mouseY)
        .attr('visibility', 'visible');
      returnMovableRect
        .attr('transform', 'translate(' + xScale(d) + ',' + mouseY + ')')
        .attr('visibility', 'visible');
      returnMovableText
        .attr('transform', 'translate(' + xScale(d) + ',' + mouseY + ')')
        .text(
          `${returnFromMouseY > 0 ? '+' : ''}${returnFromMouseY.toFixed(2)}`
        )
        .attr('visibility', 'visible');
    } else {
      returnMovableLine.attr('visibility', 'hidden');
      returnMovableCircle.attr('visibility', 'hidden');
      returnMovableRect.attr('visibility', 'hidden');
      returnMovableText.attr('visibility', 'hidden');
    }

    // WIN RATE AND WIN RATE MA HOVER UPDATES ---------------------------------------------------------------------------
    // // Determining last datapoint by index via date comparison
    const idxReturnDates = dataReturnsDates.findIndex((date) => date > d) - 1;
    const idxWinRateMaDates =
      dataReturnsDates
        .slice(inputWinRateMaDays - 1)
        .findIndex((date) => date > d) - 1;

    // // // bug work-around
    let currentWinRate, currentWinRateMa;
    if (idxReturnDates === -2) {
      currentWinRate = winRates[winRates.length - 1];
    } else if (idxReturnDates === -1) {
      currentWinRate = winRates[0];
    } else {
      currentWinRate = winRates[idxReturnDates];
    }
    if (idxWinRateMaDates === -2) {
      currentWinRateMa = winRateMaValues[winRateMaValues.length - 1];
    } else if (idxWinRateMaDates === -1) {
      currentWinRateMa = winRateMaValues[0];
      winRateMaCircle.attr('visibility', 'hidden');
      winRateMaRect.attr('visibility', 'hidden');
      winRateMaText.attr('visibility', 'hidden');
    } else {
      currentWinRateMa = winRateMaValues[idxWinRateMaDates];
      winRateMaCircle.attr('visibility', 'visible');
      winRateMaRect.attr('visibility', 'visible');
      winRateMaText.attr('visibility', 'visible');
    }

    if (inputWinRateMaDays !== 0) {
      let currentReturnMa;
      if (idxWinRateMaDates === -2) {
        currentReturnMa = returnMaValues[returnMaValues.length - 1];
      } else if (idxWinRateMaDates === -1) {
        currentReturnMa = returnMaValues[0];
        returnMaCircle.attr('visibility', 'hidden');
        returnMaRect.attr('visibility', 'hidden');
        returnMaText.attr('visibility', 'hidden');
      } else {
        currentReturnMa = returnMaValues[idxWinRateMaDates];
        returnMaCircle.attr('visibility', 'visible');
        returnMaRect.attr('visibility', 'visible');
        returnMaText.attr('visibility', 'visible');
      }
      returnMaCircle.attr(
        'transform',
        'translate(' + xScale(d) + ',' + yScaleReturn(currentReturnMa) + ')'
      );
      returnMaRect.attr(
        'transform',
        'translate(' + xScale(d) + ',' + yScaleReturn(currentReturnMa) + ')'
      );
      returnMaText
        .attr(
          'transform',
          'translate(' + xScale(d) + ',' + yScaleReturn(currentReturnMa) + ')'
        )
        .text(
          `${currentReturnMa >= 0 ? '+' : ''}${currentReturnMa.toFixed(2)}`
        );
    }
    returnMovableRect.raise();
    returnMovableText.raise();

    // // WIN RATE
    let winRateYpositionAdjustment, winRateMaYpositionAdjustment;
    if (inputWinRateMaDays === 0) {
      winRateYpositionAdjustment = 0;
      winRateMaYpositionAdjustment = 22;
    } else if (currentWinRate >= currentWinRateMa) {
      winRateYpositionAdjustment = 0;
      winRateMaYpositionAdjustment = 22;
    } else {
      winRateYpositionAdjustment = 22;
      winRateMaYpositionAdjustment = 0;
    }

    const yWinRate = yScaleWinRate(currentWinRate) + winRateYpositionAdjustment;
    const yWinRateMa =
      yScaleWinRate(currentWinRateMa) + winRateMaYpositionAdjustment;

    winRateCircle.attr(
      'transform',
      'translate(' + xScale(d) + ',' + yScaleWinRate(currentWinRate) + ')'
    );
    winRateMaCircle.attr(
      'transform',
      'translate(' + xScale(d) + ',' + yScaleWinRate(currentWinRateMa) + ')'
    );

    // // WIN RATE AND WIN RATE MA - LABEL
    winRateRect.attr(
      'transform',
      'translate(' + xScale(d) + ',' + yWinRate + ')'
    );
    winRateText
      .attr('transform', 'translate(' + xScale(d) + ',' + yWinRate + ')')
      .text(currentWinRate.toFixed(2));

    if (inputWinRateMaDays !== 0) {
      winRateMaRect.attr(
        'transform',
        'translate(' + xScale(d) + ',' + yWinRateMa + ')'
      );
      winRateMaText
        .attr('transform', 'translate(' + xScale(d) + ',' + yWinRateMa + ')')
        .text(currentWinRateMa.toFixed(2));
    }
    // VIX HOVER UPDATES ---------------------------------------------------------------------------
    // // VIX - CIRCLE
    focus
      .select('.vix-hover-circle')
      .attr(
        'transform',
        'translate(' +
          xScale(d) +
          ',' +
          yScaleVix(dataVixValuesReversed[i]) +
          ')'
      );
    // // VIX - LABEL
    vixRect.attr(
      'transform',
      'translate(' + xScale(d) + ',' + yScaleVix(dataVixValuesReversed[i]) + ')'
    );
    vixText
      .attr(
        'transform',
        'translate(' +
          xScale(d) +
          ',' +
          yScaleVix(dataVixValuesReversed[i]) +
          ')'
      )
      .text(dataVixValuesReversed[i].toFixed(2));
  }
};

export default tooltips;
