import React from "react";
import { Layout } from "antd";

import "./SeasonalityFooter.css";

const { Footer } = Layout;

const SeasonalityFooter = () => {
  const currYear = new Date(Date.now()).getFullYear();

  return (
    <Footer className="SeasonalityFooter">
      <div className="SeasonalityFooter-text">
        <b>THE TACTICAL BRIEF</b> {`© ${currYear}`}
      </div>
    </Footer>
  );
};

export default SeasonalityFooter;
