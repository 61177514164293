import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'antd';

import './ErrorPage.css';

const ErrorPage = () => {
  let history = useHistory();

  return (
    <div className='ErrorPage'>
      <div className='ErrorPage-container'>
        <div className='ErrorPage-subcontainer-message'>
          <h1 className='ErrorPage-message'>Error: Page not found!</h1>
        </div>
        <div className='ErrorPage-subcontainer-button'>
          <Button
            className='ErrorPage-button home'
            type='primary'
            onClick={() => history.push('/')}
          >
            Go to Homepage
          </Button>
          <Button
            className='ErrorPage-button back'
            type='default'
            onClick={history.goBack}
          >
            Go Back
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
