import {
  COT_OVERVIEW_LOADING,
  COT_OVERVIEW_LOADED,
  COT_OVERVIEW_ERROR,
  COT_ELEMENT_LOADING,
  COT_ELEMENT_LOADED,
  COT_ELEMENT_ERROR,
} from '../types';

const initialState = {
  overviewLoading: true,
  elementLoading: true,
  overviewData: null,
  elementData: null,
  error: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case COT_OVERVIEW_LOADING:
      return {
        ...state,
        overviewLoading: true,
      };
    case COT_OVERVIEW_LOADED:
      return {
        ...state,
        overviewLoading: false,
        overviewData: payload.data,
      };
    case COT_OVERVIEW_ERROR:
      return {
        ...state,
        overviewLoading: false,
        overviewData: null,
        elementData: null,
        error: payload,
      };
    case COT_ELEMENT_LOADING:
      return {
        ...state,
        elementLoading: true,
        elementData: null,
      };
    case COT_ELEMENT_LOADED:
      return {
        ...state,
        elementLoading: false,
        elementData: payload,
      };
    case COT_ELEMENT_ERROR:
      return {
        ...state,
        elementLoading: false,
        elementData: null,
        error: payload,
      };
    default:
      return state;
  }
}
