import * as d3 from 'd3';

const drawPrvriHistogram = (nodeId, width, height, data, flag) => {
  // GENERAL NODE INFORMATION
  const classHistogramDiv =
    'PeriodicReturnViewerResultItem-container-histogram';

  // DATA PREPARATION
  let histData, last, mean, stdev, dataType, binColor, margin
  switch (flag) {
    case 'returns':
      histData = data.periodicReturnsList;
      last = data.periodicReturnsLast
      mean = data.periodicReturnsMean
      stdev = data.periodicReturnsStdev
      dataType = 'Returns'
      binColor = '#1890ff'
      margin = { top: 115, right: 10, bottom: 17, left: 40 };
      break;
    case 'volatility':
      histData = data.periodicStdevsList;
      last = data.periodicStdevsLast
      mean = data.periodicStdevsMean
      stdev = data.periodicStdevsStdev
      dataType = 'Volatility'
      binColor='#126cbf'
      margin = { top: 20, right: 10, bottom: 17, left: 40 };
      break;
    default:
      break
  }

  // DIMENSIONS
  const widthChart = width - margin.left - margin.right;
  const heightChart = height - margin.top - margin.bottom;

  const svg = d3
    .select(`#${nodeId}`)
    .append('svg')
    .attr('width', width)
    .attr('height', height)
    // .style('border', '1px solid green')
  const g = svg
    .append('g')
    .attr('transform', `translate(${margin.left}, ${margin.top})`);

  // LABELS
  // // Title
  if (dataType === 'Returns') { 
    g.append('text')
      .attr('class', 'chart-label title')
      .attr('x', (1 / 2) * widthChart)
      .attr('y', -15-60)
      .attr('font-size', '13px')
      .attr('font-weight', 400)
      .attr('fill', '#656565')
      .attr('text-anchor', 'middle')
      .text(
        `${data.periodDays}-Day-MR-Histograms for ${data.symbol}`
      );
    g.append('text')
      .attr('class', 'chart-label title')
      .attr('x', (1 / 2) * widthChart)
      .attr('y', -15-40)
      .attr('font-size', '13px')
      .attr('font-weight', 400)
      .attr('fill', '#656565')
      .attr('text-anchor', 'middle')
      .text(
        'Returns and Volatility'
      );
  }

  // SCALES
  const xScale = d3
    .scaleLinear()
    .range([0, widthChart])
  const yScale = d3.scaleLinear().range([heightChart, 0]);

  // AXES
  const xAxisBottomCall = d3
    .axisBottom()
    .tickSizeOuter(0)
  
  const xAxisBottom = g
    .append('g')
    .attr('class', 'x axis bottom')
    .attr('transform', `translate(0, ${heightChart})`);


  // MAIN UPDATE FUNCTION
  const updateChart = () => {
    // UPDATING SCALES
    const binCount = 30
    xScale.domain(d3.extent(histData)).nice(binCount);

    const histogram = d3.histogram().domain(xScale.domain()).thresholds(xScale.ticks(binCount))
    const bins = histogram(histData)

    yScale.domain([0, d3.max(bins, d=> d.length)*1.2]);

    // UPDATING AXES
    xAxisBottomCall.scale(xScale);
    xAxisBottom.call(xAxisBottomCall);

    // STYLING AXES
    xAxisBottom.selectAll('line').attr('y2', 3)
    xAxisBottom.selectAll('text').attr('y', 6)
    d3.selectAll(`.${classHistogramDiv} .axis path`).attr('stroke', '#878787');
    d3.selectAll(`.${classHistogramDiv} .axis line`).attr('stroke', '#878787');
    d3.selectAll(`.${classHistogramDiv} .axis text`)
      .attr('fill', '#878787')
      .attr('font-size', '10px');

    // Tooltips (don't need to be in update function)
    const tooltip = d3.select(`.${classHistogramDiv}`)
      .append('div')
      .attr('class', `${classHistogramDiv}-tooltip`)
      .style('color', '#595959')
      .style('background-color', '#fff')
      .style('border', '1px solid #595959')
      .style('border-radius', '2px')
      .style('opacity', '0')
      .style('font-size','12px')
      .style('padding', '5px 10px')
      .style('position', 'absolute')

    // MAIN RECTS
    g.selectAll('.hist-bar')
      .data(bins)
      .enter()
      .append('rect')
      .attr('class', 'hist-bar')
      .attr('x', 1)
      .attr('width', d => xScale(d.x1)-xScale(d.x0)-1)
      .attr('height', (d) => heightChart - yScale(d.length))
      .attr('transform', d => "translate(" + xScale(d.x0) + "," + yScale(d.length) + ")")
      .attr('fill', (d) => (d.x0 < last && d.x1> last) ? '#ff181d' : binColor)
      .on('mouseover', function(d) {
        d3.select(this).attr('fill', '#0c4880')
        tooltip
          .transition()
          .duration(150)
          .style("opacity", 0.9)
        tooltip
          .style("display", null)
          .style("left", (d3.event.pageX + 10) + "px")
          .style("top", (d3.event.pageY - 25) + "px")
          .html(`<span>Count: ${d.length}</span><br/><span>Range: ${d.x0} % to ${d.x1} %</span>`)
      })
      .on('mousemove', () => { 
        tooltip
          .style("left", (d3.event.pageX + 10) + "px")
          .style("top", (d3.event.pageY - 25) + "px")
      })
      .on('mouseout', function(d) {
        d3.select(this).attr('fill', (d) => (d.x0 < last && d.x1> last) ? '#ff181d' : binColor)
        tooltip
        .transition()
          .duration(150)
          .style("opacity", 0)
        .transition()
          .delay(10)
          .style("display", "none")

      })


    // Utility LINES
    const xScaleUtils = d3.scaleLinear().range([0,widthChart]).domain([bins[0].x0, bins[bins.length-1].x1])
    
    // LAST Line
    g.append('line')
      .attr('class', `utility-line ${flag} last`)
      .attr('x1', xScaleUtils(last))
      .attr('x2', xScaleUtils(last))
      .attr('y1', heightChart*(1-1/1.2)/1.25 + 6)
      .attr('y2', heightChart)
      .attr('stroke', '#bf1216')
      .attr('stroke-width', 1);
    g.append('circle')
      .attr('class', 'utility-circle last')
      .attr('cx', xScaleUtils(last))
      .attr('cy', heightChart*(1-1/1.2)/1.25+4)
      .attr('r', 3)
      .attr('stroke', '#bf1216')
      .attr('stroke-width', 1.2)
      .attr('fill', 'transparent');
    // MEAN Line
    g.append('line')
      .attr('class', `utility-line ${flag} last`)
      .attr('x1', xScaleUtils(mean))
      .attr('x2', xScaleUtils(mean))
      .attr('y1', heightChart*(1-1/1.2)/1.25)
      .attr('y2', heightChart)
      .attr('stroke', '#333')
      .attr('stroke-width', 0.7)
      .attr('opacity', 0.3)
    g.append('text')
      .attr('class', 'utility-text mean')
      .attr('y', xScaleUtils(mean)+2)
      .attr('x', -heightChart*(1-1/1.2)/1.25 + 5)
      .attr('font-size', '10px')
      .attr('font-weight', 300)
      .attr('fill', '#333')
      .attr('opacity', 0.3)
      .attr('text-anchor', 'start')
      .attr('transform', 'rotate(-90)')
      .text('mean');

    // STDEV lines
    const listStdevs = [-3, -2, -1, 1, 2, 3]
    const binMin = bins[0].x0
    const binMax = bins[bins.length-1].x1
    
    listStdevs.forEach((n) => {
      const value = mean + n * stdev;
      if (value >= binMin && value <= binMax) {
        g.append('line')
          .attr('class', 'utility-line stdev')
          .attr('x1', xScaleUtils(value))
          .attr('x2', xScaleUtils(value))
          .attr('y1', heightChart*(1-1/1.2)/1.25)
          .attr('y2', heightChart)
          .attr('stroke', '#333')
          .attr('stroke-dasharray', '1,1')
          .attr('stroke-width', 0.3 + (1 / 6) * (n * n) ** 0.5)
          .attr('opacity', 0.3 + (1 / 6) * (n * n) ** 0.5);
        g.append('text')
          .attr('class', 'utility-text stdev')
          .attr('y', xScaleUtils(value)+2)
          .attr('x', -heightChart*(1-1/1.2)/1.25 + 5)
          .attr('font-size', '10px')
          .attr('font-weight', 300)
          .attr('fill', '#333')
          .attr('text-anchor', 'start')
          .attr('transform', 'rotate(-90)')
          .attr('opacity', 0.3 + (1 / 6) * (n * n) ** 0.5)
          .text(`${n > 0 ? '+' : ''}${n}σ`);
      }
    });
  };

  updateChart();
};

export default drawPrvriHistogram;
