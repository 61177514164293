import React from 'react';
import PropTypes from 'prop-types';
import { TreeSelect } from 'antd';

// Redux
import { connect } from 'react-redux';

// import { PRODUCT_DIRECTORY } from './PRODUCT_DIRECTORY';
import SYMBOL_DIRECTORY from './SYMBOL_DIRECTORY.json';
// import {PRODUCT_DIRECTORY_FREELOADER} from './PRODUCT_DIRECTORY_FREELOADER'    // create new json for freeloader as symbol_directory_freloader
import { GICS_SECTORS } from './SECTORS';

import './ProductTreeSelect.css';

const { TreeNode } = TreeSelect;

const ProductTreeSelect = ({ selectedSymbol, handleChange, user }) => {
  // const userRole = user && user.role;

  const PRODUCTS = SYMBOL_DIRECTORY;
  // const PRODUCTS = process.env.NODE_ENV === 'development'
  //   ? PRODUCT_DIRECTORY
  //   : userRole === 'subscriber'
  //     ? PRODUCT_DIRECTORY
  //     : PRODUCT_DIRECTORY_FREELOADER

  const ETF_SECTORS = Array.from(
    new Set(SYMBOL_DIRECTORY.etfs.map((d) => d['sectorName']))
  );

  // products by categories 'etfs' and 'stocks'
  const treeNodesSymbols = Object.keys(PRODUCTS).map((category) => {
    return (
      <TreeNode
        className='ProductTreeSelect-treenode-parent'
        key={category}
        value={category}
        title={`${category.toUpperCase()} (${PRODUCTS[category].length})`}
        selectable={false}
      >
        {PRODUCTS[category].map((el, i) => {
          const symbol = el.symbol;
          const name = el.name;
          return (
            <TreeNode
              className='ProductTreeSelect-treenode-element'
              key={`${symbol} - ${name} - etfs+stocks`}
              value={`${symbol} - ${name} - etfs+stocks`}
              title={`${symbol} - ${name}`}
              // key={`etfs+stocks-${symbol}`}
            />
          );
        })}
      </TreeNode>
    );
  });

  // etfs by sectors
  const treeNodesSectorsEtfs = (
    <TreeNode
      className='ProductTreeSelect-treenode-parent'
      key='sectors-etf'
      value='sectors-etf'
      title={`ETFS BY SECTORS (${PRODUCTS.etfs.length})`}
      selectable={false}
    >
      {ETF_SECTORS.map((sector) => {
        const sectorProducts = PRODUCTS['etfs'].filter(
          (s) => s.sectorName === sector
        );
        return (
          <TreeNode
            className='ProductTreeSelect-treenode-parent'
            key={`gics-${sector}`}
            value={`gics-${sector}`}
            title={`${sector.toUpperCase()} (${sectorProducts.length})`}
            selectable={false}
          >
            {sectorProducts.map((el, i) => {
              const symbol = el.symbol;
              const name = el.name;
              return (
                <TreeNode
                  className='ProductTreeSelect-treenode-element'
                  key={`${symbol} - ${name} - sector-etfs`}
                  value={`${symbol} - ${name} - sector-etfs`}
                  title={`${symbol} - ${name}`}
                  // key={`sector-etfs-${symbol}`}
                />
              );
            })}
          </TreeNode>
        );
      })}
    </TreeNode>
  );

  // stocks by sectors
  const treeNodesSectorsStocks = (
    <TreeNode
      className='ProductTreeSelect-treenode-parent'
      key='gics'
      value='gics'
      title={`STOCKS BY GICS SECTORS (${PRODUCTS.stocks.length})`}
      selectable={false}
      // key={'sector-stocks'}
    >
      {GICS_SECTORS.map((obj) => {
        const sectorProducts = PRODUCTS['stocks'].filter(
          (s) => s.sectorSymbol === obj.symbol
        );
        return (
          <TreeNode
            className='ProductTreeSelect-treenode-parent'
            key={`gics-${obj.symbol}`}
            value={`gics-${obj.symbol}`}
            title={`${obj.symbol} - ${obj.name.toUpperCase()} (${
              sectorProducts.length
            })`}
            selectable={false}
          >
            {sectorProducts.map((el, i) => {
              const symbol = el.symbol;
              const name = el.name;
              return (
                <TreeNode
                  className='ProductTreeSelect-treenode-element'
                  key={`${symbol} - ${name} - sector-stocks`}
                  value={`${symbol} - ${name} - sector-stocks`}
                  title={`${symbol} - ${name}`}
                />
              );
            })}
          </TreeNode>
        );
      })}
    </TreeNode>
  );

  const treeNodesAll = [
    ...treeNodesSymbols,
    treeNodesSectorsEtfs,
    treeNodesSectorsStocks,
  ];

  const treeSelectProducts = (
    <TreeSelect
      className='ProductTreeSelect-treeselect'
      dropdownClassName='ProductTreeSelect-treeselect-dropdown'
      showSearch
      value={selectedSymbol}
      listHeight={Math.floor(0.75 * window.innerHeight)}
      placeholder='Product'
      treeDefaultExpandAll={false}
      onChange={(val) => handleChange(val)}
    >
      {treeNodesAll}
    </TreeSelect>
  );

  return <div className='ProductTreeSelect'>{treeSelectProducts}</div>;
};

ProductTreeSelect.propTypes = {
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, {})(ProductTreeSelect);
