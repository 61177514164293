import React, { Fragment, useContext } from "react";
import PropTypes from "prop-types";
import { Button } from "antd";

// Context
import { AnomaliesContext } from "../../../contexts/AnomaliesContext";

// Redux
import { connect } from "react-redux";
import { getPeriodicReturnViewerData } from "../../../redux/actions/dataActions";
import { getReduxStates } from "../../tools-utils/tools-util-functions";

// Features
import SpinnerWithText from "../../../main/utils/SpinnerWithText/SpinnerWithText";
import PeriodicReturnViewerResultItem from "./PeriodicReturnViewerResultItem";
import PrvAnomalyTradePerformanceBlock from "./PrvAnomalyTradePerformanceBlock";
// import PrvBacktestPathAnalysis from './PrvBacktestPathAnalysisBlock';

import ProductTreeSelect from "../../tools-utils/ProductTreeSelect";
import PeriodSelect from "../../tools-utils/PeriodSelect";
import DatePickerStartDateAdjusted from "../../tools-utils/DatePickerStartDateAdjusted";

// Styling
import "./PeriodicReturnViewerContent.css";

// // Local data for dev ---------------------------------------------------------------
// import dataTemp from './backend_prva_data.json';
// import { timeParse } from 'd3';

// const parseTime = timeParse('%Y%m%d');
// dataTemp.dateList = dataTemp.dateList.map((d) => parseTime(d));
// if (dataTemp.vixData) {
//   dataTemp.vixData.data.date = dataTemp.vixData.data.date.map((d) =>
//     parseTime(d)
//   );
// }
// if (dataTemp.currentAnomalyCategory !== 'N/A') {
//   dataTemp.anomalyTradePerformance.sigmaSections[
//     dataTemp.currentAnomaly
//   ].startDates = dataTemp.anomalyTradePerformance.sigmaSections[
//     dataTemp.currentAnomaly
//   ].startDates.map((d) => parseTime(d));
// }

// =================================== COMPONENT ===================================
const PeriodicReturnViewerContent = ({
  loading,
  data,
  error,
  getPeriodicReturnViewerData,
}) => {
  // console.log('PeriodicReturnViewerContent renders');
  // loading context
  const { prvOptions, changeOption } = useContext(AnomaliesContext);

  const { symbol, period, endDate } = prvOptions;

  // defining context related functions
  const changeSymbol = (val) => {
    changeOption("prvOptions", "symbol", val.split(" - ")[0]);
  };
  const changePeriod = (val) => {
    changeOption("prvOptions", "period", val);
  };
  const changeEndDate = (date, dateStr) => {
    changeOption("prvOptions", "endDate", dateStr.replace(/-/g, ""));
  };

  const handleClickAnalyze = () => {
    getPeriodicReturnViewerData(symbol, period, endDate);
  };

  // picking correct dataLoading, dataObj, and dataError
  let [dataLoading, dataObj, dataError] = getReduxStates(
    loading,
    data,
    error,
    "anomaliesPrv"
  );

  let resultItem;
  if (dataObj !== null) {
    // if (dataTemp !== null) {
    resultItem = (
      <Fragment>
        <PeriodicReturnViewerResultItem
          data={dataObj}
          // data={dataTemp}
        />
        <PrvAnomalyTradePerformanceBlock
          data={{
            ...dataObj.anomalyTradePerformance,
            category: dataObj.category,
            symbol: dataObj.symbol,
            adjCloseLast:
              dataObj.category === "forex" && !dataObj.symbol.includes("JPY")
                ? dataObj.adjCloseList[0].toFixed(5)
                : dataObj.adjCloseList[0].toFixed(2),
            periodDays: dataObj.periodDays,
            currentReturnDeviationInStdev: dataObj.periodicReturnsDeltaStdev,
            currentAnomaly: dataObj.currentAnomaly,
            vixData: dataObj.vixData,
          }}
          // data={{
          //   ...dataTemp.anomalyTradePerformance,
          //   symbol: dataTemp.symbol,
          //   periodDays: dataTemp.periodDays,
          //   currentReturnDeviationInStdev: dataTemp.periodicReturnsDeltaStdev,
          //   currentAnomaly: dataTemp.currentAnomaly,
          //   vixData: dataTemp.vixData,
          // }}
        />
        {/* <PrvBacktestPathAnalysis
          data={dataObj}
          // data={dataTemp}
        /> */}
      </Fragment>
    );
  }

  let validSearch = false;
  if (
    symbol !== null &&
    period !== null &&
    endDate !== "" &&
    endDate !== null
  ) {
    validSearch = true;
  }

  // console.log('[PeriodicReturnViewerContent] data:', data);

  return (
    <div className="PeriodicReturnViewerContent">
      <div className="PeriodicReturnViewerContent-container-dropdown">
        <ProductTreeSelect
          selectedSymbol={symbol}
          handleChange={changeSymbol}
        />
        <PeriodSelect
          startDayNum={5}
          endDayNum={253}
          period={period}
          setPeriod={changePeriod}
        />
        {/* <DatePicker
          className='PeriodicReturnViewerContent-datepicker'
          onChange={changeEndDate}
          value={endDate !== null && endDate !== '' && moment(endDate)}
          placeholder='End Date'
        /> */}
        <DatePickerStartDateAdjusted
          classN={"PeriodicReturnViewerContent-datepicker"}
        />
        <Button
          disabled={!dataLoading && validSearch ? false : true}
          className="PeriodicReturnViewerContent-button submit"
          type="primary"
          onClick={handleClickAnalyze}
        >
          Analyze
        </Button>
      </div>

      {dataLoading ? (
        <div className="PeriodicReturnViewerContent-Spinner">
          <SpinnerWithText />
        </div>
      ) : (
        <div className="PeriodicReturnViewerContent-container-results">
          {dataObj !== null && dataError == null ? (
            // dataTemp !== null && dataError == null ? (
            <Fragment>{resultItem}</Fragment>
          ) : (
            (dataError || dataError === undefined) && (
              <div className="PeriodicReturnViewerContent-results-error">
                {dataError}
              </div>
            )
          )}
        </div>
      )}
    </div>
  );
};

PeriodicReturnViewerContent.propTypes = {
  loading: PropTypes.object,
  data: PropTypes.object,
  error: PropTypes.object,
  getPeriodicReturnViewerData: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  loading: state.data.loading,
  data: state.data,
  error: state.data.error,
});

export default connect(mapStateToProps, {
  getPeriodicReturnViewerData,
})(PeriodicReturnViewerContent);
