import React, { useContext } from "react";
import { Button } from "antd";
import PropTypes from "prop-types";

// Redux
import { connect } from "react-redux";
import { getSeasonalityViewerData } from "../../../redux/actions/seasonalityActions";

// Context
import { SeasonalityContext } from "../../../contexts/SeasonalityContext";

// Own features
import SeasonalityViewerResultItem from "./SeasonalityViewerResultItem";
import SpinnerWithText from "../../../main/utils/SpinnerWithText/SpinnerWithText";
import ProductTreeSelect from "../../tools-utils/ProductTreeSelect";

// Styling
import "./SeasonalityViewerContent.css";

//  for local testing
// import dataTemp from "./backend_sv_data_IWM.json";

const SeasonalityViewerContent = ({
  loading,
  data,
  error,
  getSeasonalityViewerData,
}) => {
  console.log("SeasonalityViewerContent renders");

  //  for local testing
  // data = dataTemp;

  // loading context
  const { svOptions, changeOption } = useContext(SeasonalityContext);
  const { symbol } = svOptions;

  // defining context related functions
  const changeSymbol = (val) => {
    changeOption("svOptions", "symbol", val.split(" - ")[0]);
  };

  // defining handler when clicking anaylze button
  const handleClickAnalyze = () => {
    getSeasonalityViewerData(symbol);
  };

  return (
    <div className="SeasonalityViewerContent">
      <div className="SeasonalityViewerContent-container-dropdown">
        <ProductTreeSelect
          selectedSymbol={symbol}
          handleChange={changeSymbol}
        />
        <Button
          disabled={!loading && symbol !== null ? false : true}
          className="SeasonalityViewerContent-button submit"
          type="primary"
          onClick={handleClickAnalyze}
        >
          Analyze
        </Button>
      </div>

      {loading ? (
        <div className="SeasonalityViewerContent-Spinner">
          <SpinnerWithText />
        </div>
      ) : (
        <div className="SeasonalityViewerContent-container-result">
          {data !== null && error == null ? (
            data.calcError === "false" ? (
              <SeasonalityViewerResultItem data={data} />
            ) : (
              <div className="SeasonalityViewerContent-error">{`Note: ${data.calcErrorMessage}`}</div>
            )
          ) : (
            (error || error === undefined) && (
              <div className="SeasonalityViewerContent-error">{`Error: ${error.error} Please contact our team of The Tactical Brief via info@thetacticalbrief.de!`}</div>
            )
          )}
        </div>
      )}
    </div>
  );
};

SeasonalityViewerContent.propTypes = {
  loading: PropTypes.bool.isRequired,
  data: PropTypes.object,
  error: PropTypes.object,
  getSeasonalityViewerData: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  loading: state.seasonalityViewer.loading,
  data: state.seasonalityViewer.data,
  error: state.seasonalityViewer.error,
});

export default connect(mapStateToProps, {
  getSeasonalityViewerData,
})(SeasonalityViewerContent);
