import React from "react";
import { Layout } from "antd";

import MainHeader from "./MainHeader";

import ErrorPage from "./content/ErrorPage/ErrorPage";
import LandingPage from "./content/LandingPage/LandingPage";
// import BreakingPage from "./content/BreakingPage/BreakingPage";
// import LoginPage from './content/LoginPage/LoginPage';
// import RegisterPage from './content/RegisterPage/RegisterPage';
// import RegistrationSentPage from './content/RegistrationSentPage/RegistrationSentPage';
// import RegistrationConfirmationPage from './content/RegistrationConfirmationPage/RegistrationConfirmationPage';
// import ResendActivationLinkPage from './content/ResendActivationLinkPage/ResendActivationLinkPage';
// import ForgotPasswordPage from './content/ForgotPasswordPage/ForgotPasswordPage';
// import ResetPasswordPage from './content/ResetPasswordPage/ResetPasswordPage';
// import AccountPage from './content/AccountPage/AccountPage';
// import AccountDeletedPage from './content/AccountDeletedPage/AccountDeletedPage';
import ImprintPage from "./content/ImprintPage/ImprintPage";

import "./Main.css";
import bgImg from "../style/images/jeshoots-com-fzOITuS1DIQ-unsplash_compr.jpg";

const Main = (props) => {
  const getMainContent = (s) => {
    switch (s) {
      case "landingpage":
        return <LandingPage />;
      // case "breakings":
      //   return <BreakingPage />;
      // case 'login':
      //   return <LoginPage bgImg={bgImg} />;
      // case 'register':
      //   return <RegisterPage bgImg={bgImg} />;
      // case 'registrationsent':
      //   return <RegistrationSentPage bgImg={bgImg} />;
      // case 'registrationconfirmed':
      //   return (
      //     <RegistrationConfirmationPage token={props.token} bgImg={bgImg} />
      //   );
      // case 'resendactivationlink':
      //   return <ResendActivationLinkPage bgImg={bgImg} />;
      // case 'forgotpassword':
      //   return <ForgotPasswordPage bgImg={bgImg} />;
      // case 'resetpassword':
      //   return <ResetPasswordPage token={props.token} bgImg={bgImg} />;
      // case 'account':
      //   return <AccountPage bgImg={bgImg} />;
      // case 'accountdeleted':
      //   return <AccountDeletedPage bgImg={bgImg} />;
      case "imprint":
        return <ImprintPage bgImg={bgImg} />;
      case "error":
      default:
        return <ErrorPage />;
    }
  };

  const mainContent = getMainContent(props.content);

  return (
    <div className="Main">
      <Layout>
        <MainHeader />

        <Layout className="Main-content">{mainContent}</Layout>
      </Layout>
    </div>
  );
};

export default Main;
