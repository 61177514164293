import React, { createContext, Component } from 'react';

export const AnomaliesContext = createContext();

export class AnomaliesContextProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toolSelected: 'prv',
      prsOptions: {
        category: null,
        period: null,
        ranking: null,
        filter: null,
      },
      prfOptions: {
        category: null,
        period: null,
        threshold: null,
      },
      prvOptions: {
        symbol: null,
        period: null,
        endDate: null,
      },
      pcsOptions: {
        symbol: null,
        category: null,
        period: null,
      },
      pcvOptions: {
        symbols: null,
        period: null,
      },
      psssOptions: {
        symbol: null,
        category: null,
        period: null,
      },
      psvOptions: {
        symbols: null,
        period: null,
      },
    };
  }

  changeTool = (newTool) => {
    this.setState((currState) => ({ ...currState, toolSelected: newTool }));
  };

  changeOption = (type, subType, value) => {
    this.setState((currState) => ({
      ...currState,
      [type]: { ...currState[type], [subType]: value },
    }));
  };

  render() {
    return (
      <AnomaliesContext.Provider
        value={{
          ...this.state,
          changeOption: this.changeOption,
          changeTool: this.changeTool,
        }}
      >
        {this.props.children}
      </AnomaliesContext.Provider>
    );
  }
}
