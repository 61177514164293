import React from 'react'
import {Select} from 'antd'

import './PeriodSelect.css'

const {Option, OptGroup} = Select

const PeriodSelect = ({startDayNum, endDayNum, period, setPeriod}) => {

  const tradingDayInfo = {
    5: 'week',
    21: 'month',
    63: 'quarter',
    126: 'half-year',
    252: 'year'
  }
  
  const periods = new Array(endDayNum-startDayNum+1).fill(0);
  const dropdownPeriod = (
    <Select
      className='PeriodSelect-dropdown'
      showSearch
      placeholder='Period'
      value={period !== null ? period : undefined}
      onChange={(val) => setPeriod(val)}
    >
      <OptGroup
        label='Trading Days'>
        {periods.map((u, i) => (
          <Option
            key={i}
            value={i + 5}
            style={{ fontWeight: 300, fontSize: '15px' }}
          >
            {`${i + 5} days${Object.keys(tradingDayInfo).includes(""+ (i+5)) ? ' - ' + tradingDayInfo[""+ (i+5)] : ''}`}
          </Option>
        ))}
      </OptGroup>
    </Select>
  );

  return (
    <div className='PeriodSelect'>
      {dropdownPeriod}
    </div>
  )
}

export default PeriodSelect
