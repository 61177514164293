import * as d3 from 'd3';
import generateNormalizedGaussianData from './drawPrvAaeChart-utils';

function drawPrvAaeChart(nodeId, data) {
  // chart container div element ---------------------------------------
  const divChart = d3.select(`#${nodeId}`)._groups[0][0];

  // STYLES  -----------------------------------------------------
  const colors = {
    axisTitle: '#5D5D5D',
    axisLabel: '#878787',
    axisLine: '#878787',
    errorBars: '#434343',
    blueMain: '#1890ff',
    blueDarker: '#126cbf',
    blueDarkest: '#002766',
    blueLightest: '#f5f5f5',
    red: '#ff181d',
    redLight: '#ffa8aa',
    green: '#4bc219',
    greenLight: '#bce8a9',
  };
  const fontStyles = {
    axisTitle: {
      fontSize: 12,
      fontWeight: 300,
    },
    axisLabel: {
      fontSize: 10,
      fontWeight: 300,
    },
  };

  // DIMENSIONS -----------------------------------------------------------
  const width = divChart.offsetWidth;
  const height = divChart.offsetHeight;
  const margin = { top: 0, right: 15, bottom: 50, left: 70 };

  const widthChart = width - margin.left - margin.right;
  const heightChart = height - margin.top - margin.bottom;

  const chartHeightSplitFactor = 0.5;
  const heightChartReturns = heightChart * chartHeightSplitFactor;
  const heightChartProbs = heightChart * (1 - chartHeightSplitFactor);

  const svg = d3
    .select(`#${nodeId}`)
    .append('svg')
    .attr('width', width)
    .attr('height', height);
  // .style('border', '1px solid red');
  const g = svg
    .append('g')
    .attr('transform', `translate(${margin.left}, ${margin.top})`);

  // g.append('rect')
  //   .attr('x', 0)
  //   .attr('y', 0)
  //   .attr('width', widthChart)
  //   .attr('height', heightChart)
  // .attr('opacity', 0.05);

  // LABELS ------------------------------------------------------------------
  // // X AXIS TITLE
  g.append('text')
    .attr('class', 'axis-title x')
    .attr('id', 'title-days')
    .attr('x', widthChart / 2)
    .attr('y', heightChart + margin.bottom / 2 + 13)
    .attr('font-size', fontStyles.axisTitle.fontSize + 'px')
    .attr('font-weight', fontStyles.axisTitle.fontWeight)
    .attr('fill', colors.axisTitle)
    .attr('text-anchor', 'middle')
    .text('Trade Duration in Trading Days');
  // // Y AXIS TITLE RETURNS
  g.append('text')
    .attr('class', 'axis-title y')
    .attr('id', 'title-returns')
    .attr('y', (-margin.left * 3) / 4)
    .attr('x', (-1 / 2) * heightChartReturns)
    .attr('font-size', fontStyles.axisTitle.fontSize + 'px')
    .attr('font-weight', fontStyles.axisTitle.fontWeight)
    .attr('fill', colors.axisTitle)
    .attr('transform', 'rotate(-90)')
    .attr('text-anchor', 'middle')
    .text('Arithm. Return [%]');
  // // Y AXIS TITLE PROBS
  g.append('text')
    .attr('class', 'axis-title y')
    .attr('id', 'title-returns')
    .attr('y', (-margin.left * 3) / 4)
    .attr('x', (-1 / 2) * heightChartProbs - heightChartReturns)
    .attr('font-size', fontStyles.axisTitle.fontSize + 'px')
    .attr('font-weight', fontStyles.axisTitle.fontWeight)
    .attr('fill', colors.axisTitle)
    .attr('transform', 'rotate(-90)')
    .attr('text-anchor', 'middle')
    .text('Return Ranges [%]');
  // // CHART LABEL RETURN DISTRIBUTION
  g.append('text')
    .attr('class', 'chart-label')
    .attr('x', widthChart - 5)
    .attr('y', 10)
    .attr('font-size', 'calc(0.5vw + 0.2rem)')
    .attr('font-weight', 400)
    .attr('fill', colors.axisLabel)
    .attr('opacity', 0.4)
    .attr('text-anchor', 'end')
    .attr('letter-spacing', '0.25px')
    .text('RETURN DISTRIBUTION');
  // // CHART LABEL RETURN DISTRIBUTION
  g.append('text')
    .attr('class', 'chart-label')
    .attr('x', widthChart - 5)
    .attr('y', heightChartReturns + 23)
    .attr('font-size', 'calc(0.5vw + 0.2rem)')
    .attr('font-weight', 400)
    .attr('fill', colors.axisLabel)
    .attr('opacity', 0.4)
    .attr('text-anchor', 'end')
    .attr('letter-spacing', '0.25px')
    .text('RETURN PROBABILITY');

  // SCALES ------------------------------------------------------------------
  const xScale = d3
    .scaleBand()
    .range([0, widthChart])
    .domain(data.tradePeriods)
    .paddingOuter(0.25) // 1/4 since 4 trading duration
    .align(0); // has to be left aligned since we need all outer padding on the right only

  const yScaleReturnsMin = d3.min(data.currentAnomalyData.min);
  const yScaleReturnsMax = d3.max(data.currentAnomalyData.max);
  const yScaleReturnsSpacing = 0.1 * (yScaleReturnsMax - yScaleReturnsMin);
  const yScaleReturns = d3
    .scaleLinear()
    .range([heightChartReturns, 0])
    .domain([
      yScaleReturnsMin - yScaleReturnsSpacing,
      yScaleReturnsMax + yScaleReturnsSpacing,
    ]);
  const yScaleProbs = d3
    .scaleBand()
    .range([heightChart, heightChartReturns])
    .domain(['≥ -10', '≥ -5', '≥ 0', '≥ +5', '≥ +10'])
    .paddingOuter(2.25)
    .paddingInner(0.35);

  // AXES ------------------------------------------------------------------
  // // X AXES
  const xAxisBottomCall = d3.axisBottom().scale(xScale).tickSizeOuter(0);
  const xAxisBottom = g
    .append('g')
    .attr('class', 'x axis bottom')
    .attr('transform', `translate(0, ${heightChart})`)
    .call(xAxisBottomCall);
  const xAxisMidCall = d3
    .axisTop()
    .scale(xScale)
    .tickFormat('')
    .tickSizeOuter(0);
  const xAxisMid = g
    .append('g')
    .attr('class', 'x axis mid')
    .attr('transform', `translate(0, ${heightChartReturns})`)
    .call(xAxisMidCall);

  // // Y AXES
  const yAxisLeftReturnsCall = d3
    .axisLeft()
    .scale(yScaleReturns)
    .tickSizeOuter(0);
  const yAxisLeftReturns = g
    .append('g')
    .attr('class', 'y axis left')
    .call(yAxisLeftReturnsCall);
  const yAxisLeftProbsCall = d3.axisLeft().scale(yScaleProbs).tickSizeOuter(0);
  const yAxisLeftProbs = g
    .append('g')
    .attr('class', 'y axis left')
    .call(yAxisLeftProbsCall);

  // // AXES Styling
  // xAxisBottom.selectAll('line').attr('y2', 3);
  // xAxisBottom.selectAll('text').attr('y', 6);
  d3.selectAll(`#${nodeId} .axis path`).attr('stroke', colors.axisLine);
  d3.selectAll(`#${nodeId} .axis line`).attr('stroke', colors.axisLine);
  d3.selectAll(`#${nodeId} .axis text`)
    .attr('fill', colors.axisLabel)
    .attr('font-size', fontStyles.axisLabel.fontSize);
  d3.selectAll(`#${nodeId} .x.axis.mid line`).attr('y1', -3).attr('y2', 4);

  // DATA  ------------------------------------------------------------------
  // // RETURNS
  const stdevBarWidth = 6;
  const stdevBarStrokeWidth = 1;
  const gaussianLineStrokeWidth = 1.1;
  const symbolSizeMean = 50;
  const symbolSizeMedian = 50;
  const symbolSizeExtrema = 40;

  // // // UTILITIY - ZERO LINE
  g.append('line')
    .attr('class', 'returns-zero-line')
    .attr('x1', 0)
    .attr('x2', widthChart)
    .attr('y1', yScaleReturns(0))
    .attr('y2', yScaleReturns(0))
    .attr('stroke', colors.axisLine)
    .attr('stroke-width', 1)
    .attr('stroke-dasharray', '1,1');

  // // // GAUSSIAN AREA + LINES
  for (let i = 0; i < data.tradePeriods.length; i++) {
    const period = data.tradePeriods[i];
    const mean = data.currentAnomalyData.mean[i];
    const stdev = data.currentAnomalyData.stdev[i];
    const min = data.currentAnomalyData.min[i];
    const max = data.currentAnomalyData.max[i];

    const dataGaussianNormalized = generateNormalizedGaussianData(
      min,
      max,
      300,
      mean,
      stdev
    );

    const xReturnBaseline = xScale(period) + xScale.bandwidth() / 2;
    const lineGenerator = d3
      .line()
      .x((d) => xReturnBaseline + (xScale.bandwidth() / 2) * d.y)
      .y((d) => yScaleReturns(d.x));

    g.append('path')
      .datum(dataGaussianNormalized)
      .attr('class', 'returns-gaussian-line')
      .attr('d', lineGenerator)
      .attr('fill', 'none')
      .attr('stroke', colors.blueDarkest)
      .attr('stroke-width', gaussianLineStrokeWidth);

    const area = d3
      .area()
      .x0(xReturnBaseline)
      .x1((d) => xReturnBaseline + (xScale.bandwidth() / 2) * d.y)
      .y0((d) => yScaleReturns(d.x))
      .y1((d) => yScaleReturns(d.x));
    g.append('path')
      .datum(dataGaussianNormalized)
      .attr('class', 'returns-gaussion-area')
      .attr('d', area)
      .attr('fill', colors.blueLightest)
      .attr('opacity', 0.75);

    g.append('line')
      .attr('class', 'returns-min-line')
      .attr('x1', xReturnBaseline)
      .attr(
        'x2',
        xReturnBaseline + (dataGaussianNormalized[0].y * xScale.bandwidth()) / 2
      )
      .attr('y1', yScaleReturns(data.currentAnomalyData.min[i]))
      .attr('y2', yScaleReturns(data.currentAnomalyData.min[i]))
      .attr('stroke', colors.red)
      .attr('stroke-width', 1)
      .attr('stroke-dasharray', '1,1');
    g.append('line')
      .attr('class', 'returns-max-line')
      .attr('x1', xReturnBaseline)
      .attr(
        'x2',
        xReturnBaseline +
          (dataGaussianNormalized[dataGaussianNormalized.length - 1].y *
            xScale.bandwidth()) /
            2
      )
      .attr('y1', yScaleReturns(data.currentAnomalyData.max[i]))
      .attr('y2', yScaleReturns(data.currentAnomalyData.max[i]))
      .attr('stroke', colors.green)
      .attr('stroke-width', 1)
      .attr('stroke-dasharray', '1,1');
  }

  // // // UTILITY - RETURNS BASELINE
  g.selectAll('.returns-baseline')
    .data(data.currentAnomalyData.min)
    .enter()
    .append('line')
    .attr('class', 'returns-baseline')
    .attr('x1', (d, i) => xScale(data.tradePeriods[i]) + xScale.bandwidth() / 2)
    .attr('x2', (d, i) => xScale(data.tradePeriods[i]) + xScale.bandwidth() / 2)
    .attr('y1', (d) => yScaleReturns(d))
    .attr('y2', (d, i) => yScaleReturns(data.currentAnomalyData.max[i]))
    .attr('stroke', colors.axisLine)
    .attr('stroke-dasharray', '1,1');
  // // // STDEV
  g.selectAll('.returns-stdev.body')
    .data(data.currentAnomalyData.stdev)
    .enter()
    .append('line')
    .attr('class', 'returns-stdev body')
    .attr('x1', (d, i) => xScale(data.tradePeriods[i]) + xScale.bandwidth() / 2)
    .attr('x2', (d, i) => xScale(data.tradePeriods[i]) + xScale.bandwidth() / 2)
    .attr('y1', (d, i) => yScaleReturns(data.currentAnomalyData.mean[i] - d))
    .attr('y2', (d, i) => yScaleReturns(data.currentAnomalyData.mean[i] + d))
    .attr('stroke', colors.blueDarkest)
    .attr('stroke-width', stdevBarStrokeWidth);
  g.selectAll('.returns-stdev.upper-bar')
    .data(data.currentAnomalyData.stdev)
    .enter()
    .append('line')
    .attr('class', 'returns-stdev upper-bar')
    .attr(
      'x1',
      (d, i) =>
        xScale(data.tradePeriods[i]) +
        xScale.bandwidth() / 2 -
        stdevBarWidth / 2
    )
    .attr(
      'x2',
      (d, i) =>
        xScale(data.tradePeriods[i]) +
        xScale.bandwidth() / 2 +
        stdevBarWidth / 2
    )
    .attr('y1', (d, i) => yScaleReturns(data.currentAnomalyData.mean[i] + d))
    .attr('y2', (d, i) => yScaleReturns(data.currentAnomalyData.mean[i] + d))
    // .attr('stroke', colors.errorBars)
    .attr('stroke', colors.blueDarkest)
    .attr('stroke-width', stdevBarStrokeWidth);
  g.selectAll('.returns-stdev.lower-bar')
    .data(data.currentAnomalyData.stdev)
    .enter()
    .append('line')
    .attr('class', 'returns-stdev lower-bar')
    .attr(
      'x1',
      (d, i) =>
        xScale(data.tradePeriods[i]) +
        xScale.bandwidth() / 2 -
        stdevBarWidth / 2
    )
    .attr(
      'x2',
      (d, i) =>
        xScale(data.tradePeriods[i]) +
        xScale.bandwidth() / 2 +
        stdevBarWidth / 2
    )
    .attr('y1', (d, i) => yScaleReturns(data.currentAnomalyData.mean[i] - d))
    .attr('y2', (d, i) => yScaleReturns(data.currentAnomalyData.mean[i] - d))
    // .attr('stroke', colors.errorBars)
    .attr('stroke', colors.blueDarkest)
    .attr('stroke-width', stdevBarStrokeWidth);
  // // // MEAN
  g.selectAll('.returns-mean-symbol')
    .data(data.currentAnomalyData.mean)
    .enter()
    .append('path')
    .attr('class', 'returns-mean-symbol')
    .attr(
      'transform',
      (d, i) =>
        `translate(${
          xScale(data.tradePeriods[i]) + xScale.bandwidth() / 2
        },${yScaleReturns(d)}),rotate(45)`
    )
    .attr('d', d3.symbol().type(d3.symbolSquare).size(symbolSizeMean))
    .attr('fill', '#fff')
    // .attr('fill', colors.blueDarkest)
    .attr('stroke', colors.blueDarkest)
    // .attr('stroke', colors.errorBars)
    .attr('stroke-width', stdevBarStrokeWidth);

  try {
    g.selectAll('.returns-mean-text')
      .data(data.currentAnomalyData.mean)
      .enter()
      .append('text')
      .attr('class', 'returns-mean-text')
      .attr(
        'x',
        (d, i) =>
          xScale(data.tradePeriods[i]) +
          xScale.bandwidth() / 2 +
          symbolSizeMean / 10 +
          6
      )
      .attr('y', (d, i) => yScaleReturns(d) + fontStyles.axisLabel.fontSize / 3)
      .attr('font-size', fontStyles.axisLabel.fontSize + 'px')
      .attr('font-weight', 400)
      .attr('fill', colors.blueDarkest)
      .text(
        (d, i) =>
          `${d >= 0 ? '+' : ''}${d.toFixed(
            1
          )} ± ${data.currentAnomalyData.stdev[i].toFixed(1)}`
      );
    // // // MEDIAN
    g.selectAll('.returns-median')
      .data(data.currentAnomalyData.median)
      .enter()
      .append('path')
      .attr('class', 'returns-median')
      .attr(
        'transform',
        (d, i) =>
          `translate(${
            xScale(data.tradePeriods[i]) + xScale.bandwidth() / 2
          },${yScaleReturns(d)}),rotate(45)`
      )
      .attr('d', d3.symbol().type(d3.symbolSquare).size(symbolSizeMedian))
      .attr('fill', colors.blueMain)
      .attr('opacity', 0.85);
  } catch (error) {
    console.log(error);
  }

  g.selectAll('.returns-median-text')
    .data(data.currentAnomalyData.median)
    .enter()
    .append('text')
    .attr('class', 'returns-median-text')
    .attr(
      'x',
      (d, i) =>
        xScale(data.tradePeriods[i]) +
        xScale.bandwidth() / 2 -
        symbolSizeExtrema / 10 -
        10
    )
    .attr('y', (d, i) => yScaleReturns(d) + fontStyles.axisLabel.fontSize / 3)
    .attr('fill', colors.blueMain)
    .attr('font-size', fontStyles.axisLabel.fontSize + 'px')
    .attr('text-anchor', 'end')
    .text(
      (d, i) =>
        `${d >= 0 ? '+' : ''}${d.toFixed(1)} (${
          d - data.currentAnomalyData.mean[i] >= 0 ? '+' : ''
        }${(
          (d - data.currentAnomalyData.mean[i]) /
          data.currentAnomalyData.stdev[i]
        ).toFixed(1)}σ)`
    );

  // // // MIN
  g.selectAll('.returns-min-symbol')
    .data(data.currentAnomalyData.min)
    .enter()
    .append('path')
    .attr('class', 'returns-min-symbol')
    .attr(
      'transform',
      (d, i) =>
        `translate(${
          xScale(data.tradePeriods[i]) +
          xScale.bandwidth() / 2 -
          symbolSizeExtrema / 10 -
          1
        },${yScaleReturns(d)}),rotate(90)`
    )
    .attr('d', d3.symbol().type(d3.symbolTriangle).size(symbolSizeExtrema))
    .attr('fill', colors.red);

  g.selectAll('.returns-min-text')
    .data(data.currentAnomalyData.min)
    .enter()
    .append('text')
    .attr('class', 'returns-min-text')
    .attr(
      'x',
      (d, i) =>
        xScale(data.tradePeriods[i]) +
        xScale.bandwidth() / 2 -
        symbolSizeExtrema / 10 -
        10
    )
    .attr('y', (d, i) => yScaleReturns(d) + fontStyles.axisLabel.fontSize / 3)
    .attr('fill', colors.axisLabel)
    .attr('font-size', fontStyles.axisLabel.fontSize + 'px')
    .attr('text-anchor', 'end')
    .text(
      (d, i) =>
        `${d >= 0 ? '+' : ''}${d.toFixed(1)} (${d >= 0 ? '+' : ''}${(
          (d - data.currentAnomalyData.mean[i]) /
          data.currentAnomalyData.stdev[i]
        ).toFixed(1)}σ)`
    );

  // // // MAX
  g.selectAll('.returns-max-symbol')
    .data(data.currentAnomalyData.max)
    .enter()
    .append('path')
    .attr('class', 'returns-max-symbol')
    .attr(
      'transform',
      (d, i) =>
        `translate(${
          xScale(data.tradePeriods[i]) +
          xScale.bandwidth() / 2 -
          symbolSizeExtrema / 10 -
          1
        },${yScaleReturns(d)}),rotate(90)`
    )
    .attr('d', d3.symbol().type(d3.symbolTriangle).size(symbolSizeExtrema))
    .attr('fill', colors.green);

  g.selectAll('.returns-max-text')
    .data(data.currentAnomalyData.max)
    .enter()
    .append('text')
    .attr('class', 'returns-max-text')
    .attr(
      'x',
      (d, i) =>
        xScale(data.tradePeriods[i]) +
        xScale.bandwidth() / 2 -
        symbolSizeExtrema / 10 -
        10
    )
    .attr('y', (d, i) => yScaleReturns(d) + fontStyles.axisLabel.fontSize / 3)
    .attr('fill', colors.axisLabel)
    .attr('font-size', fontStyles.axisLabel.fontSize + 'px')
    .attr('text-anchor', 'end')
    .text(
      (d, i) =>
        `${d >= 0 ? '+' : ''}${d.toFixed(1)} (${d >= 0 ? '+' : ''}${(
          (d - data.currentAnomalyData.mean[i]) /
          data.currentAnomalyData.stdev[i]
        ).toFixed(1)}σ)`
    );
  // // // LEGEND - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  const legendMarginTop = 25;
  const legendRowGap = 17;
  const legendSymbolMarginRight = 52;
  const legendTextMarginRight = legendSymbolMarginRight - 12;

  // // // // LEGEND - MAX
  g.append('path')
    .attr('class', 'legend-max-symbol')
    .attr(
      'transform',
      `translate(${
        widthChart - legendSymbolMarginRight
      },${legendMarginTop}),rotate(90)`
    )
    .attr('d', d3.symbol().type(d3.symbolTriangle).size(symbolSizeExtrema))
    .attr('fill', colors.green);
  g.append('text')
    .attr('class', 'legend-max-text')
    .attr(
      'transform',
      `translate(${widthChart - legendTextMarginRight},${
        legendMarginTop + fontStyles.axisLabel.fontSize / 3
      })`
    )
    .attr('font-size', fontStyles.axisLabel.fontSize)
    .attr('fill', colors.axisLabel)
    .text('Max');
  // // // // LEGEND - MEAN
  g.append('path')
    .attr('class', 'legend-median-symbol')
    .attr(
      'transform',
      `translate(${widthChart - legendSymbolMarginRight},${
        legendMarginTop + legendRowGap
      }),rotate(45)`
    )
    .attr('d', d3.symbol().type(d3.symbolSquare).size(symbolSizeMean))
    .attr('fill', '#fff')
    .attr('stroke', colors.blueDarkest)
    .attr('stroke-width', stdevBarStrokeWidth);
  g.append('text')
    .attr('class', 'legend-median-text')
    .attr(
      'transform',
      `translate(${widthChart - legendTextMarginRight},${
        legendMarginTop + legendRowGap + fontStyles.axisLabel.fontSize / 3
      })`
    )
    .attr('font-size', fontStyles.axisLabel.fontSize)
    .attr('fill', colors.axisLabel)
    .text('Mean');
  // // // // LEGEND - MEDIAN
  g.append('path')
    .attr('class', 'legend-median-symbol')
    .attr(
      'transform',
      `translate(${widthChart - legendSymbolMarginRight},${
        legendMarginTop + 2 * legendRowGap
      }),rotate(45)`
    )
    .attr('d', d3.symbol().type(d3.symbolSquare).size(symbolSizeMedian))
    .attr('fill', colors.blueMain);
  g.append('text')
    .attr('class', 'legend-median-text')
    .attr(
      'transform',
      `translate(${widthChart - legendTextMarginRight},${
        legendMarginTop + 2 * legendRowGap + fontStyles.axisLabel.fontSize / 3
      })`
    )
    .attr('font-size', fontStyles.axisLabel.fontSize)
    .attr('fill', colors.axisLabel)
    .text('Median');
  // // // // LEGEND - MIN
  g.append('path')
    .attr('class', 'legend-min-symbol')
    .attr(
      'transform',
      `translate(${widthChart - legendSymbolMarginRight},${
        legendMarginTop + 3 * legendRowGap
      }),rotate(90)`
    )
    .attr('d', d3.symbol().type(d3.symbolTriangle).size(symbolSizeExtrema))
    .attr('fill', colors.red);
  g.append('text')
    .attr('class', 'legend-mein-text')
    .attr(
      'transform',
      `translate(${widthChart - legendTextMarginRight},${
        legendMarginTop + 3 * legendRowGap + fontStyles.axisLabel.fontSize / 3
      })`
    )
    .attr('font-size', fontStyles.axisLabel.fontSize)
    .attr('fill', colors.axisLabel)
    .text('Min');

  // // PROBABILITIES
  // // // PROBABILITIES TOOLTIPS - Container to be used upon event
  const tooltip = d3
    .select(`#${nodeId}`)
    .append('div')
    .attr('class', `${nodeId}-tooltip`)
    .style('width', '200px')
    .style('color', '#595959')
    .style('background-color', '#fff')
    .style('border', '1px solid #595959')
    .style('border-radius', '2px')
    .style('opacity', '0')
    .style('font-size', '12px')
    .style('text-align', 'justify')
    .style('padding', '5px 10px')
    .style('position', 'absolute');

  g.selectAll('.prob-baseline')
    .data(data.tradePeriods)
    .enter()
    .append('line')
    .attr('class', 'prob-baseline')
    .attr('x1', (d) => xScale(d) + xScale.bandwidth() / 2)
    .attr('x2', (d) => xScale(d) + xScale.bandwidth() / 2)
    .attr('y1', yScaleProbs('≥ -10') + (yScaleProbs.bandwidth() * 3) / 2)
    .attr('y2', yScaleProbs('≥ +10') - yScaleProbs.bandwidth() / 2)
    .attr('stroke', colors.axisLine)
    .attr('stroke-width', 1)
    .attr('stroke-dasharray', '1,1');

  const probsKeyMapping = {
    '≥ -10': 'probGreaterOrEqualM10',
    '≥ -5': 'probGreaterOrEqualM5',
    '≥ 0': 'probGreaterOrEqual0',
    '≥ +5': 'probGreaterOrEqualP5',
    '≥ +10': 'probGreaterOrEqualP10',
  };
  for (let i = 0; i < data.tradePeriods.length; i++) {
    const period = data.tradePeriods[i];
    g.selectAll(`.prob-bar-${period}`)
      .data(yScaleProbs.domain())
      .enter()
      .append('rect')
      .attr('class', `prob-bar-${period}`)
      .attr('x', xScale(period) + xScale.bandwidth() / 2)
      .attr(
        'width',
        (d) =>
          (data.currentAnomalyData[probsKeyMapping[d]][i] *
            xScale.bandwidth()) /
          2
      )
      .attr('y', (d) => yScaleProbs(d))
      .attr('height', yScaleProbs.bandwidth())
      .attr('fill', colors.blueMain)
      .on('mouseover', function (d) {
        d3.select(this).attr('fill', colors.blueDarkest);
        tooltip.transition().duration(150).style('opacity', 0.9);
        tooltip
          .style('display', null)
          .style('left', d3.event.pageX + 15 + 'px')
          .style('top', d3.event.pageY - 25 + 'px')
          .html(
            `<span>Based on ${
              data.currentAnomalyData.occurrences
            } historic occurrences the probability to close with a return of ${d} % ${period} trading days after the current anomaly event is ${(
              data.currentAnomalyData[probsKeyMapping[d]][i] * 100
            ).toFixed(0)} % </span>`
          );
      })
      .on('mousemove', () => {
        tooltip
          .style('left', d3.event.pageX + 15 + 'px')
          .style('top', d3.event.pageY - 25 + 'px');
      })
      .on('mouseout', function (d) {
        d3.select(this).attr('fill', colors.blueMain);
        tooltip
          .transition()
          .duration(150)
          .style('opacity', 0)
          .transition()
          .delay(10)
          .style('display', 'none');
      });

    g.selectAll(`.prob-text-${period}`)
      .data(yScaleProbs.domain())
      .enter()
      .append('text')
      .attr('class', `prob-text-${period}`)
      .attr(
        'x',
        (d) =>
          xScale(period) +
          xScale.bandwidth() / 2 +
          (data.currentAnomalyData[probsKeyMapping[d]][i] *
            xScale.bandwidth()) /
            2 +
          5
      )
      .attr(
        'y',
        (d) =>
          yScaleProbs(d) +
          yScaleProbs.bandwidth() / 2 +
          fontStyles.axisLabel.fontSize / 3
      )
      .attr('font-size', fontStyles.axisLabel.fontSize + 'px')
      .attr('fill', colors.axisLabel)
      .text(
        (d) =>
          `${(data.currentAnomalyData[probsKeyMapping[d]][i] * 100).toFixed(
            0
          )} %`
      );
  }
}

export default drawPrvAaeChart;
