function generateValuesEvenlyDistributed(lowerLimit, upperLimit, totalCount) {
  let values = [];
  const stepSize = (upperLimit - lowerLimit) / (totalCount - 1);

  for (let i = 0; i < totalCount; i++) {
    values.push(lowerLimit + i * stepSize);
  }

  return values;
}

function gaussian(x, mean, sigma) {
  const gaussianConstant = 1 / Math.sqrt(2 * Math.PI);
  const xN = (x - mean) / sigma;

  return (gaussianConstant * Math.exp(-0.5 * xN * xN)) / sigma;
}

function generateNormalizedGaussianData(
  lowerLimit,
  upperLimit,
  totalCount,
  mean,
  sigma
) {
  const xVals = generateValuesEvenlyDistributed(
    lowerLimit,
    upperLimit,
    totalCount
  );

  const yVals = xVals.map((x) => gaussian(x, mean, sigma));
  const yMax = Math.max(...yVals);

  const data = xVals.map((x, i) => ({
    x,
    y: yVals[i] / yMax,
  }));

  return data;
}

export default generateNormalizedGaussianData;
