import React, { useEffect, useState } from "react";
import { timeParse, timeFormat, selectAll } from "d3";

import drawSeasonalityLineChart from "./drawSeasonalityLineChart";
import drawSeasonalityBarChart from "./drawSeasonalityBarChart";

import "./SeasonalityViewerResultItem.css";
import { Radio } from "antd";

const SeasonalityViewerResultItem = ({ data }) => {
  const [dimensions, setDimensions] = useState({
    heightWindow: undefined,
    widthWindow: undefined,
  });

  const parseTime = timeParse("%Y%m%d");
  const formatTimeHeader = timeFormat("%b %d, %Y");

  useEffect(() => {
    function handleResize() {
      setDimensions({
        heightWindow: window.innerHeight,
        widthWindow: window.innerWidth,
      });
      window.addEventListener("resize", handleResize);
    }
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const nodeIdSeasonalityLineChart = "SeasonalityViewerResultItem-lineChart";
  const nodeIdSeasonalityBarChart = "SeasonalityViewerResultItem-barChart";
  const heightSeasonalityLineChart = window.innerHeight < 900 ? 350 : 400;
  const heightSeasonalityBarChart = window.innerHeight < 900 ? 240 : 275;

  useEffect(() => {
    drawSeasonalityLineChart(nodeIdSeasonalityLineChart, data);
    drawSeasonalityBarChart(nodeIdSeasonalityBarChart, data);

    return () => {
      selectAll(`#${nodeIdSeasonalityLineChart} svg`).remove();
      selectAll(`#${nodeIdSeasonalityBarChart} svg`).remove();
    };
  }, [data, dimensions, nodeIdSeasonalityLineChart, nodeIdSeasonalityBarChart]);

  const seasonalityData = data.data.weekly;
  const seasonalityCurrentWeek = Object.keys(
    seasonalityData.currentPerformanceProgression
  ).sort((a, b) => b - a)[0];
  const seasonalityCurrentYtdPerformance =
    seasonalityData.currentPerformanceProgression[seasonalityCurrentWeek];
  const seasonalityStartYear = Object.keys(
    seasonalityData.pastSeasonalityDetailedReturns
  ).sort((a, b) => a - b)[0];
  const seasonalityEndYear = Object.keys(
    seasonalityData.pastSeasonalityDetailedReturns
  ).sort((a, b) => b - a)[0];

  const seasonalityBestSectionsLength =
    seasonalityData.bestSeasonalitySections_mean.sectionLength;

  return (
    <div className="SeasonalityViewerResultItem">
      <div className="SeasonalityViewerResultItem-header">
        <div className="SeasonalityViewerResultItem-header-title">
          {`${data.symbol} - ${data.name.toUpperCase()} ($${
            data.lastPriceAction.adjClose
          })`}
          {/* {data.category !== "stocks" && (
            <span
              className="SeasonalityViewerResultItem-header-title-info"
              // onClick={() => setIsInfoModalVisible(!isInfoModalVisible)}
              onClick={() => alert("Developer message: Not implemented yet.")}
            >
              INFO
            </span>
          )} */}
        </div>
        <div className="SeasonalityViewerResultItem-header-date">
          {formatTimeHeader(parseTime(data.lastPriceAction.date))}
        </div>
      </div>
      <div className="SeasonalityViewerResultItem-container">
        <div className="SeasonalityViewerResultItem-subcontainer-1">
          <div
            className="SeasonalityViewerResultItem-subcontainer-title"
            id="SeasonalityViewerResultItem-subcontainer-1-title"
          >
            Seasonality Performance Profiles by Weekly Returns
          </div>
          <div className="SeasonalityViewerResultItem-subcontainer-1-result-container">
            <div
              className="SeasonalityViewerResultItem-subcontainer-1-result-container-data"
              style={{ height: `${heightSeasonalityLineChart - 50}px` }}
            >
              <div className="SeasonalityViewerResultItem-details-item">
                <div className="SeasonalityViewerResultItem-details-label">
                  Start Year
                </div>
                <div className="SeasonalityViewerResultItem-details-value">
                  {seasonalityStartYear}
                </div>
              </div>
              <div className="SeasonalityViewerResultItem-details-item">
                <div className="SeasonalityViewerResultItem-details-label">
                  End Year
                </div>
                <div className="SeasonalityViewerResultItem-details-value">
                  {seasonalityEndYear}
                </div>
              </div>
              <div className="SeasonalityViewerResultItem-details-item">
                <div className="SeasonalityViewerResultItem-details-label">
                  Total Years
                </div>
                <div className="SeasonalityViewerResultItem-details-value">
                  {seasonalityEndYear - seasonalityStartYear + 1}
                </div>
              </div>
              <div className="SeasonalityViewerResultItem-details-item">
                <div className="SeasonalityViewerResultItem-details-label">
                  Current Week
                </div>
                <div className="SeasonalityViewerResultItem-details-value">
                  {seasonalityCurrentWeek}
                </div>
              </div>
              <div className="SeasonalityViewerResultItem-details-item">
                <div className="SeasonalityViewerResultItem-details-label">
                  Weekly Return
                </div>
                <div className="SeasonalityViewerResultItem-details-value">
                  {`${seasonalityData.currentSeasonality[
                    seasonalityCurrentWeek
                  ].toFixed(2)} %`}
                </div>
              </div>
              <div className="SeasonalityViewerResultItem-details-item">
                <div className="SeasonalityViewerResultItem-details-label">
                  YTD Portfolio Value
                </div>
                <div className="SeasonalityViewerResultItem-details-value">
                  {`${seasonalityCurrentYtdPerformance.toFixed(2)} %`}
                </div>
              </div>
              <div className="SeasonalityViewerResultItem-details-item">
                <div className="SeasonalityViewerResultItem-details-label">
                  YTD Return
                </div>
                <div className="SeasonalityViewerResultItem-details-value">
                  {`${(seasonalityCurrentYtdPerformance - 100).toFixed(2)} %`}
                </div>
              </div>
              <div className="SeasonalityViewerResultItem-details-item SeasonalityViewerResultItem-details-item-best-sections">
                <div className="SeasonalityViewerResultItem-details-label">
                  {`Best ${seasonalityBestSectionsLength}-Week Seasonality by Mean`}
                </div>
                <div className="SeasonalityViewerResultItem-details-value">
                  {`${Object.values(
                    seasonalityData.bestSeasonalitySections_mean
                      .bestSectionStartWeeks
                  )
                    .map(
                      (d) =>
                        `${d.week}-${
                          d.week + seasonalityBestSectionsLength <= 52
                            ? d.week + seasonalityBestSectionsLength - 1
                            : d.week + seasonalityBestSectionsLength - 52 - 1
                        }`
                    )
                    .join(" ... ")}`}
                </div>
              </div>
              <div className="SeasonalityViewerResultItem-details-item SeasonalityViewerResultItem-details-item-best-sections">
                <div className="SeasonalityViewerResultItem-details-label">
                  {`Best ${seasonalityBestSectionsLength}-Week Seasonality by Median`}
                </div>
                <div className="SeasonalityViewerResultItem-details-value">
                  {`${Object.values(
                    seasonalityData.bestSeasonalitySections_median
                      .bestSectionStartWeeks
                  )
                    .map(
                      (d) =>
                        `${d.week}-${
                          d.week + seasonalityBestSectionsLength <= 52
                            ? d.week + seasonalityBestSectionsLength - 1
                            : d.week + seasonalityBestSectionsLength - 52 - 1
                        }`
                    )
                    .join(" ... ")}`}
                </div>
              </div>
            </div>
            <div className="SeasonalityViewerResultItem-subcontainer-1-result-container-chart">
              <Radio.Group
                className="SeasonalityViewerResultItem-button-group-allLines"
                size="small"
                defaultValue={"no"}
                optionType="button"
                options={[
                  { label: "Mean and Median only", value: "no" },
                  { label: "All Historic Profiles", value: "yes" },
                ]}
              />
              <div
                className="SeasonalityViewerResultItem-container-lineChart"
                id={nodeIdSeasonalityLineChart}
                style={{ height: `${heightSeasonalityLineChart}px` }}
              />
            </div>
          </div>
          <div className="SeasonalityViewerResultItem-subcontainer-2-result-container">
            <div
              className="SeasonalityViewerResultItem-subcontainer-2-result-container-data"
              style={{ height: `${heightSeasonalityBarChart - 50}px` }}
            >
              <div className="SeasonalityViewerResultItem-details-item">
                <div className="SeasonalityViewerResultItem-details-label">
                  Current Week
                </div>
                <div className="SeasonalityViewerResultItem-details-value">
                  {seasonalityCurrentWeek}
                </div>
              </div>
              <div className="SeasonalityViewerResultItem-details-item">
                <div className="SeasonalityViewerResultItem-details-label">
                  Current Return
                </div>
                <div className="SeasonalityViewerResultItem-details-value">
                  {`${seasonalityData.currentSeasonality[
                    seasonalityCurrentWeek
                  ].toFixed(2)} %`}
                </div>
              </div>
              <div className="SeasonalityViewerResultItem-details-item">
                <div className="SeasonalityViewerResultItem-details-label">
                  Mean Return
                </div>
                <div className="SeasonalityViewerResultItem-details-value">
                  {`${seasonalityData.pastSeasonality.mean[
                    seasonalityCurrentWeek
                  ].toFixed(2)} %`}
                </div>
              </div>
              <div className="SeasonalityViewerResultItem-details-item">
                <div className="SeasonalityViewerResultItem-details-label">
                  StDev Return
                </div>
                <div className="SeasonalityViewerResultItem-details-value">
                  {`${seasonalityData.pastSeasonality.std[
                    seasonalityCurrentWeek
                  ].toFixed(2)} %`}
                </div>
              </div>
              <div className="SeasonalityViewerResultItem-details-item">
                <div className="SeasonalityViewerResultItem-details-label">
                  Median Return
                </div>
                <div className="SeasonalityViewerResultItem-details-value">
                  {`${seasonalityData.pastSeasonality.median[
                    seasonalityCurrentWeek
                  ].toFixed(2)} %`}
                </div>
              </div>
              <div className="SeasonalityViewerResultItem-details-item">
                <div className="SeasonalityViewerResultItem-details-label">
                  Min Return
                </div>
                <div className="SeasonalityViewerResultItem-details-value">
                  {`${seasonalityData.pastSeasonality.min[
                    seasonalityCurrentWeek
                  ].toFixed(2)} %`}
                </div>
              </div>
              <div className="SeasonalityViewerResultItem-details-item">
                <div className="SeasonalityViewerResultItem-details-label">
                  Max Return
                </div>
                <div className="SeasonalityViewerResultItem-details-value">
                  {`${seasonalityData.pastSeasonality.max[
                    seasonalityCurrentWeek
                  ].toFixed(2)} %`}
                </div>
              </div>
            </div>
            <div className="SeasonalityViewerResultItem-subcontainer-2-result-container-chart">
              <div
                className="SeasonalityViewerResultItem-container-barChart"
                id={nodeIdSeasonalityBarChart}
                style={{ height: `${heightSeasonalityBarChart}px` }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SeasonalityViewerResultItem;
