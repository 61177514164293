import React, { Fragment, useContext } from "react";
import { Button, Select } from "antd";
import PropTypes from "prop-types";

// Context
import { AnomaliesContext } from "../../../contexts/AnomaliesContext";

// Redux
import { connect } from "react-redux";
import { getPeriodicReturnFilterData } from "../../../redux/actions/dataActions";

// Features
import SYMBOL_DIRECTORY from "../../tools-utils/SYMBOL_DIRECTORY.json";
import {
  countStocksBySector,
  getReduxStates,
} from "../../tools-utils/tools-util-functions";
import CategorySelect from "../../tools-utils/CategorySelect";
import PeriodSelect from "../../tools-utils/PeriodSelect";
import SpinnerWithText from "../../../main/utils/SpinnerWithText/SpinnerWithText";
import PeriodicReturnFilterResult from "./PeriodicReturnFilterResult";

// Styling
import "./PeriodicReturnFilterContent.css";

// import dataTemp from './backend_prf_data.json';
// import { timeParse } from 'd3';

// const parseTime = timeParse('%Y%m%d');
// dataTemp.data.forEach(
//   (obj) => (obj.dateList = obj.dateList.map((s) => parseTime(s)))
// );

const { Option } = Select;

const PeriodicReturnFilterContent = ({
  loading,
  data,
  error,
  getPeriodicReturnFilterData,
}) => {
  // loading context
  const { prfOptions, changeOption } = useContext(AnomaliesContext);

  const { category, period, threshold } = prfOptions;

  // defining context related functions
  const changePeriod = (val) => {
    changeOption("prfOptions", "period", val);
  };
  const changeCategory = (val) => {
    changeOption("prfOptions", "category", val);
  };

  const handleClickAnalyze = () => {
    getPeriodicReturnFilterData(category, period, threshold);
  };

  // picking correct dataLoading, dataObj, and error
  // picking correct dataLoading, dataObj, and dataError
  let [dataLoading, dataObj, dataError] = getReduxStates(
    loading,
    data,
    error,
    "anomaliesPrf"
  );

  // Dropdown for Threshold
  const optionsThreshold = [1, 2, 3];
  const dropdownThreshold = (
    <Select
      className="PeriodicReturnFilterContent-dropdown-threshold"
      placeholder="Threshold"
      value={threshold !== null ? threshold : undefined}
      onChange={(val) => changeOption("prfOptions", "threshold", val)}
    >
      {optionsThreshold.map((d, i) => (
        <Option key={i} value={d} style={{ fontWeight: 300, fontSize: "15px" }}>
          {`${d} σ`}
        </Option>
      ))}
    </Select>
  );

  const totalForex = SYMBOL_DIRECTORY.forex.length;
  const totalEtfs = SYMBOL_DIRECTORY.etfs.length;
  const totalStocks = SYMBOL_DIRECTORY.stocks.length;

  let categoryText = "";
  let totalProducts = "";
  switch (category) {
    case "forex":
      categoryText = "Pairs";
      totalProducts = totalForex;
      break;
    case "etfs":
      categoryText = "ETFs";
      totalProducts = totalEtfs;
      break;
    case "stocks":
      categoryText = "Stocks";
      totalProducts = totalStocks;
      break;
    case "etfs+stocks":
      categoryText = "ETFs and Stocks";
      totalProducts = totalEtfs + totalStocks;
      break;
    default:
      categoryText = "Stocks";
      totalProducts = countStocksBySector(category);
  }

  let resultHeaderStr;
  // if (dataTemp !== null) {
  if (dataObj !== null) {
    let categoryStr;
    // switch (dataTemp.category) {
    switch (dataObj.category) {
      case "forex":
        categoryStr = `${totalForex} FX Pairs`;
        break;
      case "etfs":
        categoryStr = `${totalEtfs} ETFs`;
        break;
      case "stocks":
        categoryStr = `${totalStocks} Stocks`;
        break;
      case "etfs+stocks":
        categoryStr = `${totalEtfs + totalStocks} ETFs and Stocks`;
        break;
      default:
        // categoryStr = `${countStocksBySector(
        //   dataTemp.category
        // )} Stocks of GICS Sector ${dataTemp.category}`;
        categoryStr = `${countStocksBySector(
          dataObj.category
        )} Stocks of GICS Sector ${dataObj.category}`;
    }
    // resultHeaderStr = `Anomalies by ${dataTemp.periodDays}-Day Moving Return (MR) Analysis on ${categoryStr} exceeding return-Δ-threshold of ±${dataTemp.threshold} σ`;
    resultHeaderStr = `${dataObj.data.length} Anomalies by a ${dataObj.periodDays}-Day Moving Return (MR) Analysis on ${categoryStr} - exceeding return-Δ-threshold of ±${dataObj.threshold} σ`;
  }

  let validSearch = false;
  if ([category, period, threshold].every((d) => d !== null)) {
    validSearch = true;
  }

  return (
    <div className="PeriodicReturnFilterContent">
      <div className="PeriodicReturnFilterContent-container-dropdown">
        <CategorySelect category={category} setCategory={changeCategory} />
        <PeriodSelect
          startDayNum={5}
          endDayNum={253}
          period={period}
          setPeriod={changePeriod}
        />
        {dropdownThreshold}
        <Button
          disabled={!dataLoading && validSearch ? false : true}
          className="PeriodicReturnFilterContent-button submit"
          type="primary"
          onClick={handleClickAnalyze}
        >
          {category === null
            ? "Analyze"
            : `Analyze all ${totalProducts} ${categoryText}`}
        </Button>
        <span className="PeriodicReturnFilterContent-note">
          Note: Operation requires up to 45s.
        </span>
      </div>
      {dataLoading ? (
        <div className="PeriodicReturnFilterContent-Spinner">
          <SpinnerWithText />
        </div>
      ) : (
        <div className="PeriodicReturnFilterContent-container-results">
          {dataObj !== null && dataError == null ? (
            <Fragment>
              <div className="PeriodicReturnFilterContent-results-header">
                {resultHeaderStr}
              </div>
              <PeriodicReturnFilterResult
                data={dataObj.data}
                period={dataObj.periodDays}
              />
            </Fragment>
          ) : (
            (dataError || dataError === undefined) && (
              <div>{`Error: ${dataError}`}</div>
            )
          )}
        </div>
      )}
    </div>
  );
};

PeriodicReturnFilterContent.propTypes = {
  loading: PropTypes.object,
  data: PropTypes.object,
  error: PropTypes.object,
  getPeriodicReturnFilterData: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  loading: state.data.loading,
  data: state.data,
  error: state.data.error,
});

export default connect(mapStateToProps, {
  getPeriodicReturnFilterData,
})(PeriodicReturnFilterContent);
