import React from "react";
import { Redirect } from "react-router-dom";
import { Form, Input, Button } from "antd";

import "./BouncerPage.css";
import BackgroundImg from "../../../style/images/milkovi-kYlYwQze5vI-unsplash_compressed.jpg";

const BouncerPage = (props) => {
  const { accessCode, accessGranted, grantAccess } = props;

  const handleSubmit = (values) => {
    if (values.accessCode === accessCode) {
      grantAccess();
    }
  };

  if (accessGranted) {
    return <Redirect to="/" />;
  }

  return (
    <div
      className="BouncerPage"
      style={{
        backgroundImage: `url(${BackgroundImg}`,
      }}
    >
      <div className="BouncerPage-container-form">
        <Form onFinish={handleSubmit} className="BouncerPage-form">
          <Form.Item
            name="accessCode"
            rules={[
              {
                required: true,
                message: "Enter Access Code!",
              },
            ]}
          >
            <Input placeholder="ACCESS CODE" name="accessCode" />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              ENTER
            </Button>
          </Form.Item>
        </Form>
      </div>
      <div className="BouncerPage-note">
        Restricted access for participants of seminars by The Tactical Brief
      </div>
    </div>
  );
};

export default BouncerPage;
