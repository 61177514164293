import React, { useContext, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import { Menu, Layout, Modal } from "antd";
import PropTypes from "prop-types";

// Context
import { SeasonalityContext } from "../../contexts/SeasonalityContext";

// Redux
import { connect } from "react-redux";

// own components
import LogoText_white from "./../../style/logos/LogoText_white.svg";
import imgIconThorsten from ".//../../style/images/img_icon_thorsten_01.png";
import WistiaEmbed from "../../components/WistiaEmbed";

// styling
import "./SeasonalityHeadMenu.css";

const { Header } = Layout;

const SeasonalityHeadMenu = ({ loadingViewer }) => {
  // context
  const { toolSelected, changeTool } = useContext(SeasonalityContext);

  // state
  const [isModalVisible, setIsModalVisible] = useState(false);

  const modalWidth = window.innerWidth * 0.5;
  const modalPadding = 24;
  const videoSize = {
    width: modalWidth - 2 * modalPadding,
    heigth: ((modalWidth - 2 * modalPadding) / 16) * 9,
  };

  const pInfoModalSafetyStop = (
    <div>
      <h3 className="SeasonalityHeadMenu-modal-header">Safety Stop</h3>
      <div
        className="SeasonalityHeadMenu-modal-text"
        style={{ width: videoSize.width, height: videoSize.heigth }}
      >
        <WistiaEmbed hashedId={"l2j4pk7v14"} />
      </div>
    </div>
  );

  const infoModal = (
    <Modal
      title="Description and Usage"
      visible={isModalVisible}
      width={modalWidth}
      centered={true}
      footer={null}
      onOk={() => {
        setIsModalVisible(false);
      }}
      onCancel={() => {
        setIsModalVisible(false);
      }}
    >
      {pInfoModalSafetyStop}
    </Modal>
  );

  return (
    <Fragment>
      <Header className="SeasonalityHeadMenu-header">
        <div
          key="headerLogo"
          className="headerLogo"
          style={{
            float: "left",
            marginTop: "-2px",
            marginLeft: "-45px",
            marginRight: "2px",
          }}
        >
          <Link to="/">
            <img src={LogoText_white} height="36px" alt="..." />
          </Link>
        </div>
        <div
          className="SeasonalityHeadMenu-container-info"
          onClick={() => setIsModalVisible(!isModalVisible)}
        >
          <span className="SeasonalityHeadMenu-container-info-label">
            Get briefed by
          </span>
          <img
            className="SeasonalityHeadMenu-container-info-icon-thorsten"
            src={imgIconThorsten}
            width={25}
            alt="iconTh"
          />
        </div>
        <Menu
          className="SeasonalityHeadMenu-header-menu"
          key="Overview-Head-Menu"
          theme="dark"
          mode="horizontal"
          selectedKeys={[toolSelected]}
          style={{ paddingLeft: "100px", marginRight: "120px" }}
          onClick={(e) => changeTool(e.key)}
        >
          <Menu.Item key="sv">
            <span
              className={`SeasonalityHeadMenu-viewer ${
                loadingViewer ? "loading" : ""
              }`}
              data-text="Seasonality Viewer"
            >
              Seasonality Viewer
            </span>
          </Menu.Item>
        </Menu>
      </Header>
      {infoModal}
    </Fragment>
  );
};

SeasonalityHeadMenu.propTypes = {
  loadingViewer: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  loadingViewer: state.seasonalityViewer.loading,
});

export default connect(mapStateToProps, {})(SeasonalityHeadMenu);
