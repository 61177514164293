import React, { useState, useEffect } from "react";
import { selectAll } from "d3";
import { Radio, InputNumber } from "antd";

import drawPrvAaeChart from "./drawPrvAaeChart";
import drawPrvReturnsVixOverTimeChart from "./drawPrvReturnsVixOverTimeChart";

import "./PrvActionableAnomalyEvent.css";

const PrvActionableAnomalyEvent = ({ data }) => {
  console.log(data);
  const [dimensions, setDimensions] = useState({
    heightWindow: undefined,
    widthWindow: undefined,
  });
  const [winThresholdPct, setWinThresholdPct] = useState(0.0);

  useEffect(() => {
    function handleResize() {
      setDimensions({
        heightWindow: window.innerHeight,
        widthWindow: window.innerWidth,
      });
      window.addEventListener("resize", handleResize);
    }
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const nodeIdAaeChart1 = "PrvAae-chart-1";
  const heightAaeChart1 = 450;
  const nodeIdAaeChart2 = "PrvAae-chart-2";
  const heightAaeChart2 = 550;

  const anomalyData = data.currentAnomalyData;

  useEffect(() => {
    if (dimensions.heightWindow !== undefined && anomalyData !== undefined) {
      try {
        drawPrvAaeChart(nodeIdAaeChart1, data);
      } catch (error) {
        console.log(error);
      }
      try {
        drawPrvReturnsVixOverTimeChart(nodeIdAaeChart2, data);
      } catch (error) {
        console.log(error);
      }
    }

    return () => {
      selectAll(`#${nodeIdAaeChart1} svg`).remove();
      selectAll(`#${nodeIdAaeChart2} svg`).remove();
    };
  }, [dimensions, data]);

  const anomalyCategory = data.currentAnomalyCategory;

  let actionableEventText;
  let actionableEventDetail;
  if (anomalyData === undefined) {
    actionableEventText = "No";
  } else {
    actionableEventDetail = `${
      anomalyCategory.length === 2 ? "" : "just breached "
    }return anomaly of ${
      anomalyCategory[0] === "p" ? "greater then +" : "less than -"
    }${anomalyCategory[1]} σ (${data.currentReturnDeviationInStdev} σ)`;
    actionableEventText = "Yes, " + actionableEventDetail;
  }

  let sigmaRange;
  switch (anomalyCategory[0] + anomalyCategory[1]) {
    case "p3":
      sigmaRange = "Δr > 3 σ";
      break;
    case "p2":
      sigmaRange = "2 σ < Δr ≤ 3 σ";
      break;
    case "p1":
      sigmaRange = "1 σ < Δr ≤ 2 σ";
      break;
    case "m1":
      sigmaRange = "-2 σ ≤ Δr < -1 σ";
      break;
    case "m2":
      sigmaRange = "-3 σ ≤ Δr < -2 σ";
      break;
    case "m3":
      sigmaRange = "Δr < -3 σ";
      break;
    default:
      break;
  }

  const minCountAnomalyTradeReturns =
    anomalyData === undefined
      ? undefined
      : Math.min(...data.currentAnomalyData.returns.map((arr) => arr.length));
  const winRateMaTradeCounts = [0, 5, 10, 20, 50];

  const digitCount =
    data.category === "forex" && !data.symbol.includes("JPY") ? 5 : 2;
  const winThresholdPrice = (
    (1 + winThresholdPct / 100) *
    data.adjCloseLast
  ).toFixed(digitCount);

  return (
    <div className="PrvActionableAnomalyEvent">
      <div className="PrvActionableAnomalyEvent-heading">
        Actionable Anomaly Event:{" "}
        <span
          className={`PrvActionableAnomalyEvent-actionable-${
            anomalyData === undefined ? "no" : "yes"
          }`}
        >
          {actionableEventText}
        </span>
      </div>
      {anomalyData !== undefined && (
        <div className="PrvActionableAnomalyEvent-content">
          <div className="PrvActionableAnomalyEvent-content-1">
            <div className="PrvActionableAnomalyEvent-content-1-text">
              <div
                className="PrvActionableAnomalyEvent-text-subcontainer"
                id="category"
              >
                <div
                  className="PrvActionableAnomalyEvent-text-label"
                  id="category"
                >
                  Category
                </div>
                <div
                  className="PrvActionableAnomalyEvent-text-value"
                  id="category"
                >
                  {`${
                    anomalyCategory[0] === "p" ? "+" : "-"
                  }${anomalyCategory.slice(1)}`}
                </div>
              </div>
              <div
                className="PrvActionableAnomalyEvent-text-subcontainer"
                id="range"
              >
                <div
                  className="PrvActionableAnomalyEvent-text-label"
                  id="range"
                >
                  Sigma Range
                </div>
                <div
                  className="PrvActionableAnomalyEvent-text-value"
                  id="range"
                >
                  {sigmaRange}
                </div>
              </div>
              <div
                className="PrvActionableAnomalyEvent-text-subcontainer"
                id="breached"
              >
                <div
                  className="PrvActionableAnomalyEvent-text-label"
                  id="breached"
                >
                  Just breached
                </div>
                <div
                  className="PrvActionableAnomalyEvent-text-value"
                  id="breached"
                >
                  {anomalyCategory.length === 2 ? "No" : "Yes"}
                </div>
              </div>
              <div
                className="PrvActionableAnomalyEvent-text-subcontainer"
                id="occurrences"
              >
                <div
                  className="PrvActionableAnomalyEvent-text-label"
                  id="occurrences"
                >
                  Occurrences
                </div>
                <div
                  className="PrvActionableAnomalyEvent-text-value"
                  id="occurrences"
                >
                  {data.currentAnomalyData.occurrences}
                </div>
              </div>
              <div
                className="PrvActionableAnomalyEvent-text-subcontainer"
                id="daysMedian"
              >
                <div
                  className="PrvActionableAnomalyEvent-text-label"
                  id="daysMedian"
                >
                  Median Days for Occurrences
                </div>
                <div
                  className="PrvActionableAnomalyEvent-text-value"
                  id="daysMedian"
                >
                  {data.currentAnomalyData.daysMedian}
                </div>
              </div>
              <div
                className="PrvActionableAnomalyEvent-text-subcontainer"
                id="daysMeanStdev"
              >
                <div
                  className="PrvActionableAnomalyEvent-text-label"
                  id="daysMeanStdev"
                >
                  Mean Days for Occurences
                </div>
                <div
                  className="PrvActionableAnomalyEvent-text-value"
                  id="daysMeanStdev"
                >
                  {`${data.currentAnomalyData.daysMean.toFixed(
                    1
                  )}±${data.currentAnomalyData.daysStdev.toFixed(1)}`}
                </div>
              </div>
            </div>
            <div
              className="PrvActionableAnomalyEvent-content-1-chart"
              id={nodeIdAaeChart1}
              style={{ height: `${heightAaeChart1}px` }}
            ></div>
          </div>
          <div className="PrvActionableAnomalyEvent-content-2">
            <div className="PrvActionableAnomalyEvent-content-2-titel">
              Anomaly Trade Returns and VIX over Time
            </div>
            <div className="PrvActionableAnomalyEvent-content-2-container">
              <div className="PrvActionableAnomalyEvent-content-2-description">
                <div className="PrvActionableAnomalyEvent-content-2-description-body">
                  This is the text container for a brief explanation of the
                  visualization on the right and the respective parameters
                  below. This is the text container for a brief explanation of
                  the visualization on the right and the respective parameters
                  below.
                </div>
                <div className="PrvActionableAnomalyEvent-content-2-description-parameters">
                  <div className="PrvActionableAnomalyEvent-parameters-trade-periods">
                    <div className="PrvActionableAnomalyEvent-parameters-trade-periods-label">
                      Trade Duration in Trading Days
                    </div>
                    <Radio.Group
                      className="PrvActionableAnomalyEvent-parameters-trade-periods-buttons"
                      optionType="button"
                      defaultValue={data.tradePeriods[0]}
                      size="small"
                    >
                      {data.tradePeriods.map((d, i) => (
                        <Radio.Button key={`trade-period-${i}`} value={d}>
                          {d}
                        </Radio.Button>
                      ))}
                    </Radio.Group>
                  </div>
                  <div className="PrvActionableAnomalyEvent-parameters-win-threshold">
                    <div className="PrvActionableAnomalyEvent-parameters-win-threshold-label pct">
                      Win-Return-Threshold [%]
                    </div>
                    <div className="PrvActionableAnomalyEvent-parameters-win-threshold-label price">
                      Corresponding Price [$]
                    </div>
                    <InputNumber
                      className="PrvActionableAnomalyEvent-parameters-win-threshold-input"
                      min={
                        Math.min(...data.currentAnomalyData.min).toFixed(1) - 1
                      }
                      max={
                        Math.max(...data.currentAnomalyData.max).toFixed(1) + 1
                      }
                      precision={2}
                      step={0.1}
                      defaultValue={0.0}
                      value={winThresholdPct}
                      size="small"
                      onChange={(val) => setWinThresholdPct(val)}
                    />
                    <div className="PrvActionableAnomalyEvent-parameters-win-threshold-price">
                      {winThresholdPrice}
                    </div>
                  </div>
                  <div className="PrvActionableAnomalyEvent-parameters-win-rate-ma">
                    <div className="PrvActionableAnomalyEvent-parameters-win-rate-ma-label">
                      Trade Count for Moving Average on Returns and Win Rate
                    </div>
                    <Radio.Group
                      className="PrvActionableAnomalyEvent-parameters-win-rate-ma-buttons"
                      optionType="button"
                      disabled={
                        minCountAnomalyTradeReturns <
                        winRateMaTradeCounts[0] * 2
                          ? true
                          : false
                      }
                      defaultValue={0}
                      size="small"
                    >
                      {winRateMaTradeCounts.map((d, i) => (
                        <Radio.Button
                          key={`win-rate-ma-${i}`}
                          value={d}
                          disabled={d * 2 > minCountAnomalyTradeReturns}
                        >
                          {d}
                        </Radio.Button>
                      ))}
                    </Radio.Group>
                  </div>
                </div>
              </div>
              <div
                className="PrvActionableAnomalyEvent-content-2-chart"
                id={nodeIdAaeChart2}
                style={{ height: `${heightAaeChart2}px` }}
              ></div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PrvActionableAnomalyEvent;
