import React, { createContext, Component } from "react";

export const SeasonalityContext = createContext();

export class SeasonalityContextProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toolSelected: "sv", // seasonality viewer
      svOptions: {
        symbol: null,
      },
    };
  }

  changeTool = (newTool) => {
    this.setState((currState) => ({ ...currState, toolSelected: newTool }));
  };

  changeOption = (type, subType, value) => {
    this.setState((currState) => ({
      ...currState,
      [type]: { ...currState[type], [subType]: value },
    }));
  };

  render() {
    return (
      <SeasonalityContext.Provider
        value={{
          ...this.state,
          changeTool: this.changeTool,
          changeOption: this.changeOption,
        }}
      >
        {this.props.children}
      </SeasonalityContext.Provider>
    );
  }
}
