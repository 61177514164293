import * as d3 from "d3";
import { selectAll } from "d3";

function drawSeasonalityBarChart(nodeId, data) {
  // STYLES  -----------------------------------------------------
  const colors = {
    axisTitle: "#5D5D5D",
    axisLabel: "#878787",
    axisLine: "#878787",
    errorBars: "#434343",
    blueMain: "#1890ff",
    blueDarker: "#126cbf",
    blueDarkest: "#002766",
    blueLighter: "#6FBAFF",
    blueLightest: "#C5E3FF",
    red: "#ff181d",
    redLight: "#ffa8aa",
    redLabel: "#bf1216",
    green: "#4bc219",
    greenLight: "#bce8a9",
    underlyingLabel: changeHexColorOpacity("#333", 0.3),
  };
  const fontStyles = {
    axisTitle: {
      fontSize: 12,
      fontWeight: 300,
    },
    axisLabel: {
      fontSize: 10,
      fontWeight: 300,
    },
  };
  const lineStyles = {
    strokeWidth: {
      primary: {
        normal: 1.5,
        thick: 2.25,
        thicker: 3,
      },
      secondary: {
        normal: 1,
        thick: 1.75,
        thicker: 2.5,
      },
    },
  };

  // DATA PREPARATION --------------------------------------------------
  const seasonalityData = data.data.weekly;
  console.log(seasonalityData);
  // CURRENT WEEK DATA
  const [currentWeek, currentWeekReturn] = Object.entries(
    seasonalityData.currentSeasonality
  ).findLast(() => true);

  // FINAL PERFORMANCE PROGRESS MEAN AND MEDIAN
  const annualPerformanceMean =
    seasonalityData.pastPerformanceProgression.mean[52];
  const annualPerformanceMedian =
    seasonalityData.pastPerformanceProgression.median[52];

  // BEST SECTIONS DATA
  const bestSections = {
    mean: seasonalityData.bestSeasonalitySections_mean,
    median: seasonalityData.bestSeasonalitySections_median,
  };

  const bestSectionsAreaData = {};
  for (let k of Object.keys(bestSections)) {
    bestSectionsAreaData[k] = [];
    const sectionLength = bestSections[k].sectionLength;

    for (let [k2, obj] of Object.entries(
      bestSections[k].bestSectionStartWeeks
    )) {
      // accounting for best sections starting from week 50
      // and, thus, crossing over into first weeks of year
      if (obj.week <= 49) {
        // normal case
        const startWeek = obj.week - 1;
        const endWeek = obj.week + sectionLength - 1;
        const sectionAreaData = [];
        for (let i = startWeek; i <= endWeek; i++) {
          sectionAreaData.push({
            week: i,
            value: seasonalityData.pastPerformanceProgression[k][i],
          });
        }

        bestSectionsAreaData[k].push(sectionAreaData);
      } else {
        // case for crossover sections
        const startWeeks = [obj.week - 1, 0];
        const endWeeks = [52, obj.week + sectionLength - 1 - 52];

        for (let partIdx = 0; partIdx < startWeeks.length; partIdx++) {
          const sectionAreaData = [];

          for (let i = startWeeks[partIdx]; i <= endWeeks[partIdx]; i++) {
            sectionAreaData.push({
              week: i,
              value: seasonalityData.pastPerformanceProgression[k][i],
            });
          }
          bestSectionsAreaData[k].push(sectionAreaData);
        }
      }
    }
  }

  // Month Label Data
  const daysPerMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  let monthLabelData = [];

  for (let i = 0; i < daysPerMonth.length; i++) {
    const currMonthDays = daysPerMonth[i];
    const currDaysSlice = daysPerMonth.slice(0, i + 1);
    const sumDays = currDaysSlice.reduce((prev, curr) => prev + curr, 0);

    monthLabelData.push({
      monthName: months[i],
      monthEndInWeeks: sumDays / 7,
      monthMidInWeeks: (sumDays - currMonthDays) / 7 + currMonthDays / (2 * 7),
    });
  }

  // DIMENSIONS --------------------------------------------------
  // getting div container of chart and set dimensions
  const divChart = d3.select(`#${nodeId}`)._groups[0][0];
  const width = divChart.offsetWidth;
  const height = divChart.offsetHeight;

  // margins
  const margin = { top: 0, right: 50, bottom: 50, left: 70 };

  // creating main svg
  const svg = d3
    .select(`#${nodeId}`)
    .append("svg")
    .attr("width", width)
    .attr("height", height);
  // .style("border", "1px solid red");

  // defining chart height and width
  const widthChart = width - margin.left - margin.right;
  const heightChart = height - margin.top - margin.bottom;

  const g = svg
    .append("g")
    .attr("transform", `translate(${margin.left}, ${margin.top})`);

  // g.append("rect")
  //   .attr("x", 0)
  //   .attr("y", 0)
  //   .attr("width", widthChart)
  //   .attr("height", heightChart)
  //   .attr("fill", "gold");

  // MAIN LABELS ------------------------------------------------------------------
  // X AXIS TITLE
  g.append("text")
    .attr("class", "axis-title x")
    .attr("id", "title-days")
    .attr("x", widthChart / 2)
    .attr("y", heightChart + margin.bottom / 2 + 13)
    .attr("font-size", fontStyles.axisTitle.fontSize + "px")
    .attr("font-weight", fontStyles.axisTitle.fontWeight)
    .attr("fill", colors.axisTitle)
    .attr("text-anchor", "middle")
    .text("Week of Year");
  // Y AXIS TITLES
  g.append("text")
    .attr("class", "axis-title y")
    .attr("id", "axis-title-returns")
    .attr("y", (-margin.left * 3) / 4)
    .attr("x", (-1 / 2) * heightChart)
    .attr("font-size", fontStyles.axisTitle.fontSize + "px")
    .attr("font-weight", fontStyles.axisTitle.fontWeight)
    .attr("fill", colors.axisTitle)
    .attr("transform", "rotate(-90)")
    .attr("text-anchor", "middle")
    .text("Return (%)");
  // CHART LABELS
  // // SUBTITLE
  g.append("text")
    .attr("class", "chart-label")
    .attr("x", widthChart - 10)
    .attr("y", 20)
    .attr("font-size", "calc(0.5vw + 0.2rem)")
    .attr("font-weight", 400)
    .attr("fill", colors.axisLabel)
    .attr("opacity", 0.4)
    .attr("text-anchor", "end")
    .attr("letter-spacing", "0.25px")
    .text("WEEKLY RETURN STATISTICS");
  // // UNDERLYING
  g.append("text")
    .attr("class", "chart-main-label chart-symbol")
    .attr("x", widthChart / 2)
    .attr("y", (3 / 4) * heightChart + 7)
    .attr("font-size", "calc(1vw + 1rem)")
    .attr("font-weight", 500)
    .attr("fill", colors.underlyingLabel)
    .attr("text-anchor", "middle")
    .text(`${data.symbol}`);
  g.append("text")
    .attr("class", "chart-main-label chart-symbol-name")
    .attr("x", widthChart / 2)
    .attr("y", (3 / 4) * heightChart + 27)
    .attr("font-size", "calc(0.5vw + 0.3rem)")
    .attr("font-weight", 400)
    .attr("fill", colors.underlyingLabel)
    .attr("text-anchor", "middle")
    .attr("letter-spacing", "0.25px")
    .text(`${data.name.toUpperCase()}`);

  // SCALES --------------------------------------------------------------------
  // X DATE
  const xScale = d3.scaleLinear().range([0, widthChart]).domain([0, 53]);

  // Y RETURN
  const yMax = d3.max([
    ...Object.values(seasonalityData.pastSeasonality.max),
    ...Object.values(seasonalityData.currentSeasonality),
  ]);
  const yMin = d3.min([
    ...Object.values(seasonalityData.pastSeasonality.min),
    ...Object.values(seasonalityData.currentSeasonality),
  ]);

  const yPadding = (yMax - yMin) * 0.15;
  const yScale = d3
    .scaleLinear()
    .range([heightChart, 0])
    .domain([yMin - yPadding, yMax + yPadding]);

  // AXES --------------------------------------------------------------------
  // X AXIS
  const xAxisBottomCall = d3.axisBottom().scale(xScale).tickSizeOuter(0);
  const xAxisBottom = g
    .append("g")
    .attr("class", "x axis bottom")
    .attr("transform", `translate(0, ${heightChart})`);
  xAxisBottom.call(xAxisBottomCall);

  const xAxisTopCall = d3
    .axisTop()
    .scale(xScale)
    .tickSizeOuter(0)
    .ticks(0)
    .tickFormat("");
  const xAxisTop = g.append("g").attr("class", "x axis top");
  xAxisTop.call(xAxisTopCall);

  // Y AXIS
  const yAxisLeftCall = d3.axisLeft().scale(yScale).tickSizeOuter(0);
  const yAxisLeft = g.append("g").attr("class", "y axis left");
  yAxisLeft.call(yAxisLeftCall);

  const yAxisRightCall = d3.axisRight().scale(yScale).tickSizeOuter(0);
  const yAxisRight = g
    .append("g")
    .attr("class", "y axis right")
    .attr("transform", `translate(${widthChart}, 0)`);
  yAxisRight.call(yAxisRightCall);

  // // AXES STYLING
  d3.selectAll(`#${nodeId} .axis path`).attr("stroke", colors.axisLine);
  d3.selectAll(`#${nodeId} .axis line`).attr("stroke", colors.axisLine);
  d3.selectAll(`#${nodeId} .axis text`)
    .attr("fill", colors.axisLabel)
    .attr("font-size", fontStyles.axisLabel.fontSize);

  // DEFINING CHART ELEMENTS NOT TO BE UPDATED ------------------------------------------------------------
  // GRADIENTS
  const gradientWhite = changeHexColorOpacity("#fff", 0.3);
  const gradientWhiteOffset = 5;
  const gradientBlueMean = changeHexColorOpacity(colors.blueMain, 0.3);
  const gradientBlueMedian = changeHexColorOpacity(colors.blueDarkest, 0.4);

  for (let k of ["mean", "median"]) {
    for (let direction of ["below", "above"])
      svg
        .append("linearGradient")
        .attr("id", `area-gradient_${direction}_${k}`)
        // .attr("gradientUnits", "userSpaceOnUse")
        .attr("x1", "0%")
        .attr("y1", "0%")
        .attr("x2", "0%")
        .attr("y2", "100%")
        .selectAll("stop")
        .data([
          {
            offset: direction === "below" ? "0%" : `${gradientWhiteOffset}%`,
            color:
              direction === "below"
                ? k === "mean"
                  ? gradientBlueMean
                  : gradientBlueMedian
                : gradientWhite,
          },
          {
            offset:
              direction === "below" ? `${100 - gradientWhiteOffset}%` : "100%",
            color:
              direction === "below"
                ? gradientWhite
                : k === "mean"
                ? gradientBlueMean
                : gradientBlueMedian,
          },
        ])
        .enter()
        .append("stop")
        .attr("offset", (d) => d.offset)
        .attr("stop-color", (d) => d.color);
  }

  // X AXIS MONTHS
  for (let d of monthLabelData) {
    g.append("text")
      .attr("class", "x-axis-month-label")
      .attr("x", xScale(d.monthMidInWeeks))
      .attr("y", heightChart - 5)
      .attr("font-size", fontStyles.axisLabel.fontSize)
      .attr("fill", colors.axisLabel)
      .attr("text-anchor", "middle")
      .text(d.monthName);
    if (d.monthName !== "Dec") {
      g.append("line")
        .attr("class", "x-axis-month-end-line")
        .attr("x1", xScale(d.monthEndInWeeks))
        .attr("x2", xScale(d.monthEndInWeeks))
        .attr("y1", heightChart - 5)
        .attr("y2", heightChart)
        .attr("stroke", colors.axisLabel)
        .attr("stroke-width", 1);
    }
  }

  // CURRENT WEEK X AXIS LABEL
  g.append("line")
    .attr("class", "current-week-x-label-line")
    .attr("x1", xScale(currentWeek))
    .attr("x2", xScale(currentWeek))
    .attr("y1", heightChart)
    .attr("y2", heightChart + 5)
    .attr("stroke", colors.redLabel)
    .attr("stroke-width", 1)
    .attr("opacity", 0.75);
  g.append("rect")
    .attr("class", "current-week-x-label-rect")
    .attr("x", xScale(currentWeek) - 20)
    .attr("y", heightChart + 5)
    .attr("width", 40)
    .attr("height", 14)
    .attr("fill", colors.redLabel)
    .attr("opacity", 0.75);
  g.append("text")
    .attr("class", "current-week-x-label-text")
    .attr("x", xScale(currentWeek))
    .attr(
      "y",
      heightChart + 5 + 14 - (14 - fontStyles.axisLabel.fontSize) / 1.5
    )
    .attr("text-anchor", "middle")
    .attr("font-size", fontStyles.axisLabel.fontSize)
    .attr("font-weight", fontStyles.axisLabel.fontWeight)
    .attr("fill", "#fff")
    .text(currentWeek);

  // DEFINING CHART ELEMENTS TO BE UPDATED ------------------------------------------------------------
  const xMarginAfterValue = 40;
  const xChartLabelCurrent = 5;
  const xChartValueCurrent = xChartLabelCurrent + 50;
  const xChartLabelMean = xChartValueCurrent + xMarginAfterValue;
  const xChartValueMean = xChartLabelMean + 34;
  const xChartLabelStDev = xChartValueMean + xMarginAfterValue;
  const xChartValueStDev = xChartLabelStDev + 39;
  const xChartLabelMedian = xChartValueStDev + xMarginAfterValue;
  const xChartValueMedian = xChartLabelMedian + 44;
  const xChartLabelMin = xChartValueMedian + xMarginAfterValue;
  const xChartValueMin = xChartLabelMin + 24;
  const xChartLabelMax = xChartValueMin + xMarginAfterValue;
  const xChartValueMax = xChartLabelMax + 29;

  const chartLabelCurrent = g
    .append("text")
    .attr("class", "chart-text chart-label chart-label-current")
    .attr("x", xChartLabelCurrent)
    .text("CURRENT:");
  const chartValueCurrent = g
    .append("text")
    .attr("class", "chart-text chart-value chart-value-current")
    .attr("x", xChartValueCurrent)
    .attr("fill", colors.red);
  const chartLabelMean = g
    .append("text")
    .attr("class", "chart-text chart-label chart-label-mean")
    .attr("x", xChartLabelMean)
    .text("MEAN:");
  const chartValueMean = g
    .append("text")
    .attr("class", "chart-text chart-value chart-value-mean")
    .attr("x", xChartValueMean)
    .attr("fill", colors.blueMain);
  const chartLabelStDev = g
    .append("text")
    .attr("class", "chart-text chart-label chart-label-stdev")
    .attr("x", xChartLabelStDev)
    .text("STDEV:");
  const chartValueStDev = g
    .append("text")
    .attr("class", "chart-text chart-value chart-value-stdev")
    .attr("x", xChartValueStDev)
    .attr("fill", colors.axisLine);
  const chartLabelMedian = g
    .append("text")
    .attr("class", "chart-text chart-label chart-label-median")
    .attr("x", xChartLabelMedian)
    .text("MEDIAN:");
  const chartValueMedian = g
    .append("text")
    .attr("class", "chart-text chart-value chart-value-median")
    .attr("x", xChartValueMedian)
    .attr("fill", colors.blueDarker);
  const chartLabelMin = g
    .append("text")
    .attr("class", "chart-text chart-label chart-label-min")
    .attr("x", xChartLabelMin)
    .text("MIN:");
  const chartValueMin = g
    .append("text")
    .attr("class", "chart-text chart-value chart-value-min")
    .attr("x", xChartValueMin)
    .attr("fill", colors.axisLine);
  const chartLabelMax = g
    .append("text")
    .attr("class", "chart-text chart-label chart-label-max")
    .attr("x", xChartLabelMax)
    .text("MAX:");
  const chartValueMax = g
    .append("text")
    .attr("class", "chart-text chart-value chart-value-max")
    .attr("x", xChartValueMax)
    .attr("fill", colors.axisLine);

  g.selectAll(".chart-text")
    .attr("y", 13)
    .attr("font-size", fontStyles.axisLabel.fontSize);
  g.selectAll(".chart-label").attr("fill", colors.axisTitle);

  // STATIC CHART ELEMENTS ----------------------------------------------------------------------------
  // UTILITY CHART ELEMENTS
  const utilityLineZero = g
    .append("line")
    .attr("class", "utility-line-100")
    .attr("x1", 0)
    .attr("x2", widthChart)
    .attr("y1", yScale(0))
    .attr("y2", yScale(0))
    .attr("stroke", colors.axisLabel)
    .attr("stroke-width", 1)
    .attr("stroke-dasharray", "1,1");

  // PAST SEASONALITY DATA ELEMENTS
  const dataPS = seasonalityData.pastSeasonality;
  const symbolSize = window.innerWidth < 1700 ? 20 : 30;
  const symbolSizeExtrema = symbolSize / 2;
  const stdevBarWidth = 6;
  const stdevBarStrokeWidth = 1;
  const minMaxRectWidth = stdevBarStrokeWidth + 4;

  for (let i = 1; i < 53; i++) {
    // MIN DATAPOINTS
    // g.append("path")
    //   .attr("class", "seasonality-past-min-symbol")
    //   .attr(
    //     "transform",
    //     `translate(${xScale(i)},${yScale(dataPS.min[i])}),rotate(180)`
    //   )
    //   .attr("d", d3.symbol().type(d3.symbolTriangle).size(symbolSizeExtrema))
    //   .attr("fill", "#fff")
    //   .attr("stroke", () => changeHexColorOpacity(colors.axisLine, 0.75))
    //   .attr("stroke-width", stdevBarStrokeWidth);

    // MAX DATAPOINTS
    // g.append("path")
    //   .attr("class", "seasonality-past-max-symbol")
    //   .attr(
    //     "transform",
    //     `translate(${xScale(i)},${yScale(dataPS.max[i])}),rotate(0)`
    //   )
    //   .attr("d", d3.symbol().type(d3.symbolTriangle).size(symbolSizeExtrema))
    //   .attr("fill", "#fff")
    //   .attr("stroke", () => changeHexColorOpacity(colors.axisLine, 0.75))
    //   .attr("stroke-width", stdevBarStrokeWidth);

    // MIN-MAX RECTS WITH GRADIENT
    const gradientId = `area-gradient_week${i}`;
    const pctPositionMean =
      ((dataPS.max[i] - dataPS.mean[i]) / (dataPS.max[i] - dataPS.min[i])) *
      100;
    svg
      .append("linearGradient")
      .attr("id", gradientId)
      .attr("x1", "0%")
      .attr("y1", "0%")
      .attr("x2", "0%")
      .attr("y2", "100%")
      .selectAll("stop")
      .data([
        {
          offset: "0%",
          color: colors.axisLine,
          opacity: 0.15,
        },
        {
          offset: `${pctPositionMean}%`,
          color: colors.axisLine,
          opacity: 0.6,
        },
        {
          offset: "100%",
          color: colors.axisLine,
          opacity: 0.15,
        },
      ])
      .enter()
      .append("stop")
      .attr("offset", (d) => d.offset)
      .attr("stop-color", (d) => d.color)
      .attr("stop-opacity", (d) => d.opacity);

    g.append("rect")
      .attr("x", xScale(i) - minMaxRectWidth / 2)
      .attr("y", yScale(dataPS.max[i]))
      .attr("width", minMaxRectWidth)
      .attr("height", yScale(dataPS.min[i]) - yScale(dataPS.max[i]))
      .attr("fill", `url(#${gradientId})`);

    // ERROR BARS
    g.append("line")
      .attr("class", "seasonality-past-error-bar")
      .attr("x1", xScale(i))
      .attr("x2", xScale(i))
      .attr("y1", yScale(dataPS.mean[i] - dataPS.std[i]))
      .attr("y2", yScale(dataPS.mean[i] + dataPS.std[i]));

    g.append("line")
      .attr("class", "seasonality-past-error-bar upper")
      .attr("x1", xScale(i) - stdevBarWidth / 2)
      .attr("x2", xScale(i) + stdevBarWidth / 2)
      .attr("y1", yScale(dataPS.mean[i] + dataPS.std[i]))
      .attr("y2", yScale(dataPS.mean[i] + dataPS.std[i]));

    g.append("line")
      .attr("class", "seasonality-past-error-bar lower")
      .attr("x1", xScale(i) - stdevBarWidth / 2)
      .attr("x2", xScale(i) + stdevBarWidth / 2)
      .attr("y1", yScale(dataPS.mean[i] - dataPS.std[i]))
      .attr("y2", yScale(dataPS.mean[i] - dataPS.std[i]));

    g.selectAll(".seasonality-past-error-bar")
      .attr("stroke", colors.axisLine)
      .attr("stroke-width", stdevBarStrokeWidth);

    // MEAN DATAPOINTS
    g.append("path")
      .attr("class", "seasonality-past-mean-symbol")
      .attr(
        "transform",
        `translate(${xScale(i)},${yScale(dataPS.mean[i])}),rotate(45)`
      )
      .attr("d", d3.symbol().type(d3.symbolSquare).size(symbolSize))
      .attr("fill", () => changeHexColorOpacity(colors.blueMain, 0.5))
      .attr("stroke", colors.blueMain)
      .attr("stroke-width", stdevBarStrokeWidth);

    // MEDIAN DATAPOINTS
    g.append("path")
      .attr("class", "seasonality-past-median-symbol")
      .attr(
        "transform",
        `translate(${xScale(i)},${yScale(dataPS.median[i])}),rotate(45)`
      )
      .attr("d", d3.symbol().type(d3.symbolSquare).size(symbolSize))
      // .attr("fill", () => changeHexColorOpacity("#fff", 0.5))
      .attr("fill", () => changeHexColorOpacity(colors.blueDarkest, 0.5))
      .attr("stroke", colors.blueDarkest)
      .attr("stroke-width", stdevBarStrokeWidth);
  }

  // CURRENT SEASONALITY DATA ELEMENTS
  const dataCS = seasonalityData.currentSeasonality;
  const currentWeekNum = parseInt(currentWeek);
  for (let i = 1; i <= currentWeekNum; i++) {
    g.append("path")
      .attr("class", "seasonality-current-symbol")
      .attr(
        "transform",
        `translate(${xScale(i)},${yScale(dataCS[i])}),rotate(45)`
      )
      .attr(
        "d",
        d3
          .symbol()
          .type(d3.symbolSquare)
          .size(i === currentWeekNum ? symbolSize * 2 : symbolSize)
      )
      .attr(
        "fill",
        i === currentWeekNum
          ? colors.redLabel
          : () => changeHexColorOpacity(colors.red, 0.5)
        // :  "transparent"
      )
      .attr("stroke", i === currentWeekNum ? "none" : colors.red);
    // .attr("stroke-width", stdevBarStrokeWidth);
  }

  // // APPENDING ALL POSSIBLE SEASONALITY LINES
  // for (let key of Object.keys(
  //   seasonalityData.pastPerformanceProgression.allYearsIndiviual
  // )) {
  //   const annualPerformance =
  //     seasonalityData.pastPerformanceProgression.allYearsIndiviual[key][52];
  //   g.append("path")
  //     .attr(
  //       "class",
  //       `line-seasonality line-seasonality-${key} line-seasonality-history`
  //     )
  //     .attr("stroke", colors.axisLine)
  //     .attr("stroke-width", lineStyles.strokeWidth.secondary.normal)
  //     .attr("visibility", "hidden")
  //     .on("mouseover", function () {
  //       d3.select(this)
  //         .attr("stroke-width", lineStyles.strokeWidth.secondary.thicker)
  //         .attr("stroke", "#333")
  //         .attr("opacity", 1)
  //         .raise();
  //       d3.select(".tooltip-performance-line-rect")
  //         .attr("x", d3.mouse(this)[0] + 5)
  //         .attr("y", d3.mouse(this)[1] - 18)
  //         .attr("visibility", "visible")
  //         .raise();
  //       d3.select(".tooltip-performance-line-text")
  //         .attr("x", d3.mouse(this)[0] + 5 + 25)
  //         .attr("y", d3.mouse(this)[1] - 3 - 4)
  //         .attr("visibility", "visible")
  //         .text(key)
  //         .raise();
  //       d3.select(".annual-performance-historic-label-line")
  //         .attr("y1", yScale(annualPerformance))
  //         .attr("y2", yScale(annualPerformance))
  //         .attr("visibility", "visible");
  //       d3.select(".annual-performance-historic-label-rect")
  //         .attr("y", yScale(annualPerformance) - 8)
  //         .attr("visibility", "visible");
  //       d3.select(".annual-performance-historic-label-text")
  //         .attr(
  //           "y",
  //           yScale(annualPerformance) +
  //             (14 - fontStyles.axisLabel.fontSize) / 1.5
  //         )
  //         .attr("visibility", "visible")
  //         .text(annualPerformance.toFixed(2));
  //     })
  //     .on("mouseout", function () {
  //       d3.select(this)
  //         .attr("stroke-width", lineStyles.strokeWidth.secondary.normal)
  //         .attr("stroke", colors.axisLine)
  //         .attr("opacity", 0.5);
  //       d3.select(".tooltip-performance-line-rect").attr(
  //         "visibility",
  //         "hidden"
  //       );
  //       d3.select(".tooltip-performance-line-text").attr(
  //         "visibility",
  //         "hidden"
  //       );
  //       d3.select(".annual-performance-historic-label-line").attr(
  //         "visibility",
  //         "hidden"
  //       );
  //       d3.select(".annual-performance-historic-label-rect").attr(
  //         "visibility",
  //         "hidden"
  //       );
  //       d3.select(".annual-performance-historic-label-text").attr(
  //         "visibility",
  //         "hidden"
  //       );
  //     });
  // }
  // // CURRENT PERFORMANCE LINE
  // g.append("path")
  //   .attr(
  //     "class",
  //     "line-seasonality line-seasonality-current line-seasonality-main"
  //   )
  //   .attr("stroke", colors.red)
  //   .on("mouseover", function () {
  //     d3.select(this)
  //       .attr("stroke-width", lineStyles.strokeWidth.primary.thicker)
  //       .raise();
  //     d3.select(".tooltip-performance-line-rect")
  //       .attr("x", d3.mouse(this)[0] + 5)
  //       .attr("y", d3.mouse(this)[1] - 18)
  //       .attr("visibility", "visible")
  //       .raise();
  //     d3.select(".tooltip-performance-line-text")
  //       .attr("x", d3.mouse(this)[0] + 5 + 25)
  //       .attr("y", d3.mouse(this)[1] - 3 - 4)
  //       .attr("visibility", "visible")
  //       .text(data.lastPriceAction.date.slice(0, 4))
  //       .raise();
  //   })
  //   .on("mouseout", function () {
  //     d3.select(this).attr(
  //       "stroke-width",
  //       lineStyles.strokeWidth.primary.normal
  //     );
  //     d3.select(".tooltip-performance-line-rect").attr("visibility", "hidden");
  //     d3.select(".tooltip-performance-line-text").attr("visibility", "hidden");
  //   });
  // g.append("path")
  //   .attr(
  //     "class",
  //     "line-seasonality line-seasonality-pastMean line-seasonality-main"
  //   )
  //   .attr("stroke", colors.blueMain)
  //   .on("mouseover", function () {
  //     d3.select(this)
  //       .attr("stroke-width", lineStyles.strokeWidth.primary.thicker)
  //       .raise();
  //     d3.select(".tooltip-performance-line-rect")
  //       .attr("x", d3.mouse(this)[0] + 5)
  //       .attr("y", d3.mouse(this)[1] - 18)
  //       .attr("visibility", "visible")
  //       .raise();
  //     d3.select(".tooltip-performance-line-text")
  //       .attr("x", d3.mouse(this)[0] + 5 + 25)
  //       .attr("y", d3.mouse(this)[1] - 3 - 4)
  //       .attr("visibility", "visible")
  //       .text("MEAN")
  //       .raise();
  //   })
  //   .on("mouseout", function () {
  //     d3.select(this).attr(
  //       "stroke-width",
  //       lineStyles.strokeWidth.primary.normal
  //     );
  //     d3.select(".tooltip-performance-line-rect").attr("visibility", "hidden");
  //     d3.select(".tooltip-performance-line-text").attr("visibility", "hidden");
  //   });

  // g.append("path")
  //   .attr(
  //     "class",
  //     "line-seasonality line-seasonality-pastMedian line-seasonality-main"
  //   )
  //   .attr("stroke", colors.blueDarkest)
  //   .on("mouseover", function () {
  //     d3.select(this)
  //       .attr("stroke-width", lineStyles.strokeWidth.primary.thicker)
  //       .raise();
  //     d3.select(".tooltip-performance-line-rect")
  //       .attr("x", d3.mouse(this)[0] + 5)
  //       .attr("y", d3.mouse(this)[1] - 18)
  //       .attr("visibility", "visible")
  //       .raise();
  //     d3.select(".tooltip-performance-line-text")
  //       .attr("x", d3.mouse(this)[0] + 5 + 25)
  //       .attr("y", d3.mouse(this)[1] - 3 - 4)
  //       .attr("visibility", "visible")
  //       .text("MEDIAN")
  //       .raise();
  //   })
  //   .on("mouseout", function () {
  //     d3.select(this).attr(
  //       "stroke-width",
  //       lineStyles.strokeWidth.primary.normal
  //     );
  //     d3.select(".tooltip-performance-line-rect").attr("visibility", "hidden");
  //     d3.select(".tooltip-performance-line-text").attr("visibility", "hidden");
  //   });

  // d3.selectAll(".line-seasonality").attr("fill", "none");
  // d3.selectAll(".line-seasonality-main").attr(
  //   "stroke-width",
  //   lineStyles.strokeWidth.primary.normal
  // );

  // CURRENT WEEK CIRCLE
  // const currentWeekCircle = g
  //   .append("circle")
  //   .attr("class", "current-week-circle")
  //   .attr("r", 3)
  //   .attr("stroke", colors.redLabel)
  //   .attr("stroke-width", 1.2)
  //   .attr("fill", "transparent")
  //   .attr("cx", xScale(currentWeek))
  //   .attr("cy", yScale(currentWeekReturn));

  // CURRENT WEEK Y AXIS LABEL
  const currentWeekYaxisLabelLine = g
    .append("line")
    .attr("class", "current-week-y-label-line")
    .attr("x1", -5)
    .attr("x2", 0)
    .attr("y1", yScale(currentWeekReturn))
    .attr("y2", yScale(currentWeekReturn))
    .attr("stroke", colors.redLabel)
    .attr("stroke-width", 1)
    .attr("opacity", 0.75);
  const currentWeekYaxisLabelRect = g
    .append("rect")
    .attr("class", "current-week-y-label-rect")
    .attr("width", 40)
    .attr("height", 14)
    .attr("x", -5 - 40)
    .attr("y", yScale(currentWeekReturn) - 8)
    .attr("fill", colors.redLabel)
    .attr("opacity", 0.75);
  const currentWeekYaxisLabelText = g
    .append("text")
    .attr("class", "current-week-y-label-text")
    .attr("x", -5 - 40 + 20)
    .attr(
      "y",
      yScale(currentWeekReturn) + (14 - fontStyles.axisLabel.fontSize) / 1.5
    )
    .attr("text-anchor", "middle")
    .attr("font-size", fontStyles.axisLabel.fontSize)
    .attr("font-weight", fontStyles.axisLabel.fontWeight)
    .attr("fill", "#fff")
    .text(currentWeekReturn.toFixed(2));

  // ANNUAL PERFORMANCE MEAN LABEL
  // const labelAnnualPerformanceMeanLine = g
  //   .append("line")
  //   .attr("class", "annual-performance-mean-label-line")
  //   .attr("x1", widthChart)
  //   .attr("x2", widthChart + 5)
  //   .attr("stroke", colors.blueMain)
  //   .attr("stroke-width", 1)
  //   .attr("opacity", 0.75);
  // const labelAnnualPerformanceMeanRect = g
  //   .append("rect")
  //   .attr("class", "annual-performance-mean-label-rect")
  //   .attr("width", 40)
  //   .attr("height", 14)
  //   .attr("x", widthChart + 5)
  //   .attr("fill", colors.blueMain)
  //   .attr("opacity", 0.75);
  // const labelAnnualPerformanceMeanText = g
  //   .append("text")
  //   .attr("class", "annual-performance-mean-label-text")
  //   .attr("x", widthChart + 5 + 20)
  //   .attr("text-anchor", "middle")
  //   .attr("font-size", fontStyles.axisLabel.fontSize)
  //   .attr("font-weight", fontStyles.axisLabel.fontWeight)
  //   .attr("fill", "#fff")
  //   .text(annualPerformanceMean.toFixed(2));

  // ANNUAL PERFORMANCE MEDIAN LABEL
  // const labelAnnualPerformanceMedianLine = g
  //   .append("line")
  //   .attr("class", "annual-performance-median-label-line")
  //   .attr("x1", widthChart)
  //   .attr("x2", widthChart + 5)
  //   .attr("stroke", colors.blueDarkest)
  //   .attr("stroke-width", 1)
  //   .attr("opacity", 0.75);
  // const labelAnnualPerformanceMedianRect = g
  //   .append("rect")
  //   .attr("class", "annual-performance-median-label-rect")
  //   .attr("width", 40)
  //   .attr("height", 14)
  //   .attr("x", widthChart + 5)
  //   .attr("fill", colors.blueDarkest)
  //   .attr("opacity", 0.75);
  // const labelAnnualPerformanceMedianText = g
  //   .append("text")
  //   .attr("class", "annual-performance-median-label-text")
  //   .attr("x", widthChart + 5 + 20)
  //   .attr("text-anchor", "middle")
  //   .attr("font-size", fontStyles.axisLabel.fontSize)
  //   .attr("font-weight", fontStyles.axisLabel.fontWeight)
  //   .attr("fill", "#fff")
  //   .text(annualPerformanceMedian.toFixed(2));

  // // TOOLTIPS FOR PERFORMANCE LINES ------------------------------
  // RECT+TEXT FOR YEAR OF PERFORMANCE LINE (OR MEAN/MEDIAN)
  // g.append("rect")
  //   .attr("class", "tooltip-performance-line-rect")
  //   .attr("width", 50)
  //   .attr("height", 15)
  //   .attr("fill", "#333")
  //   .attr("opacity", 0.95)
  //   .attr("visibility", "hidden");
  // g.append("text")
  //   .attr("class", "tooltip-performance-line-text")
  //   .attr("font-size", "10px")
  //   .attr("fill", "#fff")
  //   .attr("text-anchor", "middle")
  //   .attr("visibility", "hidden");
  // ANNUAL PERFORMANCE FOR HISTORIC LINES
  // ANNUAL PERFORMANCE MEAN LABEL
  // g.append("line")
  //   .attr("class", "annual-performance-historic-label-line")
  //   .attr("x1", widthChart)
  //   .attr("x2", widthChart + 5)
  //   .attr("stroke", "#333")
  //   .attr("stroke-width", 1)
  //   .attr("opacity", 0.95)
  //   .attr("visibility", "hidden");
  // g.append("rect")
  //   .attr("class", "annual-performance-historic-label-rect")
  //   .attr("width", 40)
  //   .attr("height", 14)
  //   .attr("x", widthChart + 5)
  //   .attr("fill", "#333")
  //   .attr("opacity", 0.95)
  //   .attr("visibility", "hidden");
  // g.append("text")
  //   .attr("class", "annual-performance-historic-label-text")
  //   .attr("x", widthChart + 5 + 20)
  //   .attr("text-anchor", "middle")
  //   .attr("font-size", fontStyles.axisLabel.fontSize)
  //   .attr("font-weight", fontStyles.axisLabel.fontWeight)
  //   .attr("fill", "#fff")
  //   .attr("visibility", "hidden");

  // HOVERLINES ------------------------------------------------------------------
  // HOVER LINES
  const hover = g.append("g").attr("class", `${nodeId} hover`);
  // Hover lines
  const xHoverLine = hover
    .append("line")
    .attr("class", "x-hover-line hover-line x-hover-element")
    .attr("y1", 0)
    .attr("y2", heightChart + 5);
  const yHoverLine = hover
    .append("line")
    .attr("class", "y-hover-line hover-line")
    .attr("x1", -5)
    .attr("x2", widthChart);
  hover
    .selectAll(".hover-line")
    .attr("stroke", colors.axisLine)
    .attr("stroke-width", 0.5)
    .attr("stroke-dasharray", "1,1")
    .attr("opacity", 1);

  const hoverXAxisRect = hover
    .append("rect")
    .attr("class", "x-hover-rect hover-rect x-hover-element")
    .attr("width", 40)
    .attr("height", 14)
    .attr("y", heightChart + 5)
    .attr("x", -20)
    .attr("fill", "#333")
    .attr("opacity", 0.95);
  const hoverXAxisText = hover
    .append("text")
    .attr("class", "x-hover-text hover-text x-hover-element")
    .attr("y", heightChart + 16)
    .attr("text-anchor", "middle")
    .attr("font-size", "10px")
    .attr("fill", "#fff");
  const hoverYAxisRect = hover
    .append("rect")
    .attr("class", "y-hover-rect hover-rect")
    .attr("width", 40)
    .attr("height", 14)
    .attr("x", -5 - 40)
    .attr("fill", "#333")
    .attr("opacity", 0.95);
  const hoverYAxisText = hover
    .append("text")
    .attr("class", "y-hover-text hover-text")
    .attr("x", -5 - 20)
    .attr("text-anchor", "middle")
    .attr("font-size", "10px")
    .attr("fill", "#fff");
  const overlay = g
    .append("rect")
    .attr("class", "seasonality-profile-chart-overlay")
    .attr("x", 0)
    .attr("y", 0)
    .attr("width", widthChart)
    .attr("height", heightChart)
    .attr("fill", "transparent");
  // .lower();
  const hoverElements = [
    xHoverLine,
    hoverXAxisRect,
    hoverXAxisText,
    yHoverLine,
    hoverYAxisRect,
    hoverYAxisText,
  ];
  hoverElements.forEach((el) => el.attr("display", "none"));

  overlay
    .on("mouseover", function () {
      hoverElements.forEach((el) => el.attr("display", "normal"));
      d3.selectAll(
        ".SeasonalityViewerResultItem-lineChart.hover .x-hover-element"
      ).attr("display", "normal");
    })
    .on("mousemove", function () {
      const mouseCoords = d3.mouse(this);

      // UPDATING THIS BAR CHART
      const mouseCurrentWeek = Math.ceil(xScale.invert(mouseCoords[0] - 3)); // - 3 equals buffer to the right side of the datapoint

      // // Hover Lines
      xHoverLine.attr("x1", mouseCoords[0]).attr("x2", mouseCoords[0]);
      yHoverLine.attr("y1", mouseCoords[1]).attr("y2", mouseCoords[1]);

      // // X Axis Elements
      hoverXAxisRect.attr(
        "transform",
        "translate(" + mouseCoords[0] + "," + 0 + ")"
      );
      hoverXAxisText
        .attr("transform", "translate(" + mouseCoords[0] + "," + 0 + ")")
        .text(mouseCurrentWeek);

      // // CHART VALUES
      if ((mouseCurrentWeek > 0) & (mouseCurrentWeek < 53)) {
        dataCS[mouseCurrentWeek] &&
          chartValueCurrent.text(dataCS[mouseCurrentWeek].toFixed(2));
        chartValueMean.text(dataPS.mean[mouseCurrentWeek].toFixed(2));
        chartValueStDev.text(dataPS.std[mouseCurrentWeek].toFixed(2));
        chartValueMedian.text(dataPS.median[mouseCurrentWeek].toFixed(2));
        chartValueMin.text(dataPS.min[mouseCurrentWeek].toFixed(2));
        chartValueMax.text(dataPS.max[mouseCurrentWeek].toFixed(2));
      } else [chartValueCurrent, chartValueMean, chartValueStDev, chartValueMedian, chartValueMax, chartValueMin].forEach((d) => d.text(""));

      // // Y Axis Elements
      hoverYAxisRect.attr("y", mouseCoords[1] - 7);
      hoverYAxisText
        .attr("y", mouseCoords[1] + 3.5)
        .text(`${yScale.invert(mouseCoords[1]).toFixed(2)}`);

      // UPDATING ABOVE LINECHART
      d3.select(".SeasonalityViewerResultItem-lineChart.hover .x-hover-line")
        .attr("x1", mouseCoords[0])
        .attr("x2", mouseCoords[0]);
      d3.select(
        ".SeasonalityViewerResultItem-lineChart.hover .x-hover-rect"
      ).attr("transform", "translate(" + mouseCoords[0] + "," + 0 + ")");
      d3.select(".SeasonalityViewerResultItem-lineChart.hover .x-hover-text")
        .attr("transform", "translate(" + mouseCoords[0] + "," + 0 + ")")
        .text(Math.ceil(xScale.invert(mouseCoords[0] - 3))); // - 3 equals buffer to the right side of the datapoint
    })
    .on("mouseout", function () {
      hoverElements.forEach((el) => el.attr("display", "none"));
      [
        chartValueCurrent,
        chartValueMean,
        chartValueStDev,
        chartValueMedian,
        chartValueMax,
        chartValueMin,
      ].forEach((d) => d.text(""));
      d3.selectAll(
        ".SeasonalityViewerResultItem-lineChart.hover .x-hover-element"
      ).attr("display", "none");
    });

  // g.selectAll(".line-seasonality").on("mouseenter", () =>
  //   g.select(`.${nodeId}.hover`).attr("display", "normal")
  // );

  // // ===================================================================================================
  // // ======================================= MAIN UPDATE FUNCTION ======================================
  // const updateChart = () => {
  //   // TRANSITION --------------------------------------------------------------------------------------
  //   const t = d3.transition().duration(250).ease(d3.easeLinear);

  //   // CHART PARAMETER VALUES --------------------------------------------------------------------------
  //   const showAllProfiles =
  //     d3.select(
  //       ".SeasonalityViewerResultItem-button-group-allLines .ant-radio-button-checked input"
  //     )._groups[0][0].value === "yes";

  //   // PREPARING DATA --------------------------------------------------------------------------
  //   const chartData = !showAllProfiles
  //     ? {
  //         pastMean: { ...seasonalityData.pastPerformanceProgression.mean },
  //         pastMedian: {
  //           ...seasonalityData.pastPerformanceProgression.median,
  //         },
  //         current: { ...seasonalityData.currentPerformanceProgression },
  //       }
  //     : {
  //         pastMean: { ...seasonalityData.pastPerformanceProgression.mean },
  //         pastMedian: {
  //           ...seasonalityData.pastPerformanceProgression.median,
  //         },
  //         current: { ...seasonalityData.currentPerformanceProgression },
  //         ...seasonalityData.pastPerformanceProgression.allYearsIndiviual,
  //       };

  //   // UPDATING SCALES --------------------------------------------------------------------------
  //   const yDataAll = [];
  //   for (let key of Object.keys(chartData)) {
  //     yDataAll.push(...Object.values(chartData[key]));
  //   }

  //   const [yMin, yMax] = d3.extent(yDataAll);
  //   const yPadding = (yMax - yMin) * 0.1;
  //   yScale.domain([yMin - yPadding * 2.5, yMax + yPadding * 2]);

  //   // UPDATING AXES --------------------------------------------------------------------------
  //   yAxisLeftCall.scale(yScale);
  //   yAxisLeft.transition(t).call(yAxisLeftCall);
  //   yAxisRightCall.scale(yScale);
  //   yAxisRight.transition(t).call(yAxisRightCall);

  //   // // AXES STYLING
  //   d3.selectAll(`#${nodeId} .axis path`).attr("stroke", colors.axisLine);
  //   d3.selectAll(`#${nodeId} .axis line`).attr("stroke", colors.axisLine);
  //   d3.selectAll(`#${nodeId} .axis text`)
  //     .attr("fill", colors.axisLabel)
  //     .attr("font-size", fontStyles.axisLabel.fontSize);

  //   // UPDATING FILL AREAS --------------------------------------------------------------------
  //   const areaBelow = d3
  //     .area()
  //     .x((d) => xScale(d.week))
  //     .y0(heightChart)
  //     .y1((d) => yScale(d.value));
  //   const areaAbove = d3
  //     .area()
  //     .x((d) => xScale(d.week))
  //     .y0(0)
  //     .y1((d) => yScale(d.value));

  //   for (let k of Object.keys(bestSectionsAreaData)) {
  //     for (let i = 0; i < bestSectionsAreaData[k].length; i++) {
  //       const data = bestSectionsAreaData[k][i];
  //       for (let direction of ["below", "above"]) {
  //         g.select(`#best-sections-fill-area_${k}${i}${direction}`)
  //           .transition(t)
  //           .attr(
  //             "d",
  //             direction === "below" ? areaBelow(data) : areaAbove(data)
  //           );
  //       }
  //     }
  //   }

  //   // g.append("path")
  //   //   .attr("class", "best-sections-fill-area")
  //   //   .attr("d", areaBelow(fillData))
  //   //   .attr("fill", "url(#area-gradient_below_mean)")
  //   //   .lower();
  //   // g.append("path")
  //   //   .attr("class", "best-sections-fill-area")
  //   //   .attr("d", areaAbove(fillData))
  //   //   .attr("fill", "url(#area-gradient_above_mean)")
  //   //   .lower();

  //   // UPDATING LINES -------------------------------------------------------------------------
  //   // // Utility Line - 100
  //   utilityLine100
  //     .transition(t)
  //     .attr("y1", yScale(100))
  //     .attr("y2", yScale(100));

  //   // Main Lines
  //   const lineGenerator = d3
  //     .line()
  //     .x((d, i) => xScale(i))
  //     .y((d) => yScale(d));

  //   if (showAllProfiles) {
  //     d3.selectAll(".line-seasonality-history")
  //       .transition(t)
  //       .attr("opacity", 0.5)
  //       .attr("visibility", "visible");
  //     d3.selectAll(".line-seasonality-history").raise();
  //     d3.selectAll(".line-seasonality-main").raise();
  //     //   .transition(t)
  //     //   .attr("stroke-width", lineStyles.strokeWidth.primary.normal);
  //   } else {
  //     d3.selectAll(".line-seasonality-history")
  //       .transition(t)
  //       .attr("visibility", "hidden");
  //     d3.selectAll(".line-seasonality-main").raise();
  //     //   .transition(t)
  //     //   .attr("stroke-width", lineStyles.strokeWidth.primary.normal);
  //   }

  //   for (let key of Object.keys(chartData)) {
  //     d3.select(`.line-seasonality-${key}`)
  //       .transition(t)
  //       .attr("d", lineGenerator(Object.values(chartData[key])));
  //   }

  //   // UPDATING LABELS ---------------------------------------------------------------------------
  //   // CURRENT WEEK Y AXIS LEFT
  //   // currentWeekCircle.transition(t).attr("cy", yScale(currentWeekProgression));
  //   // currentWeekYaxisLabelLine
  //   //   .transition(t)
  //   //   .attr("y1", yScale(currentWeekProgression))
  //   //   .attr("y2", yScale(currentWeekProgression));
  //   // currentWeekYaxisLabelRect
  //   //   .transition(t)
  //   //   .attr("y", yScale(currentWeekProgression) - 8);
  //   // currentWeekYaxisLabelText
  //   //   .transition(t)
  //   //   .attr(
  //   //     "y",
  //   //     yScale(currentWeekProgression) +
  //   //       (14 - fontStyles.axisLabel.fontSize) / 1.5
  //   //   );
  //   // ANNUAL PERFORMANCE Y AXIS RIGHT
  //   // const yAnnualPerformanceMean = yScale(annualPerformanceMean);
  //   // const yAnnualPerformanceMedian = yScale(annualPerformanceMedian);
  //   // let yMeanAdjustVal = 0;

  //   // if (
  //   //   ((yAnnualPerformanceMean - yAnnualPerformanceMedian) ** 2) ** 0.5 <
  //   //   14
  //   // ) {
  //   //   yMeanAdjustVal =
  //   //     yAnnualPerformanceMean <= yAnnualPerformanceMedian ? -7 : 7;
  //   // }

  //   // const yAnnualPerformanceMeanAdjusted =
  //   //   yAnnualPerformanceMean + yMeanAdjustVal;
  //   // const yAnnualPerformanceMedianAdjusted =
  //   //   yAnnualPerformanceMedian - yMeanAdjustVal;

  //   // labelAnnualPerformanceMeanLine
  //   //   .transition(t)
  //   //   .attr("y1", yAnnualPerformanceMean)
  //   //   .attr("y2", yAnnualPerformanceMean);
  //   // labelAnnualPerformanceMeanRect
  //   //   .transition(t)
  //   //   .attr("y", yAnnualPerformanceMeanAdjusted - 8);
  //   // labelAnnualPerformanceMeanText
  //   //   .transition(t)
  //   //   .attr(
  //   //     "y",
  //   //     yAnnualPerformanceMeanAdjusted +
  //   //       (14 - fontStyles.axisLabel.fontSize) / 1.5
  //   //   );
  //   // labelAnnualPerformanceMedianLine
  //   //   .transition(t)
  //   //   .attr("y1", yAnnualPerformanceMedian)
  //   //   .attr("y2", yAnnualPerformanceMedian);
  //   // labelAnnualPerformanceMedianRect
  //   //   .transition(t)
  //   //   .attr("y", yAnnualPerformanceMedianAdjusted - 8);
  //   // labelAnnualPerformanceMedianText
  //   //   .transition(t)
  //   //   .attr(
  //   //     "y",
  //   //     yAnnualPerformanceMedianAdjusted +
  //   //       (14 - fontStyles.axisLabel.fontSize) / 1.5
  //   //   );
  // };

  // // updateChart();

  // // EVENT LISTENER ------------------------------------------------------------------------------------
  // // RADIO BUTTONS - TRADE DURATION
  // d3.selectAll(".SeasonalityViewerResultItem-button-group-allLines input").on(
  //   "change",
  //   updateChart
  // );
}

export default drawSeasonalityBarChart;

// UTILITY FUNCTIONS
function changeHexColorOpacity(hexColor, opacity) {
  const c = d3.rgb(hexColor);
  c.opacity = opacity;
  return c;
}
