import React, { useContext } from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';

import START_DATES from './START_DATES.json';

// context
import { AnomaliesContext } from '../../contexts/AnomaliesContext';

const DatePickerStartDateAdjusted = ({ classN }) => {
  // console.log('DatePicker renders');

  // loading context
  const { prvOptions, changeOption } = useContext(AnomaliesContext);
  const { symbol, endDate } = prvOptions;

  const changeEndDate = (date, dateStr) => {
    changeOption('prvOptions', 'endDate', dateStr.replace(/-/g, ''));
  };

  const disabledDate = (current) => {
    return current < moment(earliestDate);
  };

  const earliestDate = String(parseInt(START_DATES[symbol]) + 20000);

  if (endDate !== null && endDate !== '' && earliestDate !== undefined) {
    if (endDate < earliestDate) {
      changeOption('prvOptions', 'endDate', earliestDate);
      // console.log('EndDate updates due to earliestDate');
    }
  }

  return (
    <DatePicker
      disabled={symbol === null ? true : false}
      className={classN}
      placeholder='End Date'
      value={endDate !== null && endDate !== '' && moment(endDate)}
      onChange={changeEndDate}
      disabledDate={disabledDate}
    />
  );
};

export default DatePickerStartDateAdjusted;
