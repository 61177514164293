import axios from "axios";
import { DATA_LOADED, DATA_ERROR, IS_LOADING, INITIAL_STATE } from "../types";
import { setAlert } from "./alertActions";

import { timeParse } from "d3";

export const loadData = (tool, elementCode) => async (dispatch) => {
  try {
    dispatch({
      type: IS_LOADING,
      name: "test",
    });

    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/v1/${tool}/${elementCode}`
    );

    dispatch(setAlert(`Data loaded: ${tool} > ${elementCode}`, "success"));

    dispatch({
      type: DATA_LOADED,
      payload: res.data,
      name: "test",
    });
  } catch (error) {
    console.log("error");
    console.log(error.response);
    dispatch(setAlert(error.response.data.error, "danger"));

    dispatch({
      type: DATA_ERROR,
      payload: error.response.data.error,
      name: "test",
    });
  }
};

export const removeData = () => async (dispatch) => {
  try {
    dispatch({
      type: INITIAL_STATE,
    });
  } catch (error) {
    console.log(error);
  }
};

export const getPeriodicReturnScannerData =
  (category, period, ranking, filter) => async (dispatch) => {
    const dataName = "anomaliesPrs";

    try {
      dispatch({
        type: IS_LOADING,
        name: dataName,
      });

      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/anomalies/return-periodic-scanner/${category}/${period}/${ranking}/${filter}`
      );

      const parseTime = timeParse("%Y%m%d");

      res.data.data.data.forEach((obj) => {
        obj.dateList = obj.dateList.map((s) => parseTime(s));
      });

      dispatch({
        type: DATA_LOADED,
        payload: res.data,
        name: dataName,
      });
    } catch (error) {
      console.log("error");
      console.log(error.response);

      dispatch({
        type: DATA_ERROR,
        payload: error.response.data.error,
        name: dataName,
      });
    }
  };

export const getPeriodicReturnViewerData =
  (symbol, period, endDate) => async (dispatch) => {
    const dataName = "anomaliesPrv";

    try {
      dispatch({
        type: IS_LOADING,
        name: dataName,
      });

      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/anomalies/return-periodic-viewer/${symbol}/${period}/${endDate}`
      );

      const parseTime = timeParse("%Y%m%d");

      res.data.data.dateList = res.data.data.dateList.map((s) => parseTime(s));
      if (res.data.data.vixData) {
        res.data.data.vixData.data.date = res.data.data.vixData.data.date.map(
          (s) => parseTime(s)
        );
      }
      if (res.data.data.currentAnomaly !== "N/A") {
        res.data.data.anomalyTradePerformance.sigmaSections[
          res.data.data.currentAnomaly
        ].startDates = res.data.data.anomalyTradePerformance.sigmaSections[
          res.data.data.currentAnomaly
        ].startDates.map((d) => parseTime(d));
      }

      dispatch({
        type: DATA_LOADED,
        payload: res.data,
        name: dataName,
      });
    } catch (error) {
      console.log(error);

      dispatch({
        type: DATA_ERROR,
        name: dataName,
        payload: error.response.data.error,
      });
    }
  };

export const getPeriodicReturnFilterData =
  (category, period, threshold) => async (dispatch) => {
    const dataName = "anomaliesPrf";

    try {
      dispatch({
        type: IS_LOADING,
        name: dataName,
      });

      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/anomalies/return-periodic-filter/${category}/${period}/${threshold}`
      );

      const parseTime = timeParse("%Y%m%d");

      res.data.data.data.forEach((obj) => {
        obj.dateList = obj.dateList.map((s) => parseTime(s));
      });

      dispatch({
        type: DATA_LOADED,
        payload: res.data,
        name: dataName,
      });
    } catch (error) {
      console.log("error");
      console.log(error.response);

      dispatch({
        type: DATA_ERROR,
        payload: error.response.data.error,
        name: dataName,
      });
    }
  };

export const getPeriodicCorrelationScannerData =
  (symbol, category, period) => async (dispatch) => {
    try {
      dispatch({
        type: IS_LOADING,
        name: "anomaliesPcs",
      });

      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/anomalies/correlation-periodic-scanner/${symbol}/${category}/${period}`
      );

      const parseTime = timeParse("%Y%m%d");

      res.data.data.data.forEach((obj) => {
        obj.dateList = obj.dateList.map((s) => parseTime(s));
      });

      dispatch({
        type: DATA_LOADED,
        payload: res.data,
        name: "anomaliesPcs",
      });
    } catch (error) {
      console.log("error");
      console.log(error.response);

      dispatch({
        type: DATA_ERROR,
        payload: error.response.data.error,
        name: "anomaliesPcs",
      });
    }
  };

export const getPeriodicCorrelationViewerData =
  (symbols, period) => async (dispatch) => {
    try {
      dispatch({
        type: IS_LOADING,
        name: "anomaliesPcv",
      });

      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/anomalies/correlation-periodic-viewer/${symbols}/${period}`
      );

      const parseTime = timeParse("%Y%m%d");

      res.data.data.dateList = res.data.data.dateList.map((s) => parseTime(s));

      dispatch({
        type: DATA_LOADED,
        payload: res.data,
        name: "anomaliesPcv",
      });
    } catch (error) {
      console.log("error");
      console.log(error.response);

      dispatch({
        type: DATA_ERROR,
        payload: error.response.data.error,
        name: "anomaliesPcv",
      });
    }
  };

export const getPeriodicSpreadScannerSingleData =
  (symbol, category, period) => async (dispatch) => {
    try {
      dispatch({
        type: IS_LOADING,
        name: "anomaliesPsss",
      });

      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/anomalies/spread-periodic-scanner-single/${symbol}/${category}/${period}`
      );

      const parseTime = timeParse("%Y%m%d");

      res.data.data.dateList = res.data.data.dateList.map((s) => parseTime(s));

      res.data.data.data.forEach((obj) => {
        obj.dateList = obj.dateList.map((s) => parseTime(s));
      });

      dispatch({
        type: DATA_LOADED,
        payload: res.data,
        name: "anomaliesPsss",
      });
    } catch (error) {
      console.log("error");
      console.log(error.response);

      dispatch({
        type: DATA_ERROR,
        payload: error.response.data.error,
        name: "anomaliesPsss",
      });
    }
  };

export const getPeriodicSpreadViewerData =
  (symbols, period) => async (dispatch) => {
    try {
      dispatch({
        type: IS_LOADING,
        name: "anomaliesPsv",
      });

      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/anomalies/spread-periodic-viewer/${symbols}/${period}`
      );

      const parseTime = timeParse("%Y%m%d");

      res.data.data.dateList = res.data.data.dateList.map((s) => parseTime(s));

      dispatch({
        type: DATA_LOADED,
        payload: res.data,
        name: "anomaliesPsv",
      });
    } catch (error) {
      console.log("error");
      console.log(error.response);

      dispatch({
        type: DATA_ERROR,
        payload: error.response.data.error,
        name: "anomaliesPsv",
      });
    }
  };
