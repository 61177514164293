import {
  SEASONALITY_VIEWER_ERROR,
  SEASONALITY_VIEWER_LOADING,
  SEASONALITY_VIEWER_SUCCESS,
} from "../types";

const initialState = {
  loading: false,
  data: null,
  error: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SEASONALITY_VIEWER_LOADING:
      return {
        loading: true,
        data: null,
        error: null,
      };
    case SEASONALITY_VIEWER_SUCCESS:
      return {
        loading: false,
        data: payload.data,
        error: null,
      };
    case SEASONALITY_VIEWER_ERROR:
      return {
        loading: false,
        data: null,
        error: payload,
      };
    default:
      return state;
  }
}
