import SYMBOL_DIRECTORY from './SYMBOL_DIRECTORY.json';

export function countStocksBySector(sectorSymbol) {
  return SYMBOL_DIRECTORY.stocks.filter(
    (obj) => obj.sectorSymbol === sectorSymbol
  ).length;
}

export function getReduxStates(loading, data, error, name) {
  let dataLoading = false;
  if (loading !== null) {
    if (loading[name] !== undefined) {
      dataLoading = loading[name];
    }
  }

  let dataObj = null;
  if (data.data !== null) {
    if (data.data[name] !== undefined) {
      dataObj = data.data[name];
    }
  }

  let dataError = null;
  if (error !== null) {
    if (error[name] !== undefined) {
      dataError = error[name];
    }
  }

  return [dataLoading, dataObj, dataError];
}
