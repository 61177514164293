import React from 'react';
import { Table } from 'antd';

import './AnomalyTradePerformanceTable.css';

const AnomalyTradePerformanceTable = ({ data }) => {
  console.log(data);

  const formatDecimals = (val, decimalCount) => {
    if (typeof val !== 'number') return 'N/A';

    return val.toFixed(decimalCount);
  };

  const dataKeys = [
    'p3',
    'p3b',
    'p2',
    'p2b',
    'p1',
    'p1b',
    'm1b',
    'm1',
    'm2b',
    'm2',
    'm3b',
    'm3',
  ];
  const dataSource = [];

  dataKeys.forEach((k) => {
    const tempObj = data.sigmaSections[k];
    tempObj['key'] = k.includes('p')
      ? k.replace('p', '+')
      : k.replace('m', '-');

    let sigmaRange;
    switch (k[0] + k[1]) {
      case 'p3':
        sigmaRange = 'Δr > 3σ';
        break;
      case 'p2':
        sigmaRange = '2σ < Δr ≤ 3σ';
        break;
      case 'p1':
        sigmaRange = '1σ < Δr ≤ 2σ';
        break;
      case 'm1':
        sigmaRange = '-2σ ≤ Δr < -1σ';
        break;
      case 'm2':
        sigmaRange = '-3σ ≤ Δr < -2σ';
        break;
      case 'm3':
        sigmaRange = 'Δr < -3σ';
        break;
      default:
        break;
    }

    tempObj['sigmaRange'] = sigmaRange;
    tempObj['justBreached'] = k.includes('b') ? 'yes' : 'no';
    tempObj['daysNextPeriod'] = data.tradePeriods.join('-||-');
    tempObj['tradeCountsStr'] = tempObj.tradeCounts.join('-||-');

    if (tempObj.occurrences > 0) {
      tempObj['daysMedianAvg'] = k.includes('b')
        ? '1 | 1±0'
        : `${tempObj.daysMedian} | ${tempObj.daysMean}±${tempObj.daysStdev}`;
      tempObj['returnMedianAvg'] = tempObj['median']
        .map(
          (d, i) =>
            `${typeof d === 'number' && d > 0 ? '+' : ''}` +
            formatDecimals(d, 1) +
            ' | ' +
            `${typeof tempObj.mean[i] && tempObj.mean[i] > 0 ? '+' : ''}` +
            formatDecimals(tempObj.mean[i], 1) +
            '±' +
            formatDecimals(tempObj.stdev[i], 1)
        )
        .join('-||-');
      tempObj['returnMinMax'] = tempObj.min
        .map(
          (d, i) =>
            `${typeof d === 'number' && d >= 0 ? '+' : ''}` +
            formatDecimals(d, 1) +
            ' | ' +
            `${
              typeof tempObj.max[i] === 'number' && tempObj.max[i] >= 0
                ? '+'
                : ''
            }` +
            formatDecimals(tempObj.max[i], 1)
        )
        .join('-||-');
      tempObj['probsReturn'] = tempObj.probGreaterOrEqualP10
        .map(
          (d, i) =>
            (d !== null ? formatDecimals(d * 100, 0) : 'N/A') +
            ' | ' +
            (tempObj.probGreaterOrEqualP5[i] !== null
              ? formatDecimals(tempObj.probGreaterOrEqualP5[i] * 100, 0)
              : 'N/A') +
            ' | ' +
            (tempObj.probGreaterOrEqual0[i] !== null
              ? formatDecimals(tempObj.probGreaterOrEqual0[i] * 100, 0)
              : 'N/A') +
            ' | ' +
            (tempObj.probGreaterOrEqualM5[i] !== null
              ? formatDecimals(tempObj.probGreaterOrEqualM5[i] * 100, 0)
              : 'N/A') +
            ' | ' +
            (tempObj.probGreaterOrEqualM10[i] !== null
              ? formatDecimals(tempObj.probGreaterOrEqualM10[i] * 100, 0)
              : 'N/A')
        )
        .join('-||-');
    } else {
      tempObj['daysMedianAvg'] = 'N/A';
      tempObj['returnMedianAvg'] = 'N/A';
      tempObj['returnMinMax'] = 'N/A';
      tempObj['probsReturn'] = 'N/A';
    }

    dataSource.push(tempObj);
  });

  const columns = [
    {
      title: 'Category',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: 'Sigma Range',
      dataIndex: 'sigmaRange',
      key: 'sigmaRange',
    },
    {
      title: 'Just Breached',
      dataIndex: 'justBreached',
      key: 'justBreached',
    },
    {
      title: 'Occurrences',
      dataIndex: 'occurrences',
      key: 'occurrences',
    },
    {
      title: 'Days Median|Avg',
      dataIndex: 'daysMedianAvg',
      key: 'daysMedianAvg',
    },
    {
      title: 'Trade Duration [d]',
      dataIndex: 'daysNextPeriod',
      key: 'daysNextPeriod',
      // width: '5%',
      render(text) {
        return {
          children: (
            <div className='cell direction-vertical'>
              {text.split('-||-').map((s) => (
                <span key={'daysNextPeriod' + s}>{s}</span>
              ))}
            </div>
          ),
        };
      },
    },
    {
      title: 'Total Trades',
      dataIndex: 'tradeCountsStr',
      key: 'tradeCountsStr',
      // width: '5%',
      render(text) {
        return {
          children: (
            <div className='cell direction-vertical'>
              {text.split('-||-').map((s, i) => (
                <span key={'tradeCountsStr' + i}>{s}</span>
              ))}
            </div>
          ),
        };
      },
    },
    {
      title: 'Return Median|Avg [%]',
      dataIndex: 'returnMedianAvg',
      key: 'returnMedianAvg',
      render(text) {
        return {
          children: (
            <div className='cell direction-vertical'>
              {text.split('-||-').map((s, idx) => (
                <span key={'returns' + idx}>{s}</span>
              ))}
            </div>
          ),
        };
      },
    },
    {
      title: 'Return Min|Max [%]',
      dataIndex: 'returnMinMax',
      key: 'returnMinMax',
      render(text) {
        return {
          children: (
            <div className='cell direction-vertical'>
              {text.split('-||-').map((s, idx) => (
                <span key={'returnsMinMax' + idx}>{s}</span>
              ))}
            </div>
          ),
        };
      },
    },
    {
      title: 'Probability for Return ≥ +10|+5|0|-5|-10 % [%]',
      dataIndex: 'probsReturn',
      key: 'probsReturn',
      render(text) {
        return {
          children: (
            <div className='cell direction-vertical'>
              {text.split('-||-').map((s, idx) => (
                <span key={'probsReturn' + idx}>{s}</span>
              ))}
            </div>
          ),
        };
      },
    },
  ];

  return (
    <div className='AnomalyTradePerformanceTable'>
      <Table
        className='AnomalyTradePerformanceTable-table'
        dataSource={dataSource}
        columns={columns}
        pagination={false}
      />
    </div>
  );
};

export default AnomalyTradePerformanceTable;
