import React, { useContext } from "react";
import { Layout } from "antd";

// Context
import { SeasonalityContext } from "../../contexts/SeasonalityContext";

// Own components
import SeasonalityHeadMenu from "./SeasonalityHeadMenu";
import SeasonalityFooter from "./SeasonalityFooter";

import SeasonalityViewerContent from "./SeasonalityViewer/SeasonalityViewerContent";

const Seasonality = () => {
  const { toolSelected } = useContext(SeasonalityContext);

  let content;
  switch (toolSelected) {
    // case 'prs':
    //   content = <PeriodicReturnScannerContent />;
    //   break;
    case "sv":
      content = <SeasonalityViewerContent />;
      break;
    default:
      content = null;
  }

  return (
    <Layout>
      <SeasonalityHeadMenu />

      {content}

      <SeasonalityFooter />
    </Layout>
  );
};

export default Seasonality;
