import axios from "axios";
import {
  SEASONALITY_VIEWER_LOADING,
  SEASONALITY_VIEWER_SUCCESS,
  SEASONALITY_VIEWER_ERROR,
} from "../types";

export const getSeasonalityViewerData = (symbol) => async (dispatch) => {
  try {
    dispatch({
      type: SEASONALITY_VIEWER_LOADING,
    });

    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/v1/seasonalities/${symbol}`
    );

    dispatch({
      type: SEASONALITY_VIEWER_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    console.log("error");
    console.log(error.response);

    dispatch({
      type: SEASONALITY_VIEWER_ERROR,
      payload: error.response.data,
    });
  }
};
