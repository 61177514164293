import * as d3 from "d3";
import tooltips from "./drawPrvReturnsVixOverTime-tooltips";

function drawPrvReturnsVixOverTimeChart(nodeId, data) {
  // STYLES  -----------------------------------------------------
  const colors = {
    axisTitle: "#5D5D5D",
    axisLabel: "#878787",
    axisLine: "#878787",
    errorBars: "#434343",
    blueMain: "#1890ff",
    blueDarker: "#126cbf",
    blueDarkest: "#002766",
    blueLightest: "#f5f5f5",
    red: "#ff181d",
    redLight: "#ffa8aa",
    redLabel: "#bf1216",
    green: "#4bc219",
    greenLight: "#bce8a9",
  };
  const fontStyles = {
    axisTitle: {
      fontSize: 12,
      fontWeight: 300,
    },
    axisLabel: {
      fontSize: 10,
      fontWeight: 300,
    },
  };

  // DIMENSIONS --------------------------------------------------
  // getting div container of chart and set dimensions
  const divChart = d3.select(`#${nodeId}`)._groups[0][0];
  const width = divChart.offsetWidth;
  const height = divChart.offsetHeight;

  // margins
  const margin = { top: 5, right: 15, bottom: 50, left: 70 };

  // creating main svg
  const svg = d3
    .select(`#${nodeId}`)
    .append("svg")
    .attr("width", width)
    .attr("height", height);
  // .style('border', '1px solid red');

  // defining chart height and width
  const widthChart = width - margin.left - margin.right;
  const heightChart = height - margin.top - margin.bottom;

  // defining height fractions for the 3 charts: return scatter, win percentage line, VIX line
  const chartSplit = [3, 2, 3];
  const chartSplitSum = chartSplit.reduce((prev, curr) => prev + curr, 0);
  const heightFractionReturnChart = chartSplit[0] / chartSplitSum;
  const heightFractionWinRateChart = chartSplit[1] / chartSplitSum;
  const heightFractionVixChart = chartSplit[2] / chartSplitSum;

  const heightReturnChart = heightChart * heightFractionReturnChart;
  const heightWinRateChart = heightChart * heightFractionWinRateChart;
  const heightVixChart = heightChart * heightFractionVixChart;

  const g = svg
    .append("g")
    .attr("transform", `translate(${margin.left}, ${margin.top})`);

  // MAIN LABELS ------------------------------------------------------------------
  // X AXIS TITLE
  g.append("text")
    .attr("class", "axis-title x")
    .attr("id", "title-days")
    .attr("x", widthChart / 2)
    .attr("y", heightChart + margin.bottom / 2 + 13)
    .attr("font-size", fontStyles.axisTitle.fontSize + "px")
    .attr("font-weight", fontStyles.axisTitle.fontWeight)
    .attr("fill", colors.axisTitle)
    .attr("text-anchor", "middle")
    .text("Date");
  // Y AXIS TITLES
  g.append("text")
    .attr("class", "axis-title y")
    .attr("id", "axis-title-returns")
    .attr("y", (-margin.left * 3) / 4)
    .attr("x", (-1 / 2) * heightReturnChart)
    .attr("font-size", fontStyles.axisTitle.fontSize + "px")
    .attr("font-weight", fontStyles.axisTitle.fontWeight)
    .attr("fill", colors.axisTitle)
    .attr("transform", "rotate(-90)")
    .attr("text-anchor", "middle")
    .text("Arithm. Return [%]");
  g.append("text")
    .attr("class", "axis-title y")
    .attr("id", "axis-title-win-rate")
    .attr("y", (-margin.left * 3) / 4)
    .attr("x", -heightReturnChart - (1 / 2) * heightWinRateChart)
    .attr("font-size", fontStyles.axisTitle.fontSize + "px")
    .attr("font-weight", fontStyles.axisTitle.fontWeight)
    .attr("fill", colors.axisTitle)
    .attr("transform", "rotate(-90)")
    .attr("text-anchor", "middle")
    .text("Win Rate [%]");
  g.append("text")
    .attr("class", "axis-title y")
    .attr("id", "axis-title-vix")
    .attr("y", (-margin.left * 3) / 4)
    .attr(
      "x",
      -heightReturnChart - heightWinRateChart - (1 / 2) * heightVixChart
    )
    .attr("font-size", fontStyles.axisTitle.fontSize + "px")
    .attr("font-weight", fontStyles.axisTitle.fontWeight)
    .attr("fill", colors.axisTitle)
    .attr("transform", "rotate(-90)")
    .attr("text-anchor", "middle")
    .text("Points");
  // CHART LABELS
  g.append("text")
    .attr("class", "chart-label")
    .attr("x", widthChart - 5)
    .attr("y", 10)
    .attr("font-size", "calc(0.5vw + 0.2rem)")
    .attr("font-weight", 400)
    .attr("fill", colors.axisLabel)
    .attr("opacity", 0.4)
    .attr("text-anchor", "end")
    .attr("letter-spacing", "0.25px")
    .text("TRADE RETURNS");
  g.append("text")
    .attr("class", "chart-label")
    .attr("x", widthChart - 5)
    .attr("y", heightReturnChart + 18)
    .attr("font-size", "calc(0.5vw + 0.2rem)")
    .attr("font-weight", 400)
    .attr("fill", colors.axisLabel)
    .attr("opacity", 0.4)
    .attr("text-anchor", "end")
    .attr("letter-spacing", "0.25px")
    .text("TRADE WIN RATE");
  g.append("text")
    .attr("class", "chart-label")
    .attr("x", widthChart - 5)
    .attr("y", heightReturnChart + heightWinRateChart + 18)
    .attr("font-size", "calc(0.5vw + 0.2rem)")
    .attr("font-weight", 400)
    .attr("fill", colors.axisLabel)
    .attr("opacity", 0.4)
    .attr("text-anchor", "end")
    .attr("letter-spacing", "0.25px")
    .text("SPX VOLATILITY");
  // VIX Chart Label
  g.append("text")
    .attr("class", "chart-label returns")
    .attr("x", widthChart / 2)
    .attr("y", heightReturnChart + heightWinRateChart + heightVixChart / 2 + 7)
    .attr("font-size", "calc(1vw + 1rem)")
    .attr("font-weight", 500)
    .attr("fill", "#bbb")
    .attr("opacity", 0.5)
    .attr("text-anchor", "middle")
    .text(`${data.vixData.symbol}`);
  g.append("text")
    .attr("class", "chart-label returns")
    .attr("x", widthChart / 2)
    .attr("y", heightReturnChart + heightWinRateChart + heightVixChart / 2 + 27)
    .attr("font-size", "calc(0.5vw + 0.3rem)")
    .attr("font-weight", 400)
    .attr("fill", "#bbb")
    .attr("opacity", 0.5)
    .attr("text-anchor", "middle")
    .attr("letter-spacing", "0.25px")
    .text(`${data.vixData.name.toUpperCase()}`);

  // SCALES --------------------------------------------------------------------
  // X DATE
  const xScale = d3.scaleTime().range([0, widthChart]);

  // Y RETURN
  const yScaleReturn = d3.scaleLinear().range([heightReturnChart, 0]);

  // Y WIN RATE
  const yScaleWinRate = d3
    .scaleLinear()
    .range([heightReturnChart + heightWinRateChart, heightReturnChart])
    .domain([-30, 130]);

  // Y VIX
  const vixMinMax = d3.extent(data.vixData.data.adjClose);
  const yScaleVix = d3
    .scaleLinear()
    .range([heightChart, heightReturnChart + heightWinRateChart]);

  // AXES ----------------------------------------------------------------------
  // X AXES - DEFINITION
  const xAxisReturnCall = d3
    .axisBottom()
    .tickFormat("")
    .tickSizeInner(4)
    .tickSizeOuter(0);
  const xAxisReturn = g
    .append("g")
    .attr("class", "x axis adjusted-tick-position return-chart")
    .attr("transform", `translate(0, ${heightReturnChart})`);
  const xAxisWinRateCall = d3
    .axisBottom()
    .tickFormat("")
    .tickSizeInner(4)
    .tickSizeOuter(0);
  const xAxisWinRate = g
    .append("g")
    .attr("class", "x axis adjusted-tick-position win-rate-chart")
    .attr(
      "transform",
      `translate(0, ${heightReturnChart + heightWinRateChart})`
    );
  const xAxisVixCall = d3.axisBottom().tickSizeOuter(0);
  const xAxisVix = g
    .append("g")
    .attr("class", "x axis vix-chart")
    .attr("transform", `translate(0, ${heightChart})`);

  // Y AXES - DEFINITION
  const yAxisLeftReturnCall = d3.axisLeft().ticks(7).tickSizeOuter(0);
  const yAxisLeftReturn = g.append("g").attr("class", "y axis return");
  const yAxisLeftWinRateCall = d3
    .axisLeft()
    .scale(yScaleWinRate)
    .tickValues([0, 25, 50, 75, 100])
    .tickSizeOuter(0);
  const yAxisLeftWinRate = g
    .append("g")
    .attr("class", "y axis win-rate")
    .call(yAxisLeftWinRateCall);
  const yAxisLeftVixCall = d3.axisLeft().tickSizeOuter(0).ticks(5);
  const yAxisLeftVix = g.append("g").attr("class", "y axis vix");

  // SETTING INITIAL ELEMENTS FOR RETURN CHART ----------------------------------------------------------
  // VARIABLE FOR RETURNS IN CHART TO BE USED BY TOOLTIPS AS WELL
  let dataReturnsDates;
  // // RETURN MA LINE
  const returnMaLine = g
    .append("path")
    .attr("class", "line-return-ma")
    .attr("stroke", colors.axisTitle)
    .attr("stroke-width", 1)
    .attr("fill", "none");
  const returnMaLineLastToChartEnd = g
    .append("line")
    .attr("class", "line-return-ma-last-to-chart-end")
    .attr("stroke", colors.axisTitle)
    .attr("stroke-width", 1)
    .attr("fill", "none");
  // // LABEL - TITEL
  g.append("text")
    .attr("class", "return-chart-titel-text")
    .attr("x", widthChart / 2)
    .attr("y", 9)
    .attr("font-size", fontStyles.axisLabel.fontSize + "px")
    .attr("font-weight", fontStyles.axisLabel.fontWeight)
    .attr("text-anchor", "middle")
    .attr("fill", colors.axisLabel);
  // // LABEL - TRADES
  g.append("text")
    .attr("class", "return-chart-trades-text")
    .attr("x", 5)
    .attr("y", 9)
    .attr("font-size", fontStyles.axisLabel.fontSize + "px")
    .attr("font-weight", fontStyles.axisLabel.fontWeight)
    .attr("fill", colors.axisLabel);
  // // Label - WINS
  g.append("text")
    .attr("class", "return-chart-wins-text")
    .attr("x", 60)
    .attr("y", 9)
    .attr("font-size", fontStyles.axisLabel.fontSize + "px")
    .attr("font-weight", fontStyles.axisLabel.fontWeight)
    .attr("fill", colors.axisLabel);
  // // UTILITY RECT - WIN THRESHOLD
  g.append("rect")
    .attr("class", "return-chart-win-threshold-rect")
    .attr("x", 0)
    .attr("width", widthChart)
    .attr("fill", colors.axisLabel)
    .attr("opacity", 0.05)
    .attr("visibility", "hidden");
  // // UTILITY LINE - ZERO
  g.append("line")
    .attr("class", "return-chart-zero-line")
    .attr("x1", 0)
    .attr("x2", widthChart)
    .attr("stroke", colors.axisLabel)
    .attr("stroke-width", 1)
    .attr("stroke-dasharray", "1,1")
    .attr("visibility", "hidden");
  // // UTILITY LINE - WIN THRESHOLD
  const winThresholdLineLeft = g
    .append("line")
    .attr("class", "return-chart-win-threshold-line")
    .attr("x1", 0)
    .attr("x2", widthChart * 0.382 - 35)
    .attr("stroke", colors.blueDarkest)
    .attr("stroke-width", 1)
    .attr("visibility", "hidden");
  const winThresholdLineRight = g
    .append("line")
    .attr("class", "return-chart-win-threshold-line")
    .attr("x1", widthChart * 0.382 + 35)
    .attr("x2", widthChart)
    .attr("stroke", colors.blueDarkest)
    .attr("stroke-width", 1)
    .attr("visibility", "hidden");
  // UTILITY LABEL - WIN THRESHOLD LINE
  const winThresholdLineLabelRect = g
    .append("rect")
    .attr("class", "return-chart-win-threshold-label-rect")
    .attr("x", widthChart * 0.382 - 35)
    .attr("width", 70)
    .attr("height", 14)
    .attr("fill", "rgba(255,255,255,0.5")
    .attr("stroke", colors.blueDarkest)
    .attr("rx", 1)
    .attr("visibility", "hidden");
  const winThresholdLineLabelText = g
    .append("text")
    .attr("class", "return-chart-win-threshold-label-text")
    .attr("x", widthChart * 0.382)
    .attr("fill", colors.blueDarkest)
    .attr("font-size", fontStyles.axisLabel.fontSize + "px")
    .attr("font-weight", fontStyles.axisLabel.fontWeight)
    .attr("text-anchor", "middle")
    .attr("visibility", "hidden")
    .text("Win Threshold");
  // // Label - RETURN MA LINE
  const returnMaLineLabel = g
    .append("text")
    .attr("class", "return-chart-wins-text")
    .attr("x", 5)
    .attr("y", heightReturnChart - 6)
    .attr("font-size", fontStyles.axisLabel.fontSize + "px")
    .attr("font-weight", fontStyles.axisLabel.fontWeight)
    .attr("fill", colors.axisTitle);
  // SETTING INITIAL ELEMENTS FOR WIN RATE CHART ----------------------------------------------------------
  // VARIABLE FOR WIN RATES IN CHART TO BE USED BY TOOLTIPS AS WELL
  let winRates, winRatesMaBinaries;
  // // UTILITY LINE - 50
  g.append("line")
    .attr("class", "win-rate-chart-50-line")
    .attr("x1", 0)
    .attr("x2", widthChart)
    .attr("y1", yScaleWinRate(50))
    .attr("y2", yScaleWinRate(50))
    .attr("stroke", colors.axisLabel)
    .attr("stroke-width", 1)
    .attr("stroke-dasharray", "1,1");
  // // WIN RATE LINE
  const winRateLine = g
    .append("path")
    .attr("class", "line-win-rate")
    .attr("stroke", colors.blueDarker)
    .attr("stroke-width", 1)
    .attr("fill", "none");
  // // WIN RATE LINE FOR LAST DATAPOINT TO END OF CHART
  const winRateLineLastToChartEnd = g
    .append("line")
    .attr("class", "line-win-rate-last-to-chart-end")
    .attr("stroke", colors.blueDarker)
    .attr("stroke-width", 1)
    .attr("fill", "none");
  // // WIN RATE MA LINE
  const winRateLineMa = g
    .append("path")
    .attr("class", "line-win-rate-ma")
    .attr("stroke", colors.axisTitle)
    .attr("stroke-width", 1)
    .attr("fill", "none");
  // // WIN RATE MA LINE FOR LAST DATAPOINT TO END OF CHART
  const winRateLineMaLastToChartEnd = g
    .append("line")
    .attr("class", "line-win-rate-ma-last-to-chart-end")
    .attr("stroke", colors.axisTitle)
    .attr("stroke-width", 1)
    .attr("fill", "none");
  // // LAST WIN-RATE - CIRCLE
  g.append("circle")
    .attr("class", "win-rate-last-circle")
    .attr("r", 3)
    .attr("stroke", colors.redLabel)
    .attr("stroke-width", 1.2)
    .attr("fill", "transparent");
  // // LAST WIN-RATE - LABEL-LINE
  g.append("line")
    .attr("class", "win-rate-last-label-line")
    .attr("x1", 0)
    .attr("x2", 3)
    .attr("stroke", colors.redLabel)
    .attr("stroke-width", 1)
    .attr("opacity", 0.75);
  // // LAST WIN-RATE - LABEL-RECT
  g.append("rect")
    .attr("class", "win-rate-last-label-rect")
    .attr("width", 40)
    .attr("height", 14)
    .attr("x", 3)
    .attr("fill", colors.redLabel)
    .attr("opacity", 0.75);
  // // LAST WIN-RATE - LABEL-TEXT
  g.append("text")
    .attr("class", "win-rate-last-label-text")
    .attr("x", 3 + 20)
    .attr("text-anchor", "middle")
    .attr("font-size", fontStyles.axisLabel.fontSize)
    .attr("font-weight", fontStyles.axisLabel.fontWeight)
    .attr("fill", "#fff");
  // // LABEL - WIN RATE LINE
  const winRateLineLabel = g
    .append("text")
    .attr("class", "return-chart-trades-text")
    .attr("x", 5)
    .attr("y", heightReturnChart + heightWinRateChart - 6)
    .attr("font-size", fontStyles.axisLabel.fontSize + "px")
    .attr("font-weight", fontStyles.axisLabel.fontWeight)
    .attr("fill", colors.blueDarker)
    .text("Expanding Average");
  // // Label - WIN RATE MA LINE
  const winRateMaLineLabel = g
    .append("text")
    .attr("class", "return-chart-wins-text")
    .attr("x", 98)
    .attr("y", heightReturnChart + heightWinRateChart - 6)
    .attr("font-size", fontStyles.axisLabel.fontSize + "px")
    .attr("font-weight", fontStyles.axisLabel.fontWeight)
    .attr("fill", colors.axisTitle);
  // SETTING INITIAL ELEMENTS FOR VIX CHART -------------------------------------------------------------
  // VARIABLE FOR VIX DATES IN CHART TO BE USED BY TOOLTIPS AS WELL
  let dataVixDatesChart, dataVixValuesChart;
  // // VIX LINE
  g.append("path")
    .attr("class", "line-vix")
    .attr("stroke", colors.blueDarkest)
    .attr("stroke-width", 1)
    .attr("fill", "none");
  // // LAST VIX VALUE - CIRCLE
  g.append("circle")
    .attr("class", "vix-last-circle")
    .attr("r", 3)
    .attr("stroke", colors.redLabel)
    .attr("stroke-width", 1.2)
    .attr("fill", "transparent");
  // // LAST VIX VALUE - LABEL-LINE
  g.append("line")
    .attr("class", "vix-last-label-line")
    .attr("x1", 0)
    .attr("x2", 3)
    .attr("stroke", colors.redLabel)
    .attr("stroke-width", 1)
    .attr("opacity", 0.75);
  // // LAST VIX VALUE - LABEL-RECT
  g.append("rect")
    .attr("class", "vix-last-label-rect")
    .attr("width", 40)
    .attr("height", 14)
    .attr("x", 3)
    .attr("fill", colors.redLabel)
    .attr("opacity", 0.75);
  // // LAST VIX VALUE - LABEL-TEXT
  g.append("text")
    .attr("class", "vix-last-label-text")
    .attr("x", 3 + 20)
    .attr("text-anchor", "middle")
    .attr("font-size", fontStyles.axisLabel.fontSize)
    .attr("font-weight", fontStyles.axisLabel.fontWeight)
    .attr("fill", "#fff");

  // ===================================================================================================
  // ======================================= MAIN UPDATE FUNCTION ======================================
  const updateChart = () => {
    // TRANSITION --------------------------------------------------------------------------------------
    const t = d3.transition().duration(250).ease(d3.easeLinear);

    // PARAMETER INPUT VALUES --------------------------------------------------------------------------
    const inputTradeDuration = parseInt(
      d3.select(
        ".PrvActionableAnomalyEvent-parameters-trade-periods-buttons .ant-radio-button-checked input"
      )._groups[0][0].value
    );

    let inputWinThreshold;
    try {
      inputWinThreshold = parseFloat(
        d3.select(".PrvActionableAnomalyEvent-parameters-win-threshold input")
          ._groups[0][0].value
      );
    } catch (error) {
      inputWinThreshold = 0;
    } finally {
      if (isNaN(inputWinThreshold)) {
        inputWinThreshold = 0;
      }
    }

    const elInputWinRateMaTrades = d3.select(
      ".PrvActionableAnomalyEvent-parameters-win-rate-ma-buttons .ant-radio-button-checked input"
    )._groups[0][0];
    const inputWinRateMaTrades = elInputWinRateMaTrades.disabled
      ? 0
      : parseInt(elInputWinRateMaTrades.value);

    // DATA PREPARATION --------------------------------------------------------------------------
    // TRADE PERIOD IDX
    const idxTradePeriod = data.tradePeriods.findIndex(
      (el) => el === inputTradeDuration
    );

    // RETURN DATA
    const dataReturnsValues = data.currentAnomalyData.returns[idxTradePeriod];
    dataReturnsDates = data.currentAnomalyData.startDates;

    // // correcting for unfinished trades
    if (dataReturnsDates.length > dataReturnsValues.length) {
      dataReturnsDates = dataReturnsDates.slice(0, dataReturnsValues.length);
    }

    // // RETURN MA
    let returnMaValues = [];
    if (inputWinRateMaTrades !== 0) {
      // initial first return MA value that can be calculated
      let movingSum = dataReturnsValues
        .slice(0, inputWinRateMaTrades)
        .reduce((a, b) => a + b, 0);
      returnMaValues.push(movingSum / inputWinRateMaTrades);

      // looping over remaining return values
      for (let i = inputWinRateMaTrades; i < dataReturnsValues.length; i++) {
        movingSum =
          movingSum +
          dataReturnsValues[i] -
          dataReturnsValues[i - inputWinRateMaTrades];
        returnMaValues.push(movingSum / inputWinRateMaTrades);
      }
    }

    // WIN RATE & WIN RATE MA
    let counterReturns = 0;
    let wins = 0;
    winRates = [];
    winRatesMaBinaries = [];

    for (let i = 0; i < dataReturnsValues.length; i++) {
      counterReturns += 1;
      if (dataReturnsValues[i] >= inputWinThreshold) {
        wins += 1;
        winRatesMaBinaries.push(1);
      } else {
        winRatesMaBinaries.push(0);
      }
      winRates.push((wins / counterReturns) * 100);
    }

    // WIN RATE MOVING AVERAGE
    let winRateMaValues = [];
    if (inputWinRateMaTrades !== 0) {
      // initial first win rate MA value that can be calculated
      let movingSum = winRatesMaBinaries
        .slice(0, inputWinRateMaTrades)
        .reduce((a, b) => a + b, 0);
      winRateMaValues.push((movingSum / inputWinRateMaTrades) * 100);

      // looping over remaining win rate values
      for (let i = inputWinRateMaTrades; i < winRatesMaBinaries.length; i++) {
        movingSum =
          movingSum +
          winRatesMaBinaries[i] -
          winRatesMaBinaries[i - inputWinRateMaTrades];
        winRateMaValues.push((movingSum / inputWinRateMaTrades) * 100);
      }
    }

    // VIX data
    const dataVixDates = data.vixData.data.date;
    const dataVixValues = data.vixData.data.adjClose;

    // UPDATING SCALES ------------------------------------------------------------------------
    // xScale
    const maxDateVix = dataVixDates[0];
    const minDateVix = dataVixDates[dataVixDates.length - 1];
    const minDateReturns = dataReturnsDates[0];

    const minDate = minDateVix < minDateReturns ? minDateReturns : minDateVix;
    const minDateChart = new Date(
      new Date(minDate).setDate(minDate.getDate() - 30)
    );

    const maxDateReturns = dataReturnsDates[dataReturnsDates.length - 1];
    const maxDateChart = new Date(
      new Date(maxDateReturns).setDate(maxDateReturns.getDate() + 10)
    );

    xScale.domain([minDateChart, maxDateChart]);

    // yScaleReturn
    const yScaleReturnMin = data.currentAnomalyData.min[idxTradePeriod];
    const yScaleReturnMax = data.currentAnomalyData.max[idxTradePeriod];
    const yScaleReturnPadding = (yScaleReturnMax - yScaleReturnMin) * 0.1;
    yScaleReturn.domain([
      yScaleReturnMin - yScaleReturnPadding,
      yScaleReturnMax + yScaleReturnPadding,
    ]);

    // yScaleVix
    const vixDataStartIdx =
      minDateChart > minDateVix
        ? dataVixDates.findIndex((d) => d <= minDateChart) - 1
        : dataVixDates.length - 1;
    const vixDataEndIdx =
      maxDateChart > maxDateVix
        ? maxDateChart
        : dataVixDates.findIndex((d) => d <= maxDateChart) - 1;
    dataVixDatesChart = dataVixDates.slice(vixDataEndIdx, vixDataStartIdx + 1);
    dataVixValuesChart = dataVixValues.slice(
      vixDataEndIdx,
      vixDataStartIdx + 1
    );

    const vixMax = d3.max(dataVixValuesChart);
    yScaleVix.domain([0, vixMax * 1.15]);

    // UPDATING AXES ----------------------------------------------------------------------------
    // AXES CALLS
    xAxisReturnCall.scale(xScale);
    xAxisReturn.transition(t).call(xAxisReturnCall);
    xAxisWinRateCall.scale(xScale);
    xAxisWinRate.transition(t).call(xAxisWinRateCall);
    xAxisVixCall.scale(xScale);
    xAxisVix.transition(t).call(xAxisVixCall);

    yAxisLeftReturnCall.scale(yScaleReturn);
    yAxisLeftReturn.transition(t).call(yAxisLeftReturnCall);

    yAxisLeftVixCall.scale(yScaleVix);
    yAxisLeftVix.transition(t).call(yAxisLeftVixCall);

    // AXES STYLING
    d3.selectAll(`#${nodeId} .axis path`).attr("stroke", colors.axisLine);
    d3.selectAll(`#${nodeId} .axis line`).attr("stroke", colors.axisLine);
    d3.selectAll(`#${nodeId} .axis text`)
      .attr("fill", colors.axisLabel)
      .attr("font-size", fontStyles.axisLabel.fontSize);
    d3.selectAll(`#${nodeId} .x.axis.adjusted-tick-position line`).attr(
      "y1",
      -3
    );

    // RETURN CHART ---------------------------------------------------------------------------------
    // LABEL - TITEL
    g.select(".return-chart-titel-text").text(
      `${inputTradeDuration}d Trades with Return of ${data.currentAnomalyData.mean[
        idxTradePeriod
      ].toFixed(2)} ± ${data.currentAnomalyData.stdev[idxTradePeriod].toFixed(
        2
      )} %`
    );
    // LABEL - TRADES
    g.select(".return-chart-trades-text").text(`Trades: ${counterReturns}`);
    // LABEL - WINS
    g.select(".return-chart-wins-text").text(`Wins: ${wins}`);
    // UTILITY LINE - ZERO
    if (yScaleReturnMin < 0 && yScaleReturnMax > 0) {
      g.select(".return-chart-zero-line")
        .transition(t)
        .attr("y1", yScaleReturn(0))
        .attr("y2", yScaleReturn(0))
        .attr("visibility", "visible");
    } else {
      g.select(".return-chart-zero-line")
        .transition(t)
        .attr("visibility", "hidden");
    }
    // RETURN MA LINE
    const lineGeneratorReturn = d3
      .line()
      .x((d) => xScale(d))
      .y((d, i) => yScaleReturn(returnMaValues[i]))
      .curve(d3.curveStepAfter);
    if (returnMaValues.length > 0 && inputWinRateMaTrades !== 0) {
      const returnMaLast = returnMaValues[returnMaValues.length - 1];
      // // Return MA LINE
      returnMaLine
        .transition(t)
        .attr(
          "d",
          lineGeneratorReturn(dataReturnsDates.slice(inputWinRateMaTrades - 1))
        )
        .attr("visibility", "visible");
      // LINE LAST DATAPOINT TO END OF CHART
      returnMaLineLastToChartEnd
        .transition(t)
        .attr("x1", xScale(maxDateReturns))
        .attr("x2", widthChart)
        .attr("y1", yScaleReturn(returnMaLast))
        .attr("y2", yScaleReturn(returnMaLast));
      returnMaLineLabel
        .text(`Moving Average(${inputWinRateMaTrades})`)
        .attr("visibility", "visible");
    } else {
      returnMaLine.attr("visibility", "hidden");
      returnMaLineLastToChartEnd.attr("visibility", "hidden");
      returnMaLineLabel.attr("visibility", "hidden");
    }
    // UTILITY LINE - WIN TRHESHOLD
    if (
      yScaleReturn.domain()[0] < inputWinThreshold &&
      yScaleReturn.domain()[1] > inputWinThreshold
    ) {
      winThresholdLineLeft
        .transition(t)
        .attr("y1", yScaleReturn(inputWinThreshold))
        .attr("y2", yScaleReturn(inputWinThreshold))
        .attr("visibility", "visible");
      winThresholdLineRight
        .transition(t)
        .attr("y1", yScaleReturn(inputWinThreshold))
        .attr("y2", yScaleReturn(inputWinThreshold))
        .attr("visibility", "visible");
      g.select(".return-chart-win-threshold-rect")
        .transition(t)
        .attr("y", yScaleReturn(inputWinThreshold))
        .attr("height", heightReturnChart - yScaleReturn(inputWinThreshold))
        .attr("visibility", "visible");
      winThresholdLineLabelRect
        .transition(t)
        .attr("y", yScaleReturn(inputWinThreshold) - 7)
        .attr("visibility", "visible");
      winThresholdLineLabelText
        .transition(t)
        .attr("y", yScaleReturn(inputWinThreshold) + 3.5)
        .attr("visibility", "visible");
    } else {
      g.select(".return-chart-win-threshold-rect")
        .transition(t)
        .attr("visibility", "hidden");
      winThresholdLineLeft.transition(t).attr("visibility", "hidden");
      winThresholdLineRight.transition(t).attr("visibility", "hidden");
      winThresholdLineLabelRect.transition(t).attr("visibility", "hidden");
      winThresholdLineLabelText.transition(t).attr("visibility", "hidden");
    }

    // DATAPOINTS
    let datapoints = g.selectAll(".return-chart-circle").data(dataReturnsDates);
    datapoints.exit().remove();
    datapoints
      .enter()
      .append("circle")
      .attr("class", "return-chart-circle")
      .attr("r", 1.5)
      .merge(datapoints)
      .transition(t)
      .attr("cx", (d) => xScale(d))
      .attr("cy", (d, i) => yScaleReturn(dataReturnsValues[i]))
      .attr("fill", (d, i) =>
        dataReturnsValues[i] >= inputWinThreshold ? colors.green : colors.red
      );

    // WIN RATE CHART ---------------------------------------------------------------------------------
    // LAST WIN RATE VALUE
    const winRateLast = winRates[winRates.length - 1];
    // LINE
    const lineGeneratorWinRate = d3
      .line()
      .x((d) => xScale(d))
      .y((d, i) => yScaleWinRate(winRates[i]))
      .curve(d3.curveStepAfter);

    winRateLine.transition(t).attr("d", lineGeneratorWinRate(dataReturnsDates));
    // LINE LAST DATAPOINT TO END OF CHART
    winRateLineLastToChartEnd
      .transition(t)
      .attr("x1", xScale(maxDateReturns))
      .attr("x2", widthChart)
      .attr("y1", yScaleWinRate(winRateLast))
      .attr("y2", yScaleWinRate(winRateLast));
    // // LAST WIN RATE VALUE - CIRCLE
    g.select(".win-rate-last-circle")
      .transition(t)
      .attr("cx", widthChart - 1)
      .attr("cy", yScaleWinRate(winRateLast));
    // // LAST WIN RATE VALUE - LABEL-LINE
    g.select(".win-rate-last-label-line")
      .transition(t)
      .attr("y1", yScaleWinRate(winRateLast))
      .attr("y2", yScaleWinRate(winRateLast));
    // // LAST WIN RATE VALUE - LABEL-RECT
    g.select(".win-rate-last-label-rect")
      .transition(t)
      .attr("y", yScaleWinRate(winRateLast) - 7);
    // // LAST WIN RATE VALUE - LABEL-TEXT
    g.select(".win-rate-last-label-text")
      .transition(t)
      .attr("y", yScaleWinRate(winRateLast) + 3.5)
      .text(winRateLast.toFixed(2));
    if (winRateMaValues.length > 0 && inputWinRateMaTrades !== 0) {
      lineGeneratorWinRate.y((d, i) => yScaleWinRate(winRateMaValues[i]));
      const winRateMaLast = winRateMaValues[winRateMaValues.length - 1];
      // // WIN RATE MA LINE
      winRateLineMa
        .transition(t)
        .attr(
          "d",
          lineGeneratorWinRate(dataReturnsDates.slice(inputWinRateMaTrades - 1))
        )
        .attr("visibility", "visible");
      // LINE LAST DATAPOINT TO END OF CHART
      winRateLineMaLastToChartEnd
        .transition(t)
        .attr("x1", xScale(maxDateReturns))
        .attr("x2", widthChart)
        .attr("y1", yScaleWinRate(winRateMaLast))
        .attr("y2", yScaleWinRate(winRateMaLast))
        .attr("visibility", "visible");
      winRateMaLineLabel
        .attr("visibility", "visible")
        .text(`Moving Average(${inputWinRateMaTrades})`);
    } else {
      winRateLineMa.attr("visibility", "hidden");
      winRateLineMaLastToChartEnd.attr("visibility", "hidden");
      winRateMaLineLabel.attr("visibility", "hidden");
    }
    winRateLine.raise();
    winRateLineLastToChartEnd.raise();
    // VIX CHART ---------------------------------------------------------------------------------
    // // LINE
    const lineGeneratorVix = d3
      .line()
      .x((d) => xScale(d))
      .y((d, i) => yScaleVix(dataVixValuesChart[i]));

    g.select(".line-vix")
      .transition(t)
      .attr("d", lineGeneratorVix(dataVixDatesChart));

    // // LAST VIX VALUE - CIRCLE
    g.select(".vix-last-circle")
      .transition(t)
      .attr("cx", xScale(dataVixDatesChart[0]))
      .attr("cy", yScaleVix(dataVixValuesChart[0]));
    // // LAST VIX VALUE - LABEL-LINE
    g.select(".vix-last-label-line")
      .transition(t)
      .attr("y1", yScaleVix(dataVixValuesChart[0]))
      .attr("y2", yScaleVix(dataVixValuesChart[0]));
    // // LAST VIX VALUE - LABEL-RECT
    g.select(".vix-last-label-rect")
      .transition(t)
      .attr("y", yScaleVix(dataVixValuesChart[0]) - 7);
    // // LAST VIX VALUE - LABEL-TEXT
    g.select(".vix-last-label-text")
      .transition(t)
      .attr("y", yScaleVix(dataVixValuesChart[0]) + 3.5)
      .text(dataVixValuesChart[0].toFixed(2));
    tooltips(
      nodeId,
      g,
      dataVixDatesChart,
      dataVixValuesChart,
      winRates,
      inputWinRateMaTrades,
      winRateMaValues,
      dataReturnsDates,
      returnMaValues,
      widthChart,
      heightChart,
      heightReturnChart,
      xScale,
      yScaleReturn,
      yScaleWinRate,
      yScaleVix
    );
  };
  updateChart();

  // EVENT LISTENER ------------------------------------------------------------------------------------
  // RADIO BUTTONS - TRADE DURATION
  d3.selectAll(
    ".PrvActionableAnomalyEvent-parameters-trade-periods-buttons input"
  ).on("change", updateChart);
  // NUMBER INPUT
  d3.selectAll(
    ".PrvActionableAnomalyEvent-parameters-win-threshold .ant-input-number-handler"
  ).on("click", updateChart);
  d3.selectAll(
    ".PrvActionableAnomalyEvent-parameters-win-threshold .ant-input-number-input"
  ).on("keyup", updateChart);
  // RADIO BUTTONS - WIN RATE MA
  d3.selectAll(
    ".PrvActionableAnomalyEvent-parameters-win-rate-ma-buttons input"
  ).on("change", updateChart);
}

export default drawPrvReturnsVixOverTimeChart;
