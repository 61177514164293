export const subMenuEntries = [
  {
    name: "Market Views",
    active: true,
    urlIntern: false,
    urlPath: "https://www.youtube.com/channel/UCafNb3dwvwiCimhVvBY3nHQ",
    urlPathNotAuthenticated:
      "https://www.youtube.com/channel/UCafNb3dwvwiCimhVvBY3nHQ",
  },
  {
    name: "Anomalies",
    active: true,
    urlIntern: true,
    urlPath: "/anomalies",
    urlPathNotAuthenticated: "/main/login",
  },
  {
    name: "Volatility",
    active: true,
    urlIntern: false,
    urlPath: "https://ttb-option-flow.netlify.app/optionflow-etf",
    urlPathNotAuthenticated: "/main/login",
  },
  {
    name: "Flows",
    active: true,
    urlIntern: false,
    urlPath: "https://ttb-option-flow.netlify.app/optionflow-etf",
    urlPathNotAuthenticated: "/main/login",
  },
  {
    name: "Gamma",
    active: true,
    urlIntern: false,
    urlPath: "https://ttb-option-flow.netlify.app/optionflow-etf",
    urlPathNotAuthenticated: "/main/login",
  },
  {
    name: "Spreads",
    active: false,
    // urlIntern: true,
    urlIntern: false,
    // urlPath: '/spreads',
    urlPath: "https://ttbspreadtrader.netlify.app/",
    urlPathNotAuthenticated: "/main/login",
  },
  {
    name: "Seasonality",
    active: true,
    urlIntern: true,
    urlPath: "/seasonality",
    urlPathNotAuthenticated: "/main/login",
  },
  {
    name: "Portfolio",
    active: false,
    urlIntern: true,
    urlPath: "/tools/portfolio",
    urlPathNotAuthenticated: "/main/login",
  },
  {
    name: "Timing",
    active: false,
    urlIntern: true,
    urlPath: "/timing",
    urlPathNotAuthenticated: "/main/login",
  },
  {
    name: "Economy",
    active: false,
    urlIntern: true,
    urlPath: "/tools/economy",
    urlPathNotAuthenticated: "/main/login",
  },
  {
    name: "Sectors",
    active: false,
    urlIntern: true,
    urlPath: "/tools/sectors",
    urlPathNotAuthenticated: "/main/login",
  },
  {
    name: "Calendar",
    active: false,
    urlIntern: true,
    urlPath: "/tools/calendar",
    urlPathNotAuthenticated: "/main/login",
  },
  {
    name: "COT",
    active: false,
    urlIntern: true,
    urlPath: "/cot",
    urlPathNotAuthenticated: "/main/login",
  },
];
