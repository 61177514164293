import * as d3 from "d3";

const tooltips = (
  baseClass,
  gChart,
  category,
  symbol,
  dataLineChart,
  widthChart,
  heightChart,
  heightChartReturn,
  heightChartStDev,
  xScale,
  yScaleReturn,
  yScaleStDev,
  yScaleClose
) => {
  const dataReversed = [...dataLineChart].reverse();

  const formatTime = d3.timeFormat("%Y-%m-%d");
  const bisectDate = d3.bisector((d) => d.date).left;

  const focus = gChart
    .append("g")
    .attr("class", `${baseClass} focus`)
    .attr("display", "none");

  // Hover line
  focus
    .append("line")
    .attr("class", "x-hover-line hover-line")
    .attr("y1", 0)
    .attr("y2", heightChart + 10)
    .attr("stroke", "#d3d3d3")
    .attr("stroke-width", 0.75);

  // Hover Date Info
  focus
    .append("rect")
    .attr("class", "date-hover-rect hover-rect")
    .attr("width", 64)
    .attr("height", 14)
    .attr("y", heightChart + 5)
    .attr("x", -32)
    .attr("ry", 1)
    .attr("rx", 1)
    .attr("fill", "#fff")
    .attr("stroke", "#333")
    .attr("stroke-width", "0.5")
    .attr("opacity", 0.8);
  focus
    .append("text")
    .attr("class", "date-hover-text hover-text")
    .attr("y", heightChart + 16)
    .attr("text-anchor", "middle")
    .attr("font-size", "10px")
    .attr("fill", "#656565");

  // Hover Return Info
  focus
    .append("rect")
    .attr("class", "return-hover-rect hover-rect chart-hover-rect");
  focus
    .append("text")
    .attr("class", "return-hover-text hover-text chart-hover-text");

  // Hover StDev Info
  focus
    .append("rect")
    .attr("class", "stdev-hover-rect hover-rect chart-hover-rect");
  focus
    .append("text")
    .attr("class", "stdev-hover-text hover-text chart-hover-text");

  // Hover close Info
  focus
    .append("rect")
    .attr("class", "close-hover-rect hover-rect chart-hover-rect");
  focus
    .append("text")
    .attr("class", "close-hover-text hover-text chart-hover-text");

  focus
    .selectAll(".chart-hover-rect")
    .attr("width", 56)
    .attr("height", 14)
    .attr("y", -16)
    .attr("x", 6)
    .attr("ry", 1)
    .attr("rx", 1)
    .attr("fill", "#333")
    .attr("opacity", 0.95);
  focus
    .selectAll(".chart-hover-text")
    .attr("y", -5)
    .attr("x", 34)
    .attr("text-anchor", "middle")
    .attr("font-size", "10px")
    .attr("fill", "#fff");

  // Hover circles
  focus.append("circle").attr("class", "return-hover-circle hover-circle");
  focus.append("circle").attr("class", "stdev-hover-circle hover-circle");
  focus.append("circle").attr("class", "close-hover-circle hover-circle");
  focus
    .selectAll(".hover-circle")
    .attr("r", 2.5)
    .attr("fill", "rgba(255,255,255,0.25")
    .attr("stroke", "rgba(51,48,0,0.75")
    .attr("stroke-width", 1.2);

  gChart
    .append("rect")
    .attr("class", "overlay")
    .attr("width", widthChart)
    .attr("height", heightChart)
    .attr("fill", "transparent")
    .on("mouseover", function () {
      focus.attr("display", null);
    })
    .on("mouseout", function () {
      focus.attr("display", "none");
    })
    .on("mousemove", mousemove);

  function mousemove() {
    const x0 = xScale.invert(d3.mouse(this)[0]);
    const i = bisectDate(dataReversed, x0, 1);
    const d0 = dataReversed[i - 1];
    const d1 = dataReversed[i] || dataReversed[i - 1]; // ||-hack due to d1 undefined error at edge
    const d = x0 - d0.date > d1.date - x0 ? d1 : d0;

    focus
      .select(".x-hover-line")
      .attr("transform", "translate(" + xScale(d.date) + "," + 0 + ")");

    focus
      .select(".date-hover-rect")
      .attr("transform", "translate(" + xScale(d.date) + "," + 0 + ")");
    focus
      .select(".date-hover-text")
      .attr("transform", "translate(" + xScale(d.date) + "," + 0 + ")")
      .text(formatTime(d.date));

    focus
      .select(".return-hover-rect")
      .attr(
        "transform",
        "translate(" + xScale(d.date) + "," + yScaleReturn(d.perReturn) + ")"
      );
    focus
      .select(".return-hover-text")
      .attr(
        "transform",
        "translate(" + xScale(d.date) + "," + yScaleReturn(d.perReturn) + ")"
      )
      .text(`${d.perReturn > 0 ? "+" : ""}${d.perReturn}`);

    focus
      .select(".stdev-hover-rect")
      .attr(
        "transform",
        "translate(" + xScale(d.date) + "," + yScaleStDev(d.perStDev) + ")"
      );
    focus
      .select(".stdev-hover-text")
      .attr(
        "transform",
        "translate(" + xScale(d.date) + "," + yScaleStDev(d.perStDev) + ")"
      )
      .text(d.perStDev);

    focus
      .select(".close-hover-rect")
      .attr(
        "transform",
        "translate(" + xScale(d.date) + "," + yScaleClose(d.adjClose) + ")"
      );
    focus
      .select(".close-hover-text")
      .attr(
        "transform",
        "translate(" + xScale(d.date) + "," + yScaleClose(d.adjClose) + ")"
      )
      .text(
        category === "forex" && !symbol.includes("JPY")
          ? d.adjClose.toFixed(5)
          : d.adjClose.toFixed(2)
      );

    focus
      .select(".return-hover-circle")
      .attr(
        "transform",
        "translate(" + xScale(d.date) + "," + yScaleReturn(d.perReturn) + ")"
      );
    focus
      .select(".stdev-hover-circle")
      .attr(
        "transform",
        "translate(" + xScale(d.date) + "," + yScaleStDev(d.perStDev) + ")"
      );
    focus
      .select(".close-hover-circle")
      .attr(
        "transform",
        "translate(" + xScale(d.date) + "," + yScaleClose(d.adjClose) + ")"
      );
  }
};

export default tooltips;
