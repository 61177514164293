import React, { useState, useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import { useHistory, Link } from "react-router-dom";
import { Button } from "antd";
import {
  FacebookFilled,
  InstagramFilled,
  TwitterSquareFilled,
  YoutubeFilled,
} from "@ant-design/icons";
import { select } from "d3";

// Redux
import { connect } from "react-redux";

import Background from "../../../style/images/frontend_bg_shaded_compressed.jpg";
import Blackhole from "../../../style/images/frontend_blackhole_compressed.png";
import { ReactComponent as Dial } from "../../../style/svg/frontend-element_dial-wings_4_conv.svg";
// import { ReactComponent as Dial } from '../../../style/svg/frontend-element_dial-wings_4_cleaned_optSVG_conv.svg';
import adjustHoverStyles from "./LandingPage-adjustHoverStyles";

// import BreakingSection from "../BreakingPage/BreakingSection";
// import { breakingSectionData } from "../BreakingPage/breakingSectionData";

// import imageBreakingReserach from "../../../style/images/research_2020_anomalies+play_compressed.jpg";
// import imageMarketViews from "../../../style/images/market_views_01_compr.jpg";
import imageMarketViews from "../../../style/images/screenshot_conversation_thorsten_adi_compr.jpg";
import imageEducation from "../../../style/images/seminarraum_jay_01_compr.jpg";
import imageAnalyticToolkit from "../../../style/images/analytic_toolkit_01_compr.jpg";
import imageCommunity from "../../../style/images/nasa-Q1p7bh3SHj8-unsplash_compr.jpg";

import "./LandingPage.css";
import "./LandingPage-dial.css";
import "./LandingPage-BreakingSection.css";

const getDimensions = (el) => {
  const { height } = el.getBoundingClientRect();
  const offsetTop = el.offsetTop;
  const offsetBottom = offsetTop + height;

  return {
    height,
    offsetTop,
    offsetBottom,
  };
};

const LandingPage = ({ auth }) => {
  const { isAuthenticated } = auth;

  const [hoverState, setHoverState] = useState("initial");
  const [visibleSection, setVisibleSection] = useState("Overview");

  let history = useHistory();

  const refOverview = useRef(null);
  // const refBreaking = useRef(null);
  const refPhilosophy = useRef(null);
  // const refTeam = useRef(null);
  const refBottom = useRef(null);

  const sectionRefs = [
    { section: "Overview", ref: refOverview },
    // { section: "Breaking", ref: refBreaking },
    { section: "Philosophy", ref: refPhilosophy },
    // { section: "Team", ref: refTeam },
    { section: "Bottom", ref: refBottom },
  ];

  const executeScroll = useCallback((ref) => {
    if (ref.current.className === "LandingPage-section-1") {
      executeScrollToTop();
    } else {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  const executeScrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const createAndUpdateDialLinks = useCallback(
    (id, isAuthenticated, urlAuth, urlNotAuth) => {
      // const linkId = id + '-link'
      // const linkEl = document.getElementById(linkId)
      // const linkHref = isAuthenticated ? urlAuth : urlNotAuth

      // if (linkEl === null) {
      //   const el = document.getElementById(id)
      //   const orgHtml = el.innerHTML
      //   const newHtml = `<a id=${linkId} href="${linkHref}" onclick="return false" target="_self">` + orgHtml + '</a>'
      //   el.innerHTML = newHtml
      //   select(`#${linkId}`).on('click', function() {history.push({
      //     pathname: urlNotAuth,
      //     state: {requestedUrl: urlAuth}
      //   })})
      // } else {
      //   linkEl.setAttribute('href', linkHref)
      //   select(`#${linkId}`).on('click', function() {
      //     if(isAuthenticated && linkHref[0] !== '/') {
      //       window.open(linkHref, '_self')
      //     } else {
      //       history.push({
      //         pathname: linkHref,
      //         state: {requestedUrl: urlAuth}
      //       })
      //     }
      //   })
      // }

      // removed urlNotAuth condition to open all tools for seminar thelastone
      const linkId = id + "-link";

      if (document.getElementById(linkId) === null) {
        const el = document.getElementById(id);
        const linkHref = urlAuth;

        const orgHtml = el.innerHTML;
        const newHtml =
          `<a id=${linkId} href="${linkHref}" onclick="return false" target="_self">` +
          orgHtml +
          "</a>";
        el.innerHTML = newHtml;

        const linkEl = document.getElementById(linkId);
        linkEl.setAttribute("href", linkHref);
        select(`#${linkId}`).on("click", function () {
          if (linkHref[0] !== "/") {
            window.open(linkHref, "_self");
          } else {
            history.push({
              pathname: linkHref,
              state: { requestedUrl: urlAuth },
            });
          }
        });
      }
    },
    [history]
  );

  useEffect(() => {
    const handleScroll = () => {
      const headerHeight = 90;
      const scrollPosition = window.scrollY + headerHeight;

      const selected = sectionRefs.find(({ section, ref }) => {
        const ele = ref.current;
        if (ele) {
          const { offsetBottom, offsetTop } = getDimensions(ele);
          return (
            scrollPosition > offsetTop - (1 / 3) * window.innerHeight - 50 &&
            scrollPosition < offsetBottom - (1 / 3) * window.innerHeight + 50
          );
        } else {
          return null;
        }
      });

      if (selected && selected.section !== visibleSection) {
        setVisibleSection(selected.section);
      } else if (!selected && visibleSection) {
        setVisibleSection(undefined);
      }
    };

    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [visibleSection, sectionRefs]);

  useEffect(() => {
    const sets = ["market-views", "education", "analytic-toolkit", "community"];
    sets.forEach((s) => {
      const el = document.getElementById(`${s}-container`);
      el.addEventListener("mouseenter", () => {
        setHoverState(s);
      });
      el.addEventListener("mouseout", () => {
        setHoverState("initial");
      });
      el.addEventListener("click", () => executeScroll(refPhilosophy));
    });

    // adding click events on dial texts
    // // market views
    select("#dial-text-market-views")
      .attr("text-decoration", "underline")
      .on("click", function () {
        window.open(
          "https://www.youtube.com/channel/UCafNb3dwvwiCimhVvBY3nHQ",
          "_blank"
        );
      });

    // // cot
    // createAndUpdateDialLinks(
    //   'dial-text-cot',
    //   isAuthenticated,
    //   '/cot',
    //   '/main/login'
    // );

    // // flows, gamma, vola
    createAndUpdateDialLinks(
      "dial-text-flows",
      isAuthenticated,
      "https://ttb-option-flow.netlify.app/optionflow-etf",
      "/main/login"
    );
    select("#dial-text-flows tspan")
      .attr("fill", "#fff")
      .attr("text-decoration", "underline");

    createAndUpdateDialLinks(
      "dial-text-gamma",
      isAuthenticated,
      "https://ttb-option-flow.netlify.app/optionflow-etf",
      "/main/login"
    );
    select("#dial-text-gamma tspan")
      .attr("fill", "#fff")
      .attr("text-decoration", "underline");

    createAndUpdateDialLinks(
      "dial-text-volatility",
      isAuthenticated,
      "https://ttb-option-flow.netlify.app/optionflow-etf",
      "/main/login"
    );
    select("#dial-text-volatility tspan")
      .attr("fill", "#fff")
      .attr("text-decoration", "underline");

    // spreads
    // createAndUpdateDialLinks('dial-text-spreads', isAuthenticated, '/spreads', '/main/login')
    // createAndUpdateDialLinks(
    //   'dial-text-spreads',
    //   isAuthenticated,
    //   'https://ttbspreadtrader.netlify.app/',
    //   '/main/login'
    // );
    select("#dial-text-spreads tspan").attr("fill", "#fff");

    // // timing
    // createAndUpdateDialLinks(
    //   "dial-text-timing",
    //   isAuthenticated,
    //   "/timing",
    //   "/main/login"
    // );
    select("#dial-text-timing tspan").attr("fill", "#fff");
    // .attr("text-decoration", "underline");

    // // seasonality
    createAndUpdateDialLinks(
      "dial-text-seasonality",
      isAuthenticated,
      "/seasonality",
      "/main/login"
    );
    select("#dial-text-seasonality tspan")
      .attr("fill", "#fff")
      .attr("text-decoration", "underline");

    // anomalies
    createAndUpdateDialLinks(
      "dial-text-anomalies",
      isAuthenticated,
      "/anomalies",
      "/main/login"
    );
    select("#dial-text-anomalies tspan")
      .attr("fill", "#fff")
      .attr("text-decoration", "underline");
  }, [isAuthenticated, executeScroll, createAndUpdateDialLinks]);

  // // // // // // // // // // // // // // // BREAKING SECTION // // // // // // // // // // // // // // //
  // const breakingDataCurrent = {
  //   ...breakingSectionData.sort((a, b) => b.date - a.date)[0],
  // };

  // breakingDataCurrent.mainLinkInternal = true;
  // breakingDataCurrent.mainLinkText = "View the complete history of Breakings";
  // breakingDataCurrent.mainLinkUrl = "/main/breakings";

  // const breakingContent = <BreakingSection id={0} data={breakingDataCurrent} />;

  return (
    <div
      className="LandingPage"
      style={{ backgroundImage: `url(${Background})` }}
    >
      <div className="LandingPage-section-1" ref={refOverview}>
        <div className="LandingPage-section-1-container">
          <div className="LandingPage-section-1-container-bh-dial">
            <div
              className="LandingPage-section-1-blackhole"
              style={{ backgroundImage: `url(${Blackhole})` }}
            ></div>
            <Dial className="LandingPage-dial" />
          </div>
          <div className="LandingPage-section-1-title-main">
            THE TACTICAL BRIEF
          </div>
          <div className="LandingPage-section-1-title-sub">
            - Stuffed by Market Professionals -
          </div>
          <div className="LandingPage-section-1-container-dial">
            {adjustHoverStyles(hoverState)}
          </div>
          <div className="LandingPage-section-1-container-button">
            <Button
              className="LandingPage-button join"
              disabled={true}
              onClick={() =>
                isAuthenticated
                  ? history.push("/main/account")
                  : history.push("/main/register")
              }
            >
              {isAuthenticated ? "Membership Account" : "Become a Member"}
            </Button>
          </div>
        </div>
      </div>

      {/* <div className="LandingPage-section-breaking">
        <div
          className="LandingPage-section-breaking-container"
          ref={refBreaking}
        >
          <div className="LandingPage-section-breaking-heading LandingPage-section-breaking-grid-item">
            Breaking
            <div className="LandingPage-section-breaking-heading-line"></div>
          </div>
          {breakingContent}
        </div>
      </div> */}

      <div className="LandingPage-section-philosophy">
        <div
          className="LandingPage-section-philosophy-container"
          ref={refPhilosophy}
        >
          <div className="LandingPage-section-philosophy-heading LandingPage-section-philosophy-grid-item">
            Our Philosophy
            <div className="LandingPage-section-philosophy-heading-line"></div>
          </div>
          <div className="LandingPage-section-philosophy-box-market-views LandingPage-section-philosophy-grid-item">
            <div className="LandingPage-section-philosophy-text">
              <span className="text-titel">Market Views.</span> Sometimes
              experience and age helps to access a specific market situation.
              Working thoroughly and diligently always helps to focus one’s mind
              on the real issues. In this section we aim to let you participate
              in our thought process on how to approach investment decisions.
              Thorsten and a variety of market veterans will brief you on what
              might, and sometimes even more important, might not happen in the
              beautiful world of financial markets.
            </div>
            <div className="LandingPage-section-philosophy-img">
              <img src={imageMarketViews} alt="img-market-views" />
            </div>
          </div>
          <div className="LandingPage-section-philosophy-box-education LandingPage-section-philosophy-grid-item">
            <div className="LandingPage-section-philosophy-text">
              <span className="text-titel">Education.</span> The stuff we use.
              For every tool introduced, we will explain why, how and when to
              use it.
              <br></br>
              <br></br>
              From basics to advanced concepts, the combination thereof as well
              as the ability for you the user to manipulate up-to-date data, to
              explore markets from various angles, to discover relationships and
              your edge as well as to follow your own leads.
            </div>
            <div className="LandingPage-section-philosophy-img">
              <img src={imageEducation} alt="img-education" />
            </div>
          </div>
          <div className="LandingPage-section-philosophy-box-analytic-toolkit LandingPage-section-philosophy-grid-item">
            <div className="LandingPage-section-philosophy-text">
              <span className="text-titel">Analytic Toolkit.</span> We introduce
              strategies, concepts and applications to master financial markets.
              The purpose is not to impress by using weird lingo or
              over-complications to self-inflate our egos. We merely wish to
              explain everything that might help you to become a better
              investor.
              <br></br>
              <br></br>
              From basic economics to advanced concepts, easy to follow,
              comprehensible and entertaining.
            </div>
            <div className="LandingPage-section-philosophy-img">
              <img src={imageAnalyticToolkit} alt="img-toolkit" />
            </div>
          </div>
          <div className="LandingPage-section-philosophy-box-community LandingPage-section-philosophy-grid-item">
            <div className="LandingPage-section-philosophy-text">
              <span className="text-titel">Community.</span> We lived our
              professional lives on trading floors for a reason. We love to
              communicate, absorb information and banter. So do our students and
              professional guests. Exchange concepts, ideas, see them being
              elevated to trade ideas or shot down, or be part of a live teach
              in. Make new friends or even business associates. Enjoy a friendly
              community of traders, scientist, beginners, and accomplished
              investors without prejudice.
            </div>
            <div className="LandingPage-section-philosophy-img">
              <img src={imageCommunity} alt="img-community" />
            </div>
          </div>
        </div>
        {/* <div className="LandingPage-section-team">
          <div className="LandingPage-section-team-container" ref={refTeam}>
            <div className="LandingPage-section-team-heading LandingPage-section-team-grid-item">
              The Team Behind
              <div className="LandingPage-section-team-heading-line"></div>
            </div>
          </div>
        </div> */}
      </div>
      <div className="LandingPage-section-bottom" ref={refBottom}>
        <div className="LandingPage-section-bottom-container">
          <div className="LandingPage-section-bottom-subcontainer-1 grid-item">
            <a
              href="https://www.facebook.com/The-Tactical-Brief-105840822109430"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FacebookFilled className="LandingPage-section-bottom-icon" />
            </a>
            <a
              href="https://www.instagram.com/thetacticalbrief/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <InstagramFilled className="LandingPage-section-bottom-icon" />
            </a>
            <a
              href="https://twitter.com/_TacticalBrief_"
              target="_blank"
              rel="noopener noreferrer"
            >
              <TwitterSquareFilled className="LandingPage-section-bottom-icon" />
            </a>
            <a
              href="https://www.youtube.com/channel/UCafNb3dwvwiCimhVvBY3nHQ"
              target="_blank"
              rel="noopener noreferrer"
            >
              <YoutubeFilled className="LandingPage-section-bottom-icon" />
            </a>
          </div>
          <div className="LandingPage-section-bottom-subcontainer-2 grid-item">
            <Link to="/main/imprint" id="LandingPage-impressum-imprint-link">
              Imprint
            </Link>
            <span>|</span>{" "}
            <Link to="/main/imprint" id="LandingPage-impressum-imprint-link">
              Disclaimer
            </Link>{" "}
            <span>|</span>{" "}
            <Link to="/main/imprint" id="LandingPage-impressum-imprint-link">
              Contact
            </Link>
          </div>
          <div className="LandingPage-section-bottom-subcontainer-3 grid-item">
            <div className="LandingPage-section-bottom-subcontainer-3-text">
              {`THE TACTICAL BRIEF © ${new Date().getFullYear()} `}{" "}
              <span>by</span> WEGENER KAMMEL ERDMANN
            </div>
          </div>
          <div className="LandingPage-section-bottom-subcontainer-4 grid-item">
            <div className="LandingPage-section-bottom-subcontainer-4-text">
              Privacy Policy. In this supporting service to The Tactical Brief (
              <a
                href="https://thetacticalbrief.com"
                target="_blank"
                rel="noopener noreffer"
              >
                thetacticalbrief.com
              </a>
              ) named 'The Tactical Brief | Quant Hub', we use a third-party
              media player service by Wistia.com in private mode which collects
              and stores anomymous video access data and YouTube.com. Apart from
              this, no information is collected by the creators of this website
              and no tracking cookies are employed.
            </div>
          </div>
        </div>
      </div>
      <div className="LandingPage-nav">
        <div
          className={`${
            visibleSection === "Overview" ? "current-section" : ""
          }`}
          onClick={() => executeScroll(refOverview)}
        >
          Overview
        </div>
        {/* <div
          className={`${
            visibleSection === "Breaking" ? "current-section" : ""
          }`}
          onClick={() => executeScroll(refBreaking)}
        >
          Breaking
        </div> */}
        <div
          className={`${
            visibleSection === "Philosophy" ? "current-section" : ""
          }`}
          onClick={() => executeScroll(refPhilosophy)}
        >
          Philosophy
        </div>
        {/* <div
          className={`${visibleSection === "Team" ? "current-section" : ""}`}
          onClick={() => executeScroll(refTeam)}
        >
          The Team Behind
        </div> */}
        <div
          className={`${visibleSection === "Bottom" ? "current-section" : ""}`}
          onClick={() => executeScroll(refBottom)}
        >
          Legal Notice & Links
        </div>
      </div>
    </div>
  );
};

LandingPage.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(LandingPage);
