export const GICS_SECTORS = [
  {
    name: 'Communication Services',
    symbol: 'XLC',
  },
  {
    name: 'Consumer Discretionary',
    symbol: 'XLY',
  },
  {
    name: 'Consumer Staples',
    symbol: 'XLP',
  },
  {
    name: 'Energy',
    symbol: 'XLE',
  },
  {
    name: 'Financials',
    symbol: 'XLF',
  },
  {
    name: 'Health Care',
    symbol: 'XLV',
  },
  {
    name: 'Industrials',
    symbol: 'XLI',
  },
  {
    name: 'Information Technology',
    symbol: 'XLK',
  },
  {
    name: 'Materials',
    symbol: 'XLB',
  },
  {
    name: 'Real Estate',
    symbol: 'XLRE',
  },
  {
    name: 'Utilities',
    symbol: 'XLU',
  },
];
