import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";

// import PrivatRoute from './components/PrivatRoute';

import BouncerPage from "./main/content/BouncerPage/BouncerPage";
import Main from "./main/Main";
// import Cot from "./tools/Cot/Cot";
import Anomalies from "./tools/Anomalies/Anomalies";
import Seasonality from "./tools/Seasonality/Seasonality";
// import Timing from "./tools/Timing/Timing";
// import SpreadTraderPage from './tools/SpreadTrader/SpreadTraderPage';

// Context
import { AnomaliesContextProvider } from "./contexts/AnomaliesContext";
import { SeasonalityContextProvider } from "./contexts/SeasonalityContext";
import { TimingContextProvider } from "./contexts/TimingContext";

// Redux
import { Provider } from "react-redux";
import store from "./redux/store";
import { loadUser } from "./redux/actions/authActions";
import setAuthToken from "./redux/redux-utils/setAuthToken";

// Initial token check
if (localStorage.token) {
  setAuthToken(localStorage.token);
}

const App = () => {
  const [accessGranted, setAccessGranted] = useState(
    process.env.NODE_ENV === "production"
      ? localStorage.accessGranted || false
      : true
  );

  const grantAccess = () => {
    localStorage.setItem("accessGranted", true);
    setAccessGranted(true);
  };

  useEffect(() => {
    store.dispatch(loadUser());
    // window.onbeforeunload = function () {
    //   localStorage.removeItem('accessGranted');
    // };
  }, []);
  return (
    <Provider store={store}>
      <div className="App">
        {!accessGranted ? (
          <BouncerPage
            accessCode="IronCondor#1"
            grantAccess={grantAccess}
            accessGranted={accessGranted}
          />
        ) : (
          <Switch>
            <Route
              exact
              path="/"
              render={() => <Main content="landingpage" />}
            />
            {/* <Route
              exact
              path="/main/registrationconfirmed/:token"
              render={(routeProps) => (
                <Main
                  content="registrationconfirmed"
                  token={routeProps.match.params.token}
                />
              )}
            />
            <Route
              exact
              path="/main/resetpassword/:token"
              render={(routeProps) => (
                <Main
                  content="resetpassword"
                  token={routeProps.match.params.token}
                />
              )}
            /> */}
            <Route
              exact
              path="/main/:content"
              render={(routeProps) => (
                <Main content={routeProps.match.params.content} />
              )}
            />
            {/*<PrivatRoute exact path='/cot' component={Cot} />*/}
            {/* <Route exact path="/cot" component={Cot} /> */}

            {/*<PrivatRoute exact path='/spreads' component={SpreadTraderPage} />*/}
            {
              // <Route exact path='/spreads' component={SpreadTraderPage} />
            }

            <TimingContextProvider>
              <AnomaliesContextProvider>
                {/*<PrivatRoute exact path='/anomalies' component={Anomalies} />*/}
                <Route exact path="/anomalies" component={Anomalies} />
              </AnomaliesContextProvider>
              <SeasonalityContextProvider>
                <Route exact path="/seasonality" component={Seasonality} />
              </SeasonalityContextProvider>
              {/* <Route exact path="/timing" component={Timing} /> */}
            </TimingContextProvider>

            <Route render={() => <Main content="error" />} />
          </Switch>
        )}
      </div>
    </Provider>
  );
};

export default App;
