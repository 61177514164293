import * as d3 from "d3";

function drawSeasonalityLineChart(nodeId, data) {
  // STYLES  -----------------------------------------------------
  const colors = {
    axisTitle: "#5D5D5D",
    axisLabel: "#878787",
    axisLine: "#878787",
    errorBars: "#434343",
    blueMain: "#1890ff",
    blueDarker: "#126cbf",
    blueDarkest: "#002766",
    blueLighter: "#6FBAFF",
    blueLightest: "#C5E3FF",
    red: "#ff181d",
    redLight: "#ffa8aa",
    redLabel: "#bf1216",
    green: "#4bc219",
    greenLight: "#bce8a9",
    underlyingLabel: changeHexColorOpacity("#333", 0.3),
  };
  const fontStyles = {
    axisTitle: {
      fontSize: 12,
      fontWeight: 300,
    },
    axisLabel: {
      fontSize: 10,
      fontWeight: 300,
    },
  };
  const lineStyles = {
    strokeWidth: {
      primary: {
        normal: 1.5,
        thick: 2.25,
        thicker: 3,
      },
      secondary: {
        normal: 1,
        thick: 1.75,
        thicker: 2.5,
      },
    },
  };

  // DATA PREPARATION --------------------------------------------------
  const seasonalityData = data.data.weekly;
  const dataPS = seasonalityData.pastSeasonality;
  const dataCS = seasonalityData.currentSeasonality;

  // CURRENT WEEK DATA
  const [currentWeek, currentWeekProgression] = Object.entries(
    seasonalityData.currentPerformanceProgression
  ).findLast(() => true);

  // FINAL PERFORMANCE PROGRESS MEAN AND MEDIAN
  const annualPerformanceMean =
    seasonalityData.pastPerformanceProgression.mean[52];
  const annualPerformanceMedian =
    seasonalityData.pastPerformanceProgression.median[52];

  // BEST SECTIONS DATA
  const bestSections = {
    mean: seasonalityData.bestSeasonalitySections_mean,
    median: seasonalityData.bestSeasonalitySections_median,
  };

  const bestSectionsAreaData = {};
  for (let k of Object.keys(bestSections)) {
    bestSectionsAreaData[k] = [];
    const sectionLength = bestSections[k].sectionLength;

    for (let [k2, obj] of Object.entries(
      bestSections[k].bestSectionStartWeeks
    )) {
      // accounting for best sections starting from week 50
      // and, thus, crossing over into first weeks of year
      if (obj.week <= 49) {
        // normal case
        const startWeek = obj.week - 1;
        const endWeek = obj.week + sectionLength - 1;
        const sectionAreaData = [];
        for (let i = startWeek; i <= endWeek; i++) {
          sectionAreaData.push({
            week: i,
            value: seasonalityData.pastPerformanceProgression[k][i],
          });
        }

        bestSectionsAreaData[k].push(sectionAreaData);
      } else {
        // case for crossover sections
        const startWeeks = [obj.week - 1, 0];
        const endWeeks = [52, obj.week + sectionLength - 1 - 52];

        for (let partIdx = 0; partIdx < startWeeks.length; partIdx++) {
          const sectionAreaData = [];

          for (let i = startWeeks[partIdx]; i <= endWeeks[partIdx]; i++) {
            sectionAreaData.push({
              week: i,
              value: seasonalityData.pastPerformanceProgression[k][i],
            });
          }
          bestSectionsAreaData[k].push(sectionAreaData);
        }
      }
    }
  }

  // Month Label Data
  const daysPerMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  let monthLabelData = [];

  for (let i = 0; i < daysPerMonth.length; i++) {
    const currMonthDays = daysPerMonth[i];
    const currDaysSlice = daysPerMonth.slice(0, i + 1);
    const sumDays = currDaysSlice.reduce((prev, curr) => prev + curr, 0);

    monthLabelData.push({
      monthName: months[i],
      monthEndInWeeks: sumDays / 7,
      monthMidInWeeks: (sumDays - currMonthDays) / 7 + currMonthDays / (2 * 7),
    });
  }

  // DIMENSIONS --------------------------------------------------
  // getting div container of chart and set dimensions
  const divChart = d3.select(`#${nodeId}`)._groups[0][0];
  const width = divChart.offsetWidth;
  const height = divChart.offsetHeight;

  // margins
  const margin = { top: 0, right: 50, bottom: 50, left: 70 };

  // creating main svg
  const svg = d3
    .select(`#${nodeId}`)
    .append("svg")
    .attr("width", width)
    .attr("height", height);
  // .style("border", "1px solid red");

  // defining chart height and width
  const widthChart = width - margin.left - margin.right;
  const heightChart = height - margin.top - margin.bottom;

  const g = svg
    .append("g")
    .attr("transform", `translate(${margin.left}, ${margin.top})`);

  // g.append("rect")
  //   .attr("x", 0)
  //   .attr("y", 0)
  //   .attr("width", widthChart)
  //   .attr("height", heightChart)
  //   .attr("fill", "gold");

  // MAIN LABELS ------------------------------------------------------------------
  // X AXIS TITLE
  g.append("text")
    .attr("class", "axis-title x")
    .attr("id", "title-days")
    .attr("x", widthChart / 2)
    .attr("y", heightChart + margin.bottom / 2 + 13)
    .attr("font-size", fontStyles.axisTitle.fontSize + "px")
    .attr("font-weight", fontStyles.axisTitle.fontWeight)
    .attr("fill", colors.axisTitle)
    .attr("text-anchor", "middle")
    .text("Week of Year");
  // Y AXIS TITLES
  g.append("text")
    .attr("class", "axis-title y")
    .attr("id", "axis-title-returns")
    .attr("y", (-margin.left * 3) / 4)
    .attr("x", (-1 / 2) * heightChart)
    .attr("font-size", fontStyles.axisTitle.fontSize + "px")
    .attr("font-weight", fontStyles.axisTitle.fontWeight)
    .attr("fill", colors.axisTitle)
    .attr("transform", "rotate(-90)")
    .attr("text-anchor", "middle")
    .text("Portfolio Value (%)");
  // CHART LABELS
  // // SUBTITLE
  // g.append("text")
  //   .attr("class", "chart-label")
  //   .attr("x", widthChart - 10)
  //   .attr("y", 20)
  //   .attr("font-size", "calc(0.5vw + 0.2rem)")
  //   .attr("font-weight", 400)
  //   .attr("fill", colors.axisLabel)
  //   .attr("opacity", 0.4)
  //   .attr("text-anchor", "end")
  //   .attr("letter-spacing", "0.25px")
  //   .text("SEASONALITY BY WEEKLY RETURNS");
  // // UNDERLYING
  g.append("text")
    .attr("class", "chart-label symbol")
    .attr("x", widthChart / 2)
    .attr("y", heightChart / 2 + 7)
    .attr("font-size", "calc(1vw + 1rem)")
    .attr("font-weight", 500)
    .attr("fill", colors.underlyingLabel)
    .attr("text-anchor", "middle")
    .text(`${data.symbol}`);
  g.append("text")
    .attr("class", "chart-label name")
    .attr("x", widthChart / 2)
    .attr("y", heightChart / 2 + 27)
    .attr("font-size", "calc(0.5vw + 0.3rem)")
    .attr("font-weight", 400)
    .attr("fill", colors.underlyingLabel)
    .attr("text-anchor", "middle")
    .attr("letter-spacing", "0.25px")
    .text(`${data.name.toUpperCase()}`);

  // SCALES --------------------------------------------------------------------
  // X DATE
  const xScale = d3.scaleLinear().range([0, widthChart]).domain([0, 53]);

  // Y RETURN
  const yScale = d3.scaleLinear().range([heightChart, 0]);

  // AXES --------------------------------------------------------------------
  // X AXIS
  const xAxisBottomCall = d3.axisBottom().scale(xScale).tickSizeOuter(0);
  const xAxisBottom = g
    .append("g")
    .attr("class", "x axis bottom")
    .attr("transform", `translate(0, ${heightChart})`);
  xAxisBottom.call(xAxisBottomCall);

  const xAxisTopCall = d3
    .axisTop()
    .scale(xScale)
    .tickSizeOuter(0)
    .ticks(0)
    .tickFormat("");
  const xAxisTop = g.append("g").attr("class", "x axis top");
  xAxisTop.call(xAxisTopCall);

  // Y AXIS
  const yAxisLeftCall = d3.axisLeft().tickSizeOuter(0);
  const yAxisLeft = g.append("g").attr("class", "y axis left");

  const yAxisRightCall = d3
    .axisRight()
    // .scale(yScale)
    .tickSizeOuter(0);
  // .ticks(0)
  // .tickFormat("");
  const yAxisRight = g
    .append("g")
    .attr("class", "y axis right")
    .attr("transform", `translate(${widthChart}, 0)`);
  // yAxisRight.call(yAxisRightCall);

  // DEFINING CHART ELEMENTS NOT TO BE UPDATED ------------------------------------------------------------
  // GRADIENTS
  const gradientWhite = changeHexColorOpacity("#fff", 0.3);
  const gradientWhiteOffset = 5;
  const gradientBlueMean = changeHexColorOpacity(colors.blueMain, 0.3);
  const gradientBlueMedian = changeHexColorOpacity(colors.blueDarkest, 0.4);

  for (let k of ["mean", "median"]) {
    for (let direction of ["below", "above"])
      svg
        .append("linearGradient")
        .attr("id", `area-gradient_${direction}_${k}`)
        // .attr("gradientUnits", "userSpaceOnUse")
        .attr("x1", "0%")
        .attr("y1", "0%")
        .attr("x2", "0%")
        .attr("y2", "100%")
        .selectAll("stop")
        .data([
          {
            offset: direction === "below" ? "0%" : `${gradientWhiteOffset}%`,
            color:
              direction === "below"
                ? k === "mean"
                  ? gradientBlueMean
                  : gradientBlueMedian
                : gradientWhite,
          },
          {
            offset:
              direction === "below" ? `${100 - gradientWhiteOffset}%` : "100%",
            color:
              direction === "below"
                ? gradientWhite
                : k === "mean"
                ? gradientBlueMean
                : gradientBlueMedian,
          },
        ])
        .enter()
        .append("stop")
        .attr("offset", (d) => d.offset)
        .attr("stop-color", (d) => d.color);
  }

  // X AXIS MONTHS
  for (let d of monthLabelData) {
    g.append("text")
      .attr("class", "x-axis-month-label")
      .attr("x", xScale(d.monthMidInWeeks))
      .attr("y", heightChart - 5)
      .attr("font-size", fontStyles.axisLabel.fontSize)
      .attr("fill", colors.axisLabel)
      .attr("text-anchor", "middle")
      .text(d.monthName);
    if (d.monthName !== "Dec") {
      g.append("line")
        .attr("class", "x-axis-month-end-line")
        .attr("x1", xScale(d.monthEndInWeeks))
        .attr("x2", xScale(d.monthEndInWeeks))
        .attr("y1", heightChart - 5)
        .attr("y2", heightChart)
        .attr("stroke", colors.axisLabel)
        .attr("stroke-width", 1);
    }
  }

  // CURRENT WEEK X AXIS LABEL
  g.append("line")
    .attr("class", "current-week-x-label-line")
    .attr("x1", xScale(currentWeek))
    .attr("x2", xScale(currentWeek))
    .attr("y1", heightChart)
    .attr("y2", heightChart + 5)
    .attr("stroke", colors.redLabel)
    .attr("stroke-width", 1)
    .attr("opacity", 0.75);
  g.append("rect")
    .attr("class", "current-week-x-label-rect")
    .attr("x", xScale(currentWeek) - 20)
    .attr("y", heightChart + 5)
    .attr("width", 40)
    .attr("height", 14)
    .attr("fill", colors.redLabel)
    .attr("opacity", 0.75);
  g.append("text")
    .attr("class", "current-week-x-label-text")
    .attr("x", xScale(currentWeek))
    .attr(
      "y",
      heightChart + 5 + 14 - (14 - fontStyles.axisLabel.fontSize) / 1.5
    )
    .attr("text-anchor", "middle")
    .attr("font-size", fontStyles.axisLabel.fontSize)
    .attr("font-weight", fontStyles.axisLabel.fontWeight)
    .attr("fill", "#fff")
    .text(currentWeek);

  // DEFINING CHART ELEMENTS TO BE UPDATED ------------------------------------------------------------
  // BEST SECTIONS FILL AREA
  for (let k of Object.keys(bestSectionsAreaData)) {
    for (let i = 0; i < bestSectionsAreaData[k].length; i++) {
      for (let direction of ["below", "above"]) {
        g.append("path")
          .attr("id", `best-sections-fill-area_${k}${i}${direction}`)
          .attr("fill", `url(#area-gradient_${direction}_${k})`)
          .lower();
      }
    }
  }
  for (let k of Object.keys(bestSections)) {
    for (let i = 0; i < bestSections[k].bestSectionStartWeeks.length; i++) {
      const startWeek = bestSections[k].bestSectionStartWeeks[i].week - 1;
      const textAnchorWeek = startWeek < 49 ? startWeek + 2 : 50;

      const yOffset = -22;

      g.append("text")
        .attr("class", `best-section-label_${k}${i}`)
        .attr("x", xScale(textAnchorWeek))
        .attr("y", k === "mean" ? heightChart - 5 + yOffset : 13)
        .attr("fill", colors.axisTitle)
        .attr("font-size", fontStyles.axisLabel.fontSize)
        .attr("text-anchor", "middle")
        // .attr("fill", k === "mean" ? colors.blueDarker : colors.blueDarkest)
        .text(
          `${bestSections[k].sectionLength}W-${k.toUpperCase()} TOP ${i + 1} `
        );
      g.append("text")
        .attr("class", `best-section-label_${k}${i}`)
        .attr("x", xScale(textAnchorWeek))
        .attr("y", k === "mean" ? heightChart - 18 + yOffset : 26)
        .attr("fill", colors.axisTitle)
        .attr("font-size", fontStyles.axisLabel.fontSize)
        .attr("text-anchor", "middle")
        .text(
          `${bestSections[k].bestSectionStartWeeks[i].performance.toFixed(2)} %`
        );
    }
  }

  // UTILITY CHART ELEMENTS
  const utilityLine100 = g
    .append("line")
    .attr("class", "utility-line-100")
    .attr("x1", 0)
    .attr("x2", widthChart)
    .attr("stroke", colors.axisLabel)
    .attr("stroke-width", 1)
    .attr("stroke-dasharray", "1,1")
    .lower();

  // APPENDING ALL POSSIBLE SEASONALITY LINES
  for (let key of Object.keys(
    seasonalityData.pastPerformanceProgression.allYearsIndiviual
  )) {
    const annualPerformance =
      seasonalityData.pastPerformanceProgression.allYearsIndiviual[key][52];
    g.append("path")
      .attr(
        "class",
        `line-seasonality line-seasonality-${key} line-seasonality-history`
      )
      .attr("stroke", colors.axisLine)
      .attr("stroke-width", lineStyles.strokeWidth.secondary.normal)
      .attr("visibility", "hidden")
      .on("mouseover", function () {
        d3.select(this)
          .attr("stroke-width", lineStyles.strokeWidth.secondary.thicker)
          .attr("stroke", "#333")
          .attr("opacity", 1)
          .raise();
        d3.select(".tooltip-performance-line-rect")
          .attr("x", d3.mouse(this)[0] + 5)
          .attr("y", d3.mouse(this)[1] - 18)
          .attr("visibility", "visible")
          .raise();
        d3.select(".tooltip-performance-line-text")
          .attr("x", d3.mouse(this)[0] + 5 + 25)
          .attr("y", d3.mouse(this)[1] - 3 - 4)
          .attr("visibility", "visible")
          .text(key)
          .raise();
        d3.select(".annual-performance-historic-label-line")
          .attr("y1", yScale(annualPerformance))
          .attr("y2", yScale(annualPerformance))
          .attr("visibility", "visible");
        d3.select(".annual-performance-historic-label-rect")
          .attr("y", yScale(annualPerformance) - 8)
          .attr("visibility", "visible");
        d3.select(".annual-performance-historic-label-text")
          .attr(
            "y",
            yScale(annualPerformance) +
              (14 - fontStyles.axisLabel.fontSize) / 1.5
          )
          .attr("visibility", "visible")
          .text(annualPerformance.toFixed(2));
      })
      .on("mouseout", function () {
        d3.select(this)
          .attr("stroke-width", lineStyles.strokeWidth.secondary.normal)
          .attr("stroke", colors.axisLine)
          .attr("opacity", 0.5);
        d3.select(".tooltip-performance-line-rect").attr(
          "visibility",
          "hidden"
        );
        d3.select(".tooltip-performance-line-text").attr(
          "visibility",
          "hidden"
        );
        d3.select(".annual-performance-historic-label-line").attr(
          "visibility",
          "hidden"
        );
        d3.select(".annual-performance-historic-label-rect").attr(
          "visibility",
          "hidden"
        );
        d3.select(".annual-performance-historic-label-text").attr(
          "visibility",
          "hidden"
        );
      });
  }
  // CURRENT PERFORMANCE LINE
  g.append("path")
    .attr(
      "class",
      "line-seasonality line-seasonality-current line-seasonality-main"
    )
    .attr("stroke", colors.red)
    .on("mouseover", function () {
      d3.select(this)
        .attr("stroke-width", lineStyles.strokeWidth.primary.thicker)
        .raise();
      d3.select(".tooltip-performance-line-rect")
        .attr("x", d3.mouse(this)[0] + 5)
        .attr("y", d3.mouse(this)[1] - 18)
        .attr("visibility", "visible")
        .raise();
      d3.select(".tooltip-performance-line-text")
        .attr("x", d3.mouse(this)[0] + 5 + 25)
        .attr("y", d3.mouse(this)[1] - 3 - 4)
        .attr("visibility", "visible")
        .text(data.lastPriceAction.date.slice(0, 4))
        .raise();
    })
    .on("mouseout", function () {
      d3.select(this).attr(
        "stroke-width",
        lineStyles.strokeWidth.primary.normal
      );
      d3.select(".tooltip-performance-line-rect").attr("visibility", "hidden");
      d3.select(".tooltip-performance-line-text").attr("visibility", "hidden");
    });
  g.append("path")
    .attr(
      "class",
      "line-seasonality line-seasonality-pastMean line-seasonality-main"
    )
    .attr("stroke", colors.blueMain)
    .on("mouseover", function () {
      d3.select(this)
        .attr("stroke-width", lineStyles.strokeWidth.primary.thicker)
        .raise();
      d3.select(".tooltip-performance-line-rect")
        .attr("x", d3.mouse(this)[0] + 5)
        .attr("y", d3.mouse(this)[1] - 18)
        .attr("visibility", "visible")
        .raise();
      d3.select(".tooltip-performance-line-text")
        .attr("x", d3.mouse(this)[0] + 5 + 25)
        .attr("y", d3.mouse(this)[1] - 3 - 4)
        .attr("visibility", "visible")
        .text("MEAN")
        .raise();
    })
    .on("mouseout", function () {
      d3.select(this).attr(
        "stroke-width",
        lineStyles.strokeWidth.primary.normal
      );
      d3.select(".tooltip-performance-line-rect").attr("visibility", "hidden");
      d3.select(".tooltip-performance-line-text").attr("visibility", "hidden");
    });

  g.append("path")
    .attr(
      "class",
      "line-seasonality line-seasonality-pastMedian line-seasonality-main"
    )
    .attr("stroke", colors.blueDarkest)
    .on("mouseover", function () {
      d3.select(this)
        .attr("stroke-width", lineStyles.strokeWidth.primary.thicker)
        .raise();
      d3.select(".tooltip-performance-line-rect")
        .attr("x", d3.mouse(this)[0] + 5)
        .attr("y", d3.mouse(this)[1] - 18)
        .attr("visibility", "visible")
        .raise();
      d3.select(".tooltip-performance-line-text")
        .attr("x", d3.mouse(this)[0] + 5 + 25)
        .attr("y", d3.mouse(this)[1] - 3 - 4)
        .attr("visibility", "visible")
        .text("MEDIAN")
        .raise();
    })
    .on("mouseout", function () {
      d3.select(this).attr(
        "stroke-width",
        lineStyles.strokeWidth.primary.normal
      );
      d3.select(".tooltip-performance-line-rect").attr("visibility", "hidden");
      d3.select(".tooltip-performance-line-text").attr("visibility", "hidden");
    });

  d3.selectAll(".line-seasonality").attr("fill", "none");
  d3.selectAll(".line-seasonality-main").attr(
    "stroke-width",
    lineStyles.strokeWidth.primary.normal
  );

  // CURRENT WEEK CIRCLE
  const currentWeekCircle = g
    .append("circle")
    .attr("class", "current-week-circle")
    .attr("r", 3)
    .attr("stroke", colors.redLabel)
    .attr("stroke-width", 1.2)
    .attr("fill", "transparent")
    .attr("cx", xScale(currentWeek));

  // CURRENT WEEK Y AXIS LABEL
  const currentWeekYaxisLabelLine = g
    .append("line")
    .attr("class", "current-week-y-label-line")
    .attr("x1", -5)
    .attr("x2", 0)
    .attr("stroke", colors.redLabel)
    .attr("stroke-width", 1)
    .attr("opacity", 0.75);
  const currentWeekYaxisLabelRect = g
    .append("rect")
    .attr("class", "current-week-y-label-rect")
    .attr("width", 40)
    .attr("height", 14)
    .attr("x", -5 - 40)
    .attr("fill", colors.redLabel)
    .attr("opacity", 0.75);
  const currentWeekYaxisLabelText = g
    .append("text")
    .attr("class", "current-week-y-label-text")
    .attr("x", -5 - 40 + 20)
    .attr("text-anchor", "middle")
    .attr("font-size", fontStyles.axisLabel.fontSize)
    .attr("font-weight", fontStyles.axisLabel.fontWeight)
    .attr("fill", "#fff")
    .text(currentWeekProgression.toFixed(2));

  // ANNUAL PERFORMANCE MEAN LABEL
  const labelAnnualPerformanceMeanLine = g
    .append("line")
    .attr("class", "annual-performance-mean-label-line")
    .attr("x1", widthChart)
    .attr("x2", widthChart + 5)
    .attr("stroke", colors.blueMain)
    .attr("stroke-width", 1)
    .attr("opacity", 0.75);
  const labelAnnualPerformanceMeanRect = g
    .append("rect")
    .attr("class", "annual-performance-mean-label-rect")
    .attr("width", 40)
    .attr("height", 14)
    .attr("x", widthChart + 5)
    .attr("fill", colors.blueMain)
    .attr("opacity", 0.75);
  const labelAnnualPerformanceMeanText = g
    .append("text")
    .attr("class", "annual-performance-mean-label-text")
    .attr("x", widthChart + 5 + 20)
    .attr("text-anchor", "middle")
    .attr("font-size", fontStyles.axisLabel.fontSize)
    .attr("font-weight", fontStyles.axisLabel.fontWeight)
    .attr("fill", "#fff")
    .text(annualPerformanceMean.toFixed(2));

  // ANNUAL PERFORMANCE MEDIAN LABEL
  const labelAnnualPerformanceMedianLine = g
    .append("line")
    .attr("class", "annual-performance-median-label-line")
    .attr("x1", widthChart)
    .attr("x2", widthChart + 5)
    .attr("stroke", colors.blueDarkest)
    .attr("stroke-width", 1)
    .attr("opacity", 0.75);
  const labelAnnualPerformanceMedianRect = g
    .append("rect")
    .attr("class", "annual-performance-median-label-rect")
    .attr("width", 40)
    .attr("height", 14)
    .attr("x", widthChart + 5)
    .attr("fill", colors.blueDarkest)
    .attr("opacity", 0.75);
  const labelAnnualPerformanceMedianText = g
    .append("text")
    .attr("class", "annual-performance-median-label-text")
    .attr("x", widthChart + 5 + 20)
    .attr("text-anchor", "middle")
    .attr("font-size", fontStyles.axisLabel.fontSize)
    .attr("font-weight", fontStyles.axisLabel.fontWeight)
    .attr("fill", "#fff")
    .text(annualPerformanceMedian.toFixed(2));

  // // TOOLTIPS FOR PERFORMANCE LINES ------------------------------
  // RECT+TEXT FOR YEAR OF PERFORMANCE LINE (OR MEAN/MEDIAN)
  g.append("rect")
    .attr("class", "tooltip-performance-line-rect")
    .attr("width", 50)
    .attr("height", 15)
    .attr("fill", "#333")
    .attr("opacity", 0.95)
    .attr("visibility", "hidden");
  g.append("text")
    .attr("class", "tooltip-performance-line-text")
    .attr("font-size", "10px")
    .attr("fill", "#fff")
    .attr("text-anchor", "middle")
    .attr("visibility", "hidden");
  // ANNUAL PERFORMANCE FOR HISTORIC LINES
  // ANNUAL PERFORMANCE MEAN LABEL
  g.append("line")
    .attr("class", "annual-performance-historic-label-line")
    .attr("x1", widthChart)
    .attr("x2", widthChart + 5)
    .attr("stroke", "#333")
    .attr("stroke-width", 1)
    .attr("opacity", 0.95)
    .attr("visibility", "hidden");
  g.append("rect")
    .attr("class", "annual-performance-historic-label-rect")
    .attr("width", 40)
    .attr("height", 14)
    .attr("x", widthChart + 5)
    .attr("fill", "#333")
    .attr("opacity", 0.95)
    .attr("visibility", "hidden");
  g.append("text")
    .attr("class", "annual-performance-historic-label-text")
    .attr("x", widthChart + 5 + 20)
    .attr("text-anchor", "middle")
    .attr("font-size", fontStyles.axisLabel.fontSize)
    .attr("font-weight", fontStyles.axisLabel.fontWeight)
    .attr("fill", "#fff")
    .attr("visibility", "hidden");

  // HOVERLINES ------------------------------------------------------------------
  // HOVER LINES
  const hover = g.append("g").attr("class", `${nodeId} hover`);
  // Hover lines
  const xHoverLine = hover
    .append("line")
    .attr("class", "x-hover-line hover-line x-hover-element")
    .attr("y1", 0)
    .attr("y2", heightChart + 5);
  const yHoverLine = hover
    .append("line")
    .attr("class", "y-hover-line hover-line")
    .attr("x1", -5)
    .attr("x2", widthChart);
  hover
    .selectAll(".hover-line")
    .attr("stroke", colors.axisLine)
    .attr("stroke-width", 0.5)
    .attr("stroke-dasharray", "1,1")
    .attr("opacity", 1);

  const hoverXAxisRect = hover
    .append("rect")
    .attr("class", "x-hover-rect hover-rect x-hover-element")
    .attr("width", 40)
    .attr("height", 14)
    .attr("y", heightChart + 5)
    .attr("x", -20)
    .attr("fill", "#333")
    .attr("opacity", 0.95);
  const hoverXAxisText = hover
    .append("text")
    .attr("class", "x-hover-text hover-text x-hover-element")
    .attr("y", heightChart + 16)
    .attr("text-anchor", "middle")
    .attr("font-size", "10px")
    .attr("fill", "#fff");
  const hoverYAxisRect = hover
    .append("rect")
    .attr("class", "y-hover-rect hover-rect")
    .attr("width", 40)
    .attr("height", 14)
    .attr("x", -5 - 40)
    .attr("fill", "#333")
    .attr("opacity", 0.95);
  const hoverYAxisText = hover
    .append("text")
    .attr("class", "y-hover-text hover-text")
    .attr("x", -5 - 20)
    .attr("text-anchor", "middle")
    .attr("font-size", "10px")
    .attr("fill", "#fff");
  const overlay = g
    .append("rect")
    .attr("class", "seasonality-profile-chart-overlay")
    .attr("x", 0)
    .attr("y", 0)
    .attr("width", widthChart)
    .attr("height", heightChart)
    .attr("fill", "transparent");
  // .lower();
  const hoverElements = [
    xHoverLine,
    hoverXAxisRect,
    hoverXAxisText,
    yHoverLine,
    hoverYAxisRect,
    hoverYAxisText,
  ];
  hoverElements.forEach((el) => el.attr("display", "none"));

  overlay
    .on("mouseover", function () {
      hoverElements.forEach((el) => el.attr("display", "normal"));
      d3.selectAll(
        ".SeasonalityViewerResultItem-barChart.hover .x-hover-element"
      ).attr("display", "normal");
    })
    .on("mousemove", function () {
      const mouseCoords = d3.mouse(this);

      // UPDATING THIS LINE CHART
      // // Hover Lines
      xHoverLine.attr("x1", mouseCoords[0]).attr("x2", mouseCoords[0]);
      yHoverLine.attr("y1", mouseCoords[1]).attr("y2", mouseCoords[1]);

      // // X Axis Elements
      hoverXAxisRect.attr(
        "transform",
        "translate(" + mouseCoords[0] + "," + 0 + ")"
      );
      hoverXAxisText
        .attr("transform", "translate(" + mouseCoords[0] + "," + 0 + ")")
        .text(Math.ceil(xScale.invert(mouseCoords[0] - 3))); // - 3 equals buffer to the right side of the datapoint

      // // Y Axis Elements
      hoverYAxisRect.attr("y", mouseCoords[1] - 7);
      hoverYAxisText
        .attr("y", mouseCoords[1] + 3.5)
        .text(`${yScale.invert(mouseCoords[1]).toFixed(2)}`);

      // // UPDATING BELOW BAR CHART
      // HOVER ELEMENTS
      d3.select(".SeasonalityViewerResultItem-barChart.hover .x-hover-line")
        .attr("x1", mouseCoords[0])
        .attr("x2", mouseCoords[0]);
      d3.select(
        ".SeasonalityViewerResultItem-barChart.hover .x-hover-rect"
      ).attr("transform", "translate(" + mouseCoords[0] + "," + 0 + ")");
      d3.select(".SeasonalityViewerResultItem-barChart.hover .x-hover-text")
        .attr("transform", "translate(" + mouseCoords[0] + "," + 0 + ")")
        .text(Math.ceil(xScale.invert(mouseCoords[0] - 3))); // - 3equals buffer to the right side of the datapoint

      // CHART VALUES
      const mouseCurrentWeek = Math.ceil(xScale.invert(mouseCoords[0] - 3)); // - 3 equals buffer to the right side of the datapoint
      const masterSelectorString =
        "#SeasonalityViewerResultItem-barChart .chart-text.chart-value-";

      if ((mouseCurrentWeek > 0) & (mouseCurrentWeek < 53)) {
        dataCS[mouseCurrentWeek] &&
          d3
            .selectAll(`${masterSelectorString}current`)
            .text(dataCS[mouseCurrentWeek].toFixed(2));
        d3.selectAll(`${masterSelectorString}mean`).text(
          dataPS.mean[mouseCurrentWeek].toFixed(2)
        );
        d3.selectAll(`${masterSelectorString}stdev`).text(
          dataPS.std[mouseCurrentWeek].toFixed(2)
        );
        d3.selectAll(`${masterSelectorString}median`).text(
          dataPS.median[mouseCurrentWeek].toFixed(2)
        );
        d3.selectAll(`${masterSelectorString}min`).text(
          dataPS.min[mouseCurrentWeek].toFixed(2)
        );
        d3.selectAll(`${masterSelectorString}max`).text(
          dataPS.max[mouseCurrentWeek].toFixed(2)
        );
      } else d3.selectAll("#SeasonalityViewerResultItem-barChart .chart-text.chart-value").text("");
    })
    .on("mouseout", function () {
      hoverElements.forEach((el) => el.attr("display", "none"));
      d3.selectAll(
        ".SeasonalityViewerResultItem-barChart.hover .x-hover-element"
      ).attr("display", "none");
      d3.selectAll(
        "#SeasonalityViewerResultItem-barChart .chart-text.chart-value"
      ).text("");
    });

  g.selectAll(".line-seasonality").on("mouseenter", () =>
    hoverElements.forEach((el) => el.attr("display", "normal"))
  );

  // ===================================================================================================
  // ======================================= MAIN UPDATE FUNCTION ======================================
  const updateChart = () => {
    // TRANSITION --------------------------------------------------------------------------------------
    const t = d3.transition().duration(250).ease(d3.easeLinear);

    // CHART PARAMETER VALUES --------------------------------------------------------------------------
    const showAllProfiles =
      d3.select(
        ".SeasonalityViewerResultItem-button-group-allLines .ant-radio-button-checked input"
      )._groups[0][0].value === "yes";

    // PREPARING DATA --------------------------------------------------------------------------
    const chartData = !showAllProfiles
      ? {
          pastMean: { ...seasonalityData.pastPerformanceProgression.mean },
          pastMedian: {
            ...seasonalityData.pastPerformanceProgression.median,
          },
          current: { ...seasonalityData.currentPerformanceProgression },
        }
      : {
          pastMean: { ...seasonalityData.pastPerformanceProgression.mean },
          pastMedian: {
            ...seasonalityData.pastPerformanceProgression.median,
          },
          current: { ...seasonalityData.currentPerformanceProgression },
          ...seasonalityData.pastPerformanceProgression.allYearsIndiviual,
        };

    // UPDATING SCALES --------------------------------------------------------------------------
    const yDataAll = [];
    for (let key of Object.keys(chartData)) {
      yDataAll.push(...Object.values(chartData[key]));
    }

    const [yMin, yMax] = d3.extent(yDataAll);
    const yPadding = (yMax - yMin) * 0.1;
    yScale.domain([yMin - yPadding * 2.5, yMax + yPadding * 2]);

    // UPDATING AXES --------------------------------------------------------------------------
    yAxisLeftCall.scale(yScale);
    yAxisLeft.transition(t).call(yAxisLeftCall);
    yAxisRightCall.scale(yScale);
    yAxisRight.transition(t).call(yAxisRightCall);

    // // AXES STYLING
    d3.selectAll(`#${nodeId} .axis path`).attr("stroke", colors.axisLine);
    d3.selectAll(`#${nodeId} .axis line`).attr("stroke", colors.axisLine);
    d3.selectAll(`#${nodeId} .axis text`)
      .attr("fill", colors.axisLabel)
      .attr("font-size", fontStyles.axisLabel.fontSize);

    // UPDATING FILL AREAS --------------------------------------------------------------------
    const areaBelow = d3
      .area()
      .x((d) => xScale(d.week))
      .y0(heightChart)
      .y1((d) => yScale(d.value));
    const areaAbove = d3
      .area()
      .x((d) => xScale(d.week))
      .y0(0)
      .y1((d) => yScale(d.value));

    for (let k of Object.keys(bestSectionsAreaData)) {
      for (let i = 0; i < bestSectionsAreaData[k].length; i++) {
        const data = bestSectionsAreaData[k][i];
        for (let direction of ["below", "above"]) {
          g.select(`#best-sections-fill-area_${k}${i}${direction}`)
            .transition(t)
            .attr(
              "d",
              direction === "below" ? areaBelow(data) : areaAbove(data)
            );
        }
      }
    }

    // g.append("path")
    //   .attr("class", "best-sections-fill-area")
    //   .attr("d", areaBelow(fillData))
    //   .attr("fill", "url(#area-gradient_below_mean)")
    //   .lower();
    // g.append("path")
    //   .attr("class", "best-sections-fill-area")
    //   .attr("d", areaAbove(fillData))
    //   .attr("fill", "url(#area-gradient_above_mean)")
    //   .lower();

    // UPDATING LINES -------------------------------------------------------------------------
    // // Utility Line - 100
    utilityLine100
      .transition(t)
      .attr("y1", yScale(100))
      .attr("y2", yScale(100));

    // Main Lines
    const lineGenerator = d3
      .line()
      .x((d, i) => xScale(i))
      .y((d) => yScale(d));

    if (showAllProfiles) {
      d3.selectAll(".line-seasonality-history")
        .transition(t)
        .attr("opacity", 0.5)
        .attr("visibility", "visible");
      d3.selectAll(".line-seasonality-history").raise();
      d3.selectAll(".line-seasonality-main").raise();
      //   .transition(t)
      //   .attr("stroke-width", lineStyles.strokeWidth.primary.normal);
    } else {
      d3.selectAll(".line-seasonality-history")
        .transition(t)
        .attr("visibility", "hidden");
      d3.selectAll(".line-seasonality-main").raise();
      //   .transition(t)
      //   .attr("stroke-width", lineStyles.strokeWidth.primary.normal);
    }

    for (let key of Object.keys(chartData)) {
      d3.select(`.line-seasonality-${key}`)
        .transition(t)
        .attr("d", lineGenerator(Object.values(chartData[key])));
    }

    // UPDATING LABELS ---------------------------------------------------------------------------
    // CURRENT WEEK Y AXIS LEFT
    currentWeekCircle.transition(t).attr("cy", yScale(currentWeekProgression));
    currentWeekYaxisLabelLine
      .transition(t)
      .attr("y1", yScale(currentWeekProgression))
      .attr("y2", yScale(currentWeekProgression));
    currentWeekYaxisLabelRect
      .transition(t)
      .attr("y", yScale(currentWeekProgression) - 8);
    currentWeekYaxisLabelText
      .transition(t)
      .attr(
        "y",
        yScale(currentWeekProgression) +
          (14 - fontStyles.axisLabel.fontSize) / 1.5
      );
    // ANNUAL PERFORMANCE Y AXIS RIGHT
    const yAnnualPerformanceMean = yScale(annualPerformanceMean);
    const yAnnualPerformanceMedian = yScale(annualPerformanceMedian);
    let yMeanAdjustVal = 0;

    if (
      ((yAnnualPerformanceMean - yAnnualPerformanceMedian) ** 2) ** 0.5 <
      14
    ) {
      yMeanAdjustVal =
        yAnnualPerformanceMean <= yAnnualPerformanceMedian ? -7 : 7;
    }

    const yAnnualPerformanceMeanAdjusted =
      yAnnualPerformanceMean + yMeanAdjustVal;
    const yAnnualPerformanceMedianAdjusted =
      yAnnualPerformanceMedian - yMeanAdjustVal;

    labelAnnualPerformanceMeanLine
      .transition(t)
      .attr("y1", yAnnualPerformanceMean)
      .attr("y2", yAnnualPerformanceMean);
    labelAnnualPerformanceMeanRect
      .transition(t)
      .attr("y", yAnnualPerformanceMeanAdjusted - 8);
    labelAnnualPerformanceMeanText
      .transition(t)
      .attr(
        "y",
        yAnnualPerformanceMeanAdjusted +
          (14 - fontStyles.axisLabel.fontSize) / 1.5
      );
    labelAnnualPerformanceMedianLine
      .transition(t)
      .attr("y1", yAnnualPerformanceMedian)
      .attr("y2", yAnnualPerformanceMedian);
    labelAnnualPerformanceMedianRect
      .transition(t)
      .attr("y", yAnnualPerformanceMedianAdjusted - 8);
    labelAnnualPerformanceMedianText
      .transition(t)
      .attr(
        "y",
        yAnnualPerformanceMedianAdjusted +
          (14 - fontStyles.axisLabel.fontSize) / 1.5
      );
  };

  updateChart();

  // EVENT LISTENER ------------------------------------------------------------------------------------
  // RADIO BUTTONS - TRADE DURATION
  d3.selectAll(".SeasonalityViewerResultItem-button-group-allLines input").on(
    "change",
    updateChart
  );
}

export default drawSeasonalityLineChart;

// UTILITY FUNCTIONS
function changeHexColorOpacity(hexColor, opacity) {
  const c = d3.rgb(hexColor);
  c.opacity = opacity;
  return c;
}
