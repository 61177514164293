import * as d3 from "d3";

const tooltips = (
  category,
  symbol,
  baseClass,
  gChart,
  widthChart,
  heightChart,
  xScale,
  yScale
) => {
  const formatTime = d3.timeFormat("%Y-%m-%d");

  const hover = gChart
    .append("g")
    .attr("class", `${baseClass} hover`)
    .attr("display", "none");

  // Hover lines
  const xHoverLine = hover
    .append("line")
    .attr("class", "x-hover-line hover-line")
    .attr("y1", 0)
    .attr("y2", heightChart + 5);
  const yHoverLine = hover
    .append("line")
    .attr("class", "x-hover-line hover-line")
    .attr("x1", 0)
    .attr("x2", widthChart);
  hover
    .selectAll(".hover-line")
    .attr("stroke", "#d3d3d3")
    .attr("stroke-width", 0.5)
    .attr("opacity", 0.75);

  // Hover Date
  const dateRect = hover
    .append("rect")
    .attr("class", "date-hover-rect hover-rect")
    .attr("width", 64)
    .attr("height", 14)
    .attr("y", heightChart + 6)
    .attr("x", -32)
    .attr("ry", 1)
    .attr("rx", 1)
    .attr("fill", "#fff")
    .attr("stroke", "#333")
    .attr("stroke-width", "0.5")
    .attr("opacity", 0.8);
  const dateText = hover
    .append("text")
    .attr("class", "date-hover-text hover-text")
    .attr("y", heightChart + 16)
    .attr("text-anchor", "middle")
    .attr("font-size", "10px")
    .attr("fill", "#656565");

  // Hover Price
  const priceText = hover
    .append("text")
    .attr("class", "price-hover-text hover-text")
    .attr("x", -2)
    .attr("text-anchor", "end")
    .attr("font-size", "10px")
    .attr("fill", "#656565");

  // Hover overlay
  const digitCount = category === "forex" && !symbol.includes("JPY") ? 5 : 2;

  gChart
    .append("rect")
    .attr("class", "hover overlay")
    .attr("x", 0)
    .attr("y", 0)
    .attr("width", widthChart)
    .attr("height", heightChart)
    .attr("fill", "transparent")
    .attr("opacity", 0.5)
    .on("mouseover", function () {
      hover.attr("display", "normal");
    })
    .on("mousemove", function () {
      const mouseCoords = d3.mouse(this);

      // Updating hover elements
      // // hover lines
      xHoverLine.attr("x1", mouseCoords[0]).attr("x2", mouseCoords[0]);
      yHoverLine.attr("y1", mouseCoords[1]).attr("y2", mouseCoords[1]);

      // // date
      if (mouseCoords[0] > widthChart - 32) {
        dateRect.transition().duration(100).attr("x", -64);
        dateText.transition().duration(100).attr("x", -32);
      } else {
        dateRect.transition().duration(100).attr("x", -32);
        dateText.transition().duration(100).attr("x", 0);
      }
      dateRect.attr("transform", "translate(" + mouseCoords[0] + "," + 0 + ")");
      dateText
        .attr("transform", "translate(" + mouseCoords[0] + "," + 0 + ")")
        .text(formatTime(xScale.invert(mouseCoords[0])));

      // // price
      priceText
        .attr("y", mouseCoords[1] + 3.5)
        .text(`${yScale.invert(mouseCoords[1]).toFixed(digitCount)}`);
    })
    .on("mouseout", function () {
      hover.attr("display", "none");
    });
};

export default tooltips;
