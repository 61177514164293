import * as d3 from "d3";

import tooltips from "./drawPrvriLineChart-tooltips";

const drawPrvriLineChart = (nodeId, width, height, data) => {
  // GENERAL NODE INFORMATION
  const classLineChartDiv =
    "PeriodicReturnViewerResultItem-container-linechart";

  // DATA PREPARATION
  const dataLineChart = data.periodicReturnsList.map((d, idx) => ({
    date: data.dateList[idx],
    adjClose: data.adjCloseList[idx],
    perReturn: d,
    perReturnExpMeans: data.periodicReturnsExpMeansList[idx],
    perReturnExpStDevs: data.periodicReturnsExpStdevsList[idx],
    perStDev: data.periodicStdevsList[idx],
    perStDevExpMeans: data.periodicStdevsExpMeansList[idx],
    perStDevExpStDevs: data.periodicStdevsExpStdevsList[idx],
  }));

  // DIMENSIONS
  const margin = { top: 60, right: 60, bottom: 28, left: 75 };

  const widthChart = width - margin.left - margin.right;
  const heightChart = height - margin.top - margin.bottom;

  // RETURN/ADJCLOSE CHART HEIGHTS RATIO - GOLDEN RATIO 0.618
  const heightFractionClose = 0.333;
  const heightRatioReturnToStDev = 0.5;
  const heightChartAdjClose = heightChart * heightFractionClose;
  const heightChartReturn =
    (heightChart - heightChartAdjClose) * heightRatioReturnToStDev;
  const heightChartStDev =
    heightChart - heightChartAdjClose - heightChartReturn;

  const svg = d3
    .select(`#${nodeId}`)
    .append("svg")
    .attr("width", width)
    .attr("height", height);
  // .style('border', '1px solid green')
  const g = svg
    .append("g")
    .attr("transform", `translate(${margin.left}, ${margin.top})`);

  // DEFINING CLIP-PATH FUNCTIONALITY
  svg
    .append("defs")
    .append("clipPath")
    .attr("id", "clip")
    .append("rect")
    .attr("x", 0)
    .attr("width", widthChart)
    .attr("height", heightChart);

  // LABELS
  // titel
  g.append("text")
    .attr("class", "x axis-label")
    .attr("x", (1 / 2) * widthChart)
    .attr("y", -15)
    .attr("font-size", "13px")
    .attr("font-weight", 400)
    .attr("fill", "#656565")
    .attr("text-anchor", "middle")
    .text(`${data.periodDays}-Day-MR-Time Plot for ${data.symbol}`);
  // chartLabelReturns
  g.append("text")
    .attr("class", "chart-label returns")
    .attr("x", widthChart - 5)
    .attr("y", 10 + 7)
    .attr("font-size", "calc(0.5vw + 0.2rem)")
    .attr("font-weight", 400)
    .attr("fill", "#bbb")
    .attr("opacity", 0.5)
    .attr("text-anchor", "end")
    .attr("letter-spacing", "0.25px")
    .text("RETURNS");
  // chartLabelVolatility
  g.append("text")
    .attr("class", "chart-label volatility")
    .attr("x", widthChart - 5)
    .attr("y", heightChartReturn + 15 + 7)
    .attr("font-size", "calc(0.5vw + 0.2rem)")
    .attr("font-weight", 400)
    .attr("fill", "#bbb")
    .attr("opacity", 0.5)
    .attr("text-anchor", "end")
    .attr("letter-spacing", "0.25px")
    .text("VOLATILITY");
  // chartLabelClose
  g.append("text")
    .attr("class", "chart-label close")
    .attr("x", widthChart - 5)
    .attr("y", heightChartReturn + heightChartStDev + 15 + 7)
    .attr("font-size", "calc(0.5vw + 0.2rem)")
    .attr("font-weight", 400)
    .attr("fill", "#bbb")
    .attr("opacity", 0.5)
    .attr("text-anchor", "end")
    .attr("letter-spacing", "0.25px")
    .text("PRICE");
  // chartLabelCloseSymbol
  g.append("text")
    .attr("class", "chart-label returns")
    .attr("x", widthChart / 2)
    .attr(
      "y",
      heightChartReturn + heightChartStDev + heightChartAdjClose / 2 + 7
    )
    .attr("font-size", "calc(1vw + 1rem)")
    .attr("font-weight", 500)
    .attr("fill", "#bbb")
    .attr("opacity", 0.5)
    .attr("text-anchor", "middle")
    .text(`${data.symbol}`);
  // chartLabelCloseName
  g.append("text")
    .attr("class", "chart-label returns")
    .attr("x", widthChart / 2)
    .attr(
      "y",
      heightChartReturn + heightChartStDev + heightChartAdjClose / 2 + 27
    )
    .attr("font-size", "calc(0.5vw + 0.3rem)")
    .attr("font-weight", 400)
    .attr("fill", "#bbb")
    .attr("opacity", 0.5)
    .attr("text-anchor", "middle")
    .attr("letter-spacing", "0.25px")
    .text(`${data.name.toUpperCase()}`);
  // yLabelLeftTop
  g.append("text")
    .attr("class", "y axis-label")
    .attr("x", (-1 / 2) * heightChartReturn)
    .attr("y", -margin.left + 35)
    .attr("font-size", "12px")
    .attr("font-weight", 300)
    .attr("fill", "#5D5D5D")
    .attr("text-anchor", "middle")
    .attr("transform", "rotate(-90)")
    .text("Period Log Return [%]");
  // yLabelLeftMid
  g.append("text")
    .attr("class", "y axis-label")
    .attr(
      "x",
      -heightChartReturn - heightChartStDev + (1 / 2) * heightChartStDev
    )
    .attr("y", -margin.left + 35)
    .attr("font-size", "12px")
    .attr("font-weight", 300)
    .attr("fill", "#5D5D5D")
    .attr("text-anchor", "middle")
    .attr("transform", "rotate(-90)")
    .text("Period Volatility [%]");
  // yLabelLeftBottom
  g.append("text")
    .attr("class", "y axis-label")
    .attr("x", -heightChart + (1 / 2) * heightChartAdjClose)
    .attr("y", -margin.left + 35)
    .attr("font-size", "12px")
    .attr("font-weight", 300)
    .attr("fill", "#5D5D5D")
    .attr("text-anchor", "middle")
    .attr("transform", "rotate(-90)")
    .text("Adjusted Close [$]");

  // SCALES
  const xScale = d3.scaleTime().range([0, widthChart]);
  const yScaleReturn = d3.scaleLinear().range([heightChartReturn, 0]);
  const yScaleStDev = d3
    .scaleLinear()
    .range([heightChartReturn + heightChartStDev, heightChartReturn]);
  const yScaleClose = d3
    .scaleLinear()
    .range([heightChart, heightChartReturn + heightChartStDev]);

  // AXES
  const xAxisBottomCall = d3.axisBottom().tickSizeOuter(0).tickSizeInner(6);
  const xAxisBottom = g
    .append("g")
    .attr("class", "x axis bottom")
    .attr("clip-path", "url(#clip)")
    .attr("transform", `translate(0, ${heightChart})`);
  const xAxisReturnBottomCall = d3
    .axisBottom()
    .tickSizeOuter(0)
    .tickSizeInner(6);
  const xAxisReturnBottom = g
    .append("g")
    .attr("class", "x axis bottom return")
    .attr("clip-path", "url(#clip)")
    .attr("transform", `translate(0, ${heightChartReturn})`);
  const xAxisStDevTopCall = d3.axisTop().tickSizeOuter(0).tickSizeInner(6);
  const xAxisStDevTop = g
    .append("g")
    .attr("class", "x axis top stdev")
    .attr("clip-path", "url(#clip)")
    .attr("transform", `translate(0, ${heightChartReturn})`);
  const xAxisStDevBottomCall = d3
    .axisBottom()
    .tickSizeOuter(0)
    .tickSizeInner(6);
  const xAxisStDevBottom = g
    .append("g")
    .attr("class", "x axis bottom stdev")
    .attr("clip-path", "url(#clip)")
    .attr("transform", `translate(0, ${heightChartReturn + heightChartStDev})`);
  const xAxisCloseTopCall = d3.axisTop().tickSizeOuter(0).tickSizeInner(6);
  const xAxisCloseTop = g
    .append("g")
    .attr("class", "x axis top close")
    .attr("clip-path", "url(#clip)")
    .attr("transform", `translate(0, ${heightChartReturn + heightChartStDev})`);

  const yAxisLeftTopCall = d3.axisLeft().ticks(5).tickSizeOuter(0);
  const yAxisLeftTop = g.append("g").attr("class", "y axis left");
  const yAxisLeftMidCall = d3.axisLeft().ticks(5).tickSizeOuter(0);
  const yAxisLeftMid = g.append("g").attr("class", "y axis left");
  const yAxisLeftBottomCall = d3.axisLeft().ticks(5).tickSizeOuter(0);
  const yAxisLeftBottom = g.append("g").attr("class", "y axis left");

  // MAIN UPDATE FUNCTION ==============================================================================
  const updateChart = () => {
    // UPDATING SCALES --------------------------------------------------------------------------------
    // // X SCALE
    const xScaleMin = dataLineChart[dataLineChart.length - 1].date;
    const xScaleMax = dataLineChart[0].date;
    xScale.domain([xScaleMin, xScaleMax]);

    // // Y SCALES
    // // // Y Scale Return
    const perReturnMax = data.periodicReturnsMax;
    const perReturnMin = data.periodicReturnsMin;
    const yScaleReturnSpacing = (perReturnMax - perReturnMin) * 0.15;
    yScaleReturn.domain([
      perReturnMin - yScaleReturnSpacing,
      perReturnMax + yScaleReturnSpacing * 1.5,
    ]);
    // // // Y Scale StDev
    const perStDevMax = data.periodicStdevsMax;
    const perStDevMin = data.periodicStdevsMin;
    const yScalestDevSpacing = (perStDevMax - perStDevMin) * 0.15;
    yScaleStDev.domain([
      perStDevMin - yScalestDevSpacing,
      perStDevMax + yScalestDevSpacing * 1.5,
    ]);
    // // // Y Scale Close
    const [closeMin, closeMax] = d3.extent(dataLineChart, (d) => d.adjClose);
    const yScalestCloseSpacing = (closeMax - closeMin) * 0.15;
    yScaleClose.domain([
      closeMin - yScalestCloseSpacing,
      closeMax + yScalestCloseSpacing * 1.5,
    ]);

    // UPDATING AXES  --------------------------------------------------------------------------------
    // // X AXIS
    // // // X Axis - Return Bottom
    xAxisReturnBottomCall.scale(xScale);
    xAxisReturnBottom.call(xAxisReturnBottomCall).selectAll("text").remove();
    xAxisReturnBottom.selectAll("line").attr("y2", 3);
    // // // X Axis - StDev Top
    xAxisStDevTopCall.scale(xScale);
    xAxisStDevTop.call(xAxisStDevTopCall).selectAll("text").remove();
    xAxisStDevTop.select("path").remove();
    xAxisStDevTop.selectAll("line").attr("y2", -2);
    // // // X Axis - StDev Bottom
    xAxisStDevBottomCall.scale(xScale);
    xAxisStDevBottom.call(xAxisStDevBottomCall).selectAll("text").remove();
    xAxisStDevBottom.selectAll("line").attr("y2", 3);
    // // // X Axis - Close Top
    xAxisCloseTopCall.scale(xScale);
    xAxisCloseTop.call(xAxisCloseTopCall).selectAll("text").remove();
    xAxisCloseTop.select("path").remove();
    xAxisCloseTop.selectAll("line").attr("y2", -2);
    // // // X Axis - Close Bottom
    xAxisBottomCall.scale(xScale);
    xAxisBottom.call(xAxisBottomCall);

    // // Y AXIS
    yAxisLeftTopCall.scale(yScaleReturn);
    yAxisLeftTop.call(yAxisLeftTopCall);
    yAxisLeftMidCall.scale(yScaleStDev);
    yAxisLeftMid.call(yAxisLeftMidCall);
    yAxisLeftBottomCall.scale(yScaleClose);
    yAxisLeftBottom.call(yAxisLeftBottomCall);

    // // AXES STYLING
    d3.selectAll(`.${classLineChartDiv} .axis path`).attr("stroke", "#878787");
    d3.selectAll(`.${classLineChartDiv} .axis line`).attr("stroke", "#878787");
    d3.selectAll(`.${classLineChartDiv} .axis text`)
      .attr("fill", "#878787")
      .attr("font-size", "10px");

    // LINES --------------------------------------------------------------------------------

    // // RETURN LINES + LABELS

    // // // RETURN: MAIN LINE
    const lineGeneratorReturn = d3
      .line()
      .x((d) => xScale(d.date))
      .y((d) => yScaleReturn(d.perReturn));
    g.append("path")
      .attr("class", "main-line")
      .attr("id", "line-return")
      .attr("clip-path", "url(#clip)")
      .attr("d", lineGeneratorReturn(dataLineChart))
      .attr("fill", "none")
      .attr("stroke", "#1890ff")
      .attr("stroke-width", 1);

    // // // RETURN: MEAN LINE + LABEL
    const lineGeneratorReturnMean = d3
      .line()
      .x((d) => xScale(d.date))
      .y((d) => yScaleReturn(d.perReturnExpMeans));
    const perReturnMean = data.periodicReturnsMean;
    g.append("path")
      .attr("class", "utility-line mean")
      .attr("id", "line-return-mean")
      .attr("clip-path", "url(#clip)")
      .attr("d", lineGeneratorReturnMean(dataLineChart))
      .attr("fill", "none")
      .attr("stroke", "#333")
      .attr("stroke-width", 0.7)
      .attr("opacity", 0.3);
    g.append("text")
      .attr("class", "utility-text mean")
      .attr("id", "text-label-return-mean")
      .attr("x", widthChart + 20)
      .attr("y", yScaleReturn(perReturnMean) + 2)
      .attr("font-size", "10px")
      .attr("text-anchor", "middle")
      .attr("font-weight", 300)
      .attr("fill", "#333")
      .attr("opacity", 0.3)
      .text("mean");

    // // // RETURN: STDEV (EXPANDING) LINES + LABELS
    const listStDevs = [-3, -2, -1, 1, 2, 3];
    const dataReturnStDevLines = listStDevs.map((n) =>
      dataLineChart.map((obj) => ({
        date: obj.date,
        expStDev: obj.perReturnExpMeans + n * obj.perReturnExpStDevs,
      }))
    );
    const yScaleReturnMin = yScaleReturn.domain()[0];
    const yScaleReturnMax = yScaleReturn.domain()[1];

    const lineGeneratorReturnStDev = d3
      .line()
      .x((d) => xScale(d.date))
      .y((d) => yScaleReturn(d.expStDev))
      .defined(
        (d) => d.expStDev > yScaleReturnMin && d.expStDev < yScaleReturnMax
      );

    const perReturnStDevLast = data.periodicReturnsStdev;

    listStDevs.forEach((n, i) => {
      // Line StDev
      g.append("path")
        .attr("class", "utility-line stdev")
        .attr("id", `line-return-stdev_${n}`)
        .attr("clip-path", "url(#clip)")
        .attr("d", lineGeneratorReturnStDev(dataReturnStDevLines[i]))
        .attr("fill", "none")
        .attr("stroke", "#333")
        .attr("stroke-dasharray", "1,1")
        .attr("stroke-width", 0.3 + (1 / 6) * (n * n) ** 0.5)
        .attr("opacity", 0.3 + (1 / 6) * (n * n) ** 0.5);
      // Label StDev
      const expStDevLast = perReturnMean + n * perReturnStDevLast;
      g.append("text")
        .attr("class", "utility-text stdev")
        .attr("id", `text-label-return-stdev_${n}`)
        .attr("x", widthChart + 20)
        .attr("y", yScaleReturn(expStDevLast) + 2)
        .attr(
          "display",
          expStDevLast > yScaleReturnMin && expStDevLast < yScaleReturnMax
            ? "normal"
            : "none"
        )
        .attr("font-size", "10px")
        .attr("text-anchor", "middle")
        .attr("font-weight", 300)
        .attr("fill", "#333")
        .attr("opacity", 0.3 + (1 / 6) * (n * n) ** 0.5)
        .text(`${n > 0 ? "+" : ""}${n}σ`);
    });

    // // // RETURN: LAST LINE + CIRCLE
    const perRtnLast = data.periodicReturnsLast;
    g.append("circle")
      .attr("class", "utility-circle last")
      .attr("cx", xScale(xScaleMax))
      .attr("cy", yScaleReturn(perRtnLast))
      .attr("r", 3)
      .attr("stroke", "#bf1216")
      .attr("stroke-width", 1.2)
      .attr("fill", "transparent");
    g.append("line")
      .attr("class", "utility-label-line return")
      .attr("x1", 0)
      .attr("x2", 3)
      .attr("y1", yScaleReturn(perRtnLast))
      .attr("y2", yScaleReturn(perRtnLast))
      .attr("stroke", "#bf1216")
      .attr("stroke-width", 1)
      .attr("opacity", 0.75);
    g.append("rect")
      .attr("class", "utility-label-rect return")
      .attr("width", 50)
      .attr("height", 14)
      .attr("x", 3)
      .attr("y", yScaleReturn(perRtnLast) - 7)
      .attr("fill", "#bf1216")
      .attr("opacity", 0.75);
    g.append("text")
      .attr("class", "utility-label-text return")
      .attr("y", yScaleReturn(perRtnLast) + 3.5)
      .attr("x", 3 + 25)
      .attr("text-anchor", "middle")
      .attr("font-size", "10px")
      .attr("fill", "#fff")
      .text(perRtnLast.toFixed(2));

    // // VOLA LINES + LABELS
    // // // VOLA: MAIN LINE
    const lineGeneratorVola = d3
      .line()
      .x((d) => xScale(d.date))
      .y((d) => yScaleStDev(d.perStDev));
    g.append("path")
      .attr("class", "main-line")
      .attr("id", "line-vola")
      .attr("clip-path", "url(#clip)")
      .attr("d", lineGeneratorVola(dataLineChart))
      .attr("fill", "none")
      .attr("stroke", "#126cbf")
      .attr("stroke-width", 1.1);
    // // // VOLA: MEAN LINE + LABEL
    const lineGeneratorVolaMean = d3
      .line()
      .x((d) => xScale(d.date))
      .y((d) => yScaleStDev(d.perStDevExpMeans));
    const perStDevMean = data.periodicStdevsMean;
    g.append("path")
      .attr("class", "utility-line mean")
      .attr("id", "line-vola-mean")
      .attr("clip-path", "url(#clip)")
      .attr("d", lineGeneratorVolaMean(dataLineChart))
      .attr("fill", "none")
      .attr("stroke", "#333")
      .attr("stroke-width", 0.7)
      .attr("opacity", 0.3);
    g.append("text")
      .attr("class", "utility-text mean")
      .attr("id", "text-label-vola-mean")
      .attr("x", widthChart + 20)
      .attr("y", yScaleStDev(perStDevMean) + 2)
      .attr("font-size", "10px")
      .attr("text-anchor", "middle")
      .attr("font-weight", 300)
      .attr("fill", "#333")
      .attr("opacity", 0.3)
      .text("mean");
    // // // VOLA: STDEV LINES + LABELS
    const dataVolaStDevLines = listStDevs.map((n) =>
      dataLineChart.map((obj) => ({
        date: obj.date,
        expStDev: obj.perStDevExpMeans + n * obj.perStDevExpStDevs,
      }))
    );
    const yScaleStDevMin = yScaleStDev.domain()[0];
    const yScaleStDevMax = yScaleStDev.domain()[1];

    const lineGeneratorVolaStDev = d3
      .line()
      .x((d) => xScale(d.date))
      .y((d) => yScaleStDev(d.expStDev))
      .defined(
        (d) => d.expStDev > yScaleStDevMin && d.expStDev < yScaleStDevMax
      );

    const perStDevStDevLast = data.periodicStdevsStdev;

    listStDevs.forEach((n, i) => {
      // Line expanding StDev
      g.append("path")
        .attr("class", "utility-line stdev")
        .attr("id", `line-vola-stdev_${n}`)
        .attr("clip-path", "url(#clip)")
        .attr("d", lineGeneratorVolaStDev(dataVolaStDevLines[i]))
        .attr("fill", "none")
        .attr("stroke", "#333")
        .attr("stroke-dasharray", "1,1")
        .attr("stroke-width", 0.3 + (1 / 6) * (n * n) ** 0.5)
        .attr("opacity", 0.3 + (1 / 6) * (n * n) ** 0.5);
      // Label StDev
      const expStDevLast = perStDevMean + n * perStDevStDevLast;
      g.append("text")
        .attr("class", "utility-text stdev")
        .attr("id", `text-label-vola-stdev_${n}`)
        .attr("x", widthChart + 20)
        .attr("y", yScaleStDev(expStDevLast) + 2)
        .attr(
          "display",
          expStDevLast > yScaleStDevMin && expStDevLast < yScaleStDevMax
            ? "normal"
            : "none"
        )
        .attr("font-size", "10px")
        .attr("text-anchor", "middle")
        .attr("font-weight", 300)
        .attr("fill", "#333")
        .attr("opacity", 0.3 + (1 / 6) * (n * n) ** 0.5)
        .text(`${n > 0 ? "+" : ""}${n}σ`);
    });
    // // // VOLA: LAST LINE + CIRCLE
    const perStDevLast = data.periodicStdevsLast;
    g.append("circle")
      .attr("class", "utility-circle last")
      .attr("cx", xScale(xScaleMax))
      .attr("cy", yScaleStDev(perStDevLast))
      .attr("r", 3)
      .attr("stroke", "#bf1216")
      .attr("stroke-width", 1.2)
      .attr("fill", "transparent");
    g.append("line")
      .attr("class", "utility-label-line vola")
      .attr("x1", 0)
      .attr("x2", 3)
      .attr("y1", yScaleStDev(perStDevLast))
      .attr("y2", yScaleStDev(perStDevLast))
      .attr("stroke", "#bf1216")
      .attr("stroke-width", 1)
      .attr("opacity", 0.75);
    g.append("rect")
      .attr("class", "utility-label-rect vola")
      .attr("width", 50)
      .attr("height", 14)
      .attr("x", 3)
      .attr("y", yScaleStDev(perStDevLast) - 7)
      .attr("fill", "#bf1216")
      .attr("opacity", 0.75);
    g.append("text")
      .attr("class", "utility-label-text vola")
      .attr("y", yScaleStDev(perStDevLast) + 3.5)
      .attr("x", 3 + 25)
      .attr("text-anchor", "middle")
      .attr("font-size", "10px")
      .attr("fill", "#fff")
      .text(perStDevLast.toFixed(2));

    // // ADJ CLOSE LINE
    const adjCloseLast = dataLineChart[0].adjClose;
    const lineGeneratorClose = d3
      .line()
      .x((d) => xScale(d.date))
      .y((d) => yScaleClose(d.adjClose));
    g.append("path")
      .attr("class", "main-line")
      .attr("id", "line-close")
      .attr("clip-path", "url(#clip)")
      .attr("d", lineGeneratorClose(dataLineChart))
      .attr("fill", "none")
      .attr("stroke", "#002766")
      .attr("stroke-width", 1);
    g.append("circle")
      .attr("class", "utility-circle last")
      .attr("cx", xScale(xScaleMax))
      .attr("cy", yScaleClose(adjCloseLast))
      .attr("r", 3)
      .attr("stroke", "#bf1216")
      .attr("stroke-width", 1.2)
      .attr("fill", "transparent");
    g.append("line")
      .attr("class", "utility-label-line close")
      .attr("x1", 0)
      .attr("x2", 3)
      .attr("y1", yScaleClose(adjCloseLast))
      .attr("y2", yScaleClose(adjCloseLast))
      .attr("stroke", "#bf1216")
      .attr("stroke-width", 1)
      .attr("opacity", 0.75);
    g.append("rect")
      .attr("class", "utility-label-rect close")
      .attr("width", 50)
      .attr("height", 14)
      .attr("x", 3)
      .attr("y", yScaleClose(adjCloseLast) - 7)
      .attr("rx", 1)
      .attr("ry", 1)
      .attr("fill", "#bf1216")
      .attr("opacity", 0.75);
    g.append("text")
      .attr("class", "utility-label-text close")
      .attr("y", yScaleClose(adjCloseLast) + 3.5)
      .attr("x", 3 + 25)
      .attr("text-anchor", "middle")
      .attr("font-size", "10px")
      .attr("fill", "#fff")
      .text(
        data.category === "forex" && !data.symbol.includes("JPY")
          ? adjCloseLast.toFixed(5)
          : adjCloseLast.toFixed(2)
      );

    // // SPECIFIC LINE STYLES
    d3.selectAll(
      `
      .${classLineChartDiv} .utility-line.stdev, 
      .${classLineChartDiv} .utility-line.mean,
      .${classLineChartDiv} .chart-label
      `
    ).lower();

    // ZOOM FUNCTIONALITY
    zoom(svg);

    function zoom(svg) {
      const extent = [
        [0, margin.top],
        [widthChart, heightChart - margin.top],
      ];

      const zooming = d3
        .zoom()
        .scaleExtent([1, 50])
        .translateExtent(extent)
        .extent(extent)
        .on("zoom", zoom)
        .on("end", zoomEnd);

      svg.call(zooming);

      function zoom() {
        // UPDATING xScale range AND xAxis
        xScale.range([0, widthChart].map((d) => d3.event.transform.applyX(d)));
        xAxisBottomCall.scale(xScale);
        xAxisBottom.call(xAxisBottomCall);
        xAxisReturnBottomCall.scale(xScale);
        xAxisReturnBottom.call(xAxisReturnBottomCall);
        xAxisReturnBottom.selectAll("line").attr("y2", 3);
        xAxisStDevBottomCall.scale(xScale);
        xAxisStDevBottom.call(xAxisStDevBottomCall);
        xAxisStDevBottom.selectAll("line").attr("y2", 3);
        xAxisStDevTopCall.scale(xScale);
        xAxisStDevTop.call(xAxisStDevTopCall);
        xAxisStDevTop.select("path").remove();
        xAxisCloseTopCall.scale(xScale);
        xAxisCloseTop.call(xAxisCloseTopCall);
        xAxisCloseTop.select("path").remove();

        // UPDATING MAIN (ONLY MAIN LINES DUE TO PERFORMANCE)
        // RETURN
        svg
          .select("#line-return")
          .attr("d", lineGeneratorReturn(dataLineChart));
        // VOLA
        svg.select("#line-vola").attr("d", lineGeneratorVola(dataLineChart));
        // Close
        svg.select("#line-close").attr("d", lineGeneratorClose(dataLineChart));
      }

      function zoomEnd() {
        // NEW xScale END DATE
        const xAxisEndDate = xScale.invert(widthChart);
        const tempDataLineChartFiltered = dataLineChart.filter(
          (obj) => obj.date >= xAxisEndDate
        );

        // LAST DATA OBJECT IN ZOOMED AREA AND RESPECTIVE MEAN AND STDEV VALUES
        const lastDataObj =
          tempDataLineChartFiltered[tempDataLineChartFiltered.length - 1];

        const lastDataObjReturnMean = lastDataObj.perReturnExpMeans;
        const lastDataObjReturnStdev = lastDataObj.perReturnExpStDevs;

        const lastDataObjVolaMean = lastDataObj.perStDevExpMeans;
        const lastDataObjVolaStdev = lastDataObj.perStDevExpStDevs;

        // UPDATING REMAINING xAxis-range-DEPENDING CHART LINES
        // RETURN MEAN
        svg
          .select("#line-return-mean")
          .attr("d", lineGeneratorReturnMean(dataLineChart));
        svg
          .select("#text-label-return-mean")
          .attr("y", yScaleReturn(lastDataObjReturnMean) + 2);
        // VOLA MEAN
        svg
          .select("#line-vola-mean")
          .attr("d", lineGeneratorVolaMean(dataLineChart));
        svg
          .select("#text-label-vola-mean")
          .attr("y", yScaleStDev(lastDataObjVolaMean) + 2);

        // STDEV
        listStDevs.forEach((n, i) => {
          // RETURN STDEV LINES
          svg
            .select(`#line-return-stdev_${n}`)
            .attr("d", lineGeneratorReturnStDev(dataReturnStDevLines[i]));
          // RETURN STDEV LABELS
          const valReturnStDev =
            lastDataObjReturnMean + n * lastDataObjReturnStdev;
          svg
            .select(`#text-label-return-stdev_${n}`)
            .attr(
              "display",
              valReturnStDev > yScaleReturnMin &&
                valReturnStDev < yScaleReturnMax
                ? "normal"
                : "none"
            )
            .attr("y", yScaleReturn(valReturnStDev) + 2);
          // VOLA STDEV LINES
          svg
            .select(`#line-vola-stdev_${n}`)
            .attr("d", lineGeneratorVolaStDev(dataVolaStDevLines[i]));
          // VOLA STDEV LABELS
          const valVolaStDev = lastDataObjVolaMean + n * lastDataObjVolaStdev;
          svg
            .select(`#text-label-vola-stdev_${n}`)
            .attr(
              "display",
              valVolaStDev > yScaleStDevMin && valVolaStDev < yScaleStDevMax
                ? "normal"
                : "none"
            )
            .attr("y", yScaleStDev(valVolaStDev) + 2);
        });
      }
    }
  };

  updateChart();
  tooltips(
    nodeId,
    g,
    data.category,
    data.symbol,
    dataLineChart,
    widthChart,
    heightChart,
    heightChartReturn,
    heightChartStDev,
    xScale,
    yScaleReturn,
    yScaleStDev,
    yScaleClose
  );
};

export default drawPrvriLineChart;
