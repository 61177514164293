import React from "react";

import "./ImprintPage.css";

const ImprintPage = (props) => {
  return (
    <div
      className="ImprintPage"
      style={{
        backgroundImage: `url(${props.bgImg}`,
      }}
    >
      <div className="ImprintPage-container">
        <div className="ImprintPage-container-heading">
          <h1 className="ImprintPage-container-heading-heading">IMPRINT</h1>
          <div></div>
        </div>
        <div className="ImprintPage-container-imprint">
          <div className="ImprintPage-container-imprint-reference">
            Information according §5 Telemedia Act
          </div>
          <div className="ImprintPage-container-imprint-name">
            Thorsten Roland Wegener
          </div>
          <div className="ImprintPage-container-imprint-street">
            Marktplatz 6
          </div>
          <div className="ImprintPage-container-imprint-city">
            D-65510 Idstein
          </div>
          <div className="ImprintPage-container-imprint-contact">Contact</div>
          <div className="ImprintPage-container-imprint-email">
            Email:{" "}
            <a href="mailto: twegener@thetacticalbrief.com">
              twegener@thetacticalbrief.com
            </a>
          </div>
          <div className="ImprintPage-container-imprint-phone">
            Phone: +49 171 657 7671
          </div>
          <div className="ImprintPage-container-imprint-registration">
            Registration
          </div>
          <div className="ImprintPage-container-imprint-ustid">
            USt-ID: DE 320 63 5898
          </div>
        </div>
        <div className="ImprintPage-container-disclaimer">
          <div className="ImprintPage-container-disclaimer-heading">
            Disclaimer
          </div>
          <div className="ImprintPage-container-disclaimer-content-heading">
            Confidentiality and Limited Access Statement
          </div>
          <div className="ImprintPage-container-disclaimer-content-text">
            This website is expressly intended for use by a select group of
            individuals and is not to be construed as a public website. Access
            to and use of this site is restricted to authorized users only. Any
            unauthorized access or use is strictly prohibited and may result in
            legal action.
            <br></br>
            It has to be emphasized that no personal information is recorded or
            stored through this website. Our commitment to privacy and data
            security is paramount. Users are reminded to exercise due diligence
            in safeguarding their access information.
            <br></br>
            This statement serves as a legal notice and should be considered
            binding upon all users. By accessing and utilizing this website,
            users explicitly acknowledge and agree to the terms outlined herein.
            Any breach of these terms may lead to termination of access and
            pursuit of legal remedies available under applicable laws.
            <br></br>
            This website is provided on an "as-is" basis, and the operators
            disclaim any warranties, express or implied, regarding its
            functionality or suitability for a particular purpose. The operators
            reserve the right to modify or terminate access to the website at
            any time without prior notice.
            <br></br>
            For further information or inquiries regarding the terms of use and
            privacy practices, users are encouraged to contact the website
            administrators through the designated channels.
            <br></br>
            <br></br>This statement is subject to change without notice, and
            users are advised to review it periodically for updates.
          </div>
          <div className="ImprintPage-container-disclaimer-content-heading">
            EU dispute resolution
          </div>
          <div className="ImprintPage-container-disclaimer-content-text">
            {/*Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten 
            nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als 
            Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde 
            Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige 
            Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von 
            Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche 
            Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung 
            möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte 
            umgehend entfernen.*/}
            The European Commission provides a platform for online dispute
            resolution (ODR):{" "}
            <a href="https://ec.europa.eu/consumers/odr/">
              https://ec.europa.eu/consumers/odr/
            </a>
            . Our e-mail address can be found above in the contact section.
            <div>
              As a service provider, we are responsible for our own content on
              these pages in accordance with general legislation pursuant to
              Section 7 (1) of the German Telemedia Act (TMG). According to §§ 8
              to 10 TMG, however, we are not obligated as a service provider to
              monitor transmitted or stored third-party information or to
              investigate circumstances that indicate illegal activity.
              Obligations to remove or block the use of information under the
              general laws remain unaffected. However, liability in this regard
              is only possible from the point in time at which a concrete
              infringement of the law becomes known. If we become aware of such
              infringements, we will remove this content immediately.
            </div>
          </div>
          <div className="ImprintPage-container-disclaimer-content-heading">
            Dispute resolution proceedings in front of a consumer arbitration
            board
          </div>
          <div className="ImprintPage-container-disclaimer-content-text">
            We are not willing or obliged to participate in dispute resolution
            proceedings in front of a consumer arbitration board.
          </div>
          <div className="ImprintPage-container-disclaimer-links-heading">
            Liability for Links
          </div>
          <div className="ImprintPage-container-disclaimer-links-text">
            {/*Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen 
            Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. 
            Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der 
            Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf 
            mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung 
            nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne 
            konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von 
            Rechtsverletzungen werden wir derartige Links umgehend entfernen.*/}
            Our offer contains links to external websites of third parties, on
            whose contents we have no influence. Therefore, we cannot assume any
            liability for these external contents. The respective provider or
            operator of the pages is always responsible for the content of the
            linked pages. The linked pages were checked for possible legal
            violations at the time of linking. Illegal contents were not
            recognizable at the time of linking. However, a permanent control of
            the contents of the linked pages is not reasonable without concrete
            evidence of a violation of the law. If we become aware of any
            infringements, we will remove such links immediately.
          </div>
          <div className="ImprintPage-container-disclaimer-copyright-heading">
            Copyright
          </div>
          <div className="ImprintPage-container-disclaimer-copyright-text">
            {/*Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen 
            dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art 
            der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen 
            Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind 
            nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser 
            Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. 
            Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf 
            eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. 
            Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.*/}
            The content and works created by the site operators on these pages
            are subject to German copyright law. The reproduction, editing,
            distribution and any kind of exploitation outside the limits of
            copyright require the written consent of the respective author or
            creator. Downloads and copies of this site are only permitted for
            private, non-commercial use. Insofar as the content on this site was
            not created by the operator, the copyrights of third parties are
            respected. In particular, third-party content is identified as such.
            Should you nevertheless become aware of a copyright infringement,
            please inform us accordingly. If we become aware of any
            infringements, we will remove such content immediately.
          </div>
        </div>
        <div className="ImprintPage-container-reference">
          <div className="ImprintPage-container-reference-text">
            {"Reference: "}
            <a
              className="ImprintPage-container-reference-link"
              href="https://www.e-recht24.de/"
              target="_blank"
              rel="noopener noreferrer"
            >
              www.e-recht24.de
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImprintPage;
